import styled from 'styled-components';
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { StyledButton } from '../../components/common/StyledButton';
import { useNavigate } from 'react-router-dom';

const ContentPolicyPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <TitleText>Dispute Resolution Policy</TitleText>
      <Text>
        At AP Software Ltd, we aim to resolve disputes in a fair, transparent,
        and efficient manner. If a disagreement arises between the company and
        any user, we encourage both parties to first engage in direct
        communication to seek a mutual resolution.
      </Text>
      <Text>
        In the event that the dispute cannot be resolved informally, the
        following process will be followed:
        <br />
        <br />
        1. Written Notice: The party raising the dispute must provide written
        notice to the other party outlining the nature of the dispute and any
        relevant facts or documentation.
        <br />
        2. Negotiation: Upon receipt of the notice, both parties will engage in
        good-faith negotiations to attempt to resolve the dispute within 30
        days.
        <br />
        3. Mediation: If negotiations fail, either party may request mediation
        through a mutually agreed upon mediator. The costs of mediation will be
        shared equally unless otherwise agreed.
        <br />
        4. Arbitration: If mediation is unsuccessful, the dispute will be
        submitted to binding arbitration in accordance with applicable local
        laws. The decision of the arbitrator will be final and binding.
        <br />
        <br />
        This policy is subject to applicable laws and does not limit either
        party's right to seek legal remedies in urgent or exceptional cases.
      </Text>
      <AcceptButton onClick={handleBack}>OK</AcceptButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px;

  border: 1px solid ${BORDER_COLOR};

  max-width: 400px;

  position: absolute;
  left: 50%;
  transform: translate(-50%, -0%);
`;

const Text = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: justify;
  margin-bottom: 16px;
`;

const TitleText = styled(Text)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default ContentPolicyPage;
