import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useFetchFolderImages from "../../../hooks/folders/useFetchFolderImages";
import { desktopMediaQuery } from "../../../styleHelpers";
import NonSharedImageFeed from "../NonSharedImageFeed";
import { FaArrowLeft } from "react-icons/fa6";

const FolderDetail: React.FC = () => {
  const { folderId } = useParams<{ folderId: string }>();
  const { images, isLoading } = useFetchFolderImages(folderId);

  if (isLoading) return <p>Loading...</p>;

  return (
    <Container>
      <BackButton onClick={() => window.history.back()} />
      <NonSharedImageFeed images={images} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 16px;
`;

const BackButton = styled(FaArrowLeft)`
  position: absolute;
  top: 100px;
  left: 16px;
  font-size: 24px;
  ${desktopMediaQuery} {
    top: 120px;
    left: 420px;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }
`;

export default FolderDetail;
