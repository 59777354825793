import styled from "styled-components";
import { PRIMARY_TEXT_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

export const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  align-items: center;

  gap: 80px;

  padding: 8px;
  margin: 8px;
  margin-left: 36px;
  margin-right: 39px;
  ${desktopMediaQuery} {
    padding: 16px;
    margin: 16px;
  }

  max-width: 600px;

  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: rgba(168, 168, 168, 0.2);
  }

  &::before {
    width: 0.5px; /* width of vertical line */
    height: 100%;
    left: 50%;
    top: 0;
  }

  &::after {
    height: 0.5px; /* height of horizontal line */
    width: 100%;
    top: 50%;
    left: 0;
  }
`;

export const TextContainerInGrid = styled.div`
  padding-bottom: 8px;
  color: ${PRIMARY_TEXT_COLOR};

  font-size: 16px;
  ${desktopMediaQuery} {
    font-size: 18px;
  }

  text-overflow: wrap;
`;

export const IconContainerInGrid = styled.div`
  margin-top: 8px;
  font-size: 32px;
  ${desktopMediaQuery} {
    font-size: 40px;
  }
`;
