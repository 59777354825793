import styled from 'styled-components';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FeedChannel } from '../../../types';
import { SmoothlyLoadingImage } from '../../../components/common/SmoothlyLoadingImage';
import { useState } from 'react';

interface ChannelCardProps {
  channel: FeedChannel;
  handleSelectChannel: (channelName: string) => void;
}

const ChannelCard = ({ channel, handleSelectChannel }: ChannelCardProps) => {
  const { name, cardImageUrl } = channel;
  const [loaded, setLoaded] = useState(false);
  const backgroundImage = cardImageUrl
    ? cardImageUrl
    : '/android-chrome-512x512.png';
  return (
    <Card onClick={() => handleSelectChannel(name)}>
      <Image
        src={backgroundImage}
        alt={name}
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
      <Caption># {name}</Caption>
    </Card>
  );
};

const Card = styled.div<{
  isSelected?: boolean;
}>`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  position: relative;
  height: 23vh;
  min-width: 100px;
  max-width: 45vw;

  background-size: cover;
  border-radius: 8px;

  transition: 0.3s;
  ${desktopMediaQuery} {
    height: 24vh;
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
    }
  }
`;

const Image = styled(SmoothlyLoadingImage)`
  //cover
  object-fit: cover;
  // top
  object-position: 0 0;
  width: 100%;
  height: 100%;
`;

const Caption = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  bottom: 0;
`;

export default ChannelCard;
