import styled from 'styled-components';
import TransitioningModal from '../../../../components/Modals/TransitioningModal';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
} from '../../../../constants';
import { useEffect, useState } from 'react';
import StylishInput from '../../../../components/common/StyledInput';
import { InputContainer } from '../../../../components/common/InputContainer';
import EngineSelector from '../../EngineSelector/EngineSelector';
import {
  getEngineUsingModelId,
  getExampleImageSrc,
} from '../../../../utils/engineHelpers';
import { BiSave, BiTrash } from 'react-icons/bi';
import { StyledButton } from '../../../../components/common/StyledButton';
import { toast } from 'react-toastify';
import useManageStyle from '../../../../hooks/images/useManageStyle';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import { ManageStyleActions, StyleInterface } from '../../../../types';
import { CloseButton, StickiedTopPart } from './CommonInShowAllStylesModal';
import { desktopMediaQuery } from '../../../../styleHelpers';
import { useImageGenerationSettingsContext } from '../../../../context/ImageGenerationSettingsProvider';
import { MdOutlineCopyAll } from 'react-icons/md';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
  action: ManageStyleActions;
  styleToEdit?: StyleInterface;

  handleAfterSaving: () => void;
}

const ManageCustomStylesModal = ({
  show,
  setShow,
  action,
  styleToEdit,
  handleAfterSaving,
}: I) => {
  const closeModal = () => {
    setName('');
    setPrefixPrompt('');
    setSuffixPrompt('');
    setNegativePrompt('');
    setShow(false);
  };

  const { setSelectedStyle } = useImageGenerationSettingsContext();

  const manageStyle = useManageStyle();
  const { loggedInUser } = useLoggedInUserContext();

  const [name, setName] = useState('');
  const [prefixPrompt, setPrefixPrompt] = useState('');
  const [suffixPrompt, setSuffixPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const realisticHd2Engine = getEngineUsingModelId('realistic_vision_v5.1');

  const [engine, setEngine] = useState(realisticHd2Engine);

  useEffect(() => {
    if (action === 'update') {
      if (!styleToEdit) return;
      setName(styleToEdit.name);
      setPrefixPrompt(styleToEdit.prefixPrompt);
      setSuffixPrompt(styleToEdit.suffixPrompt);
      setNegativePrompt(styleToEdit.negativePrompt);
      if (styleToEdit.engine) {
        setEngine(styleToEdit.engine);
      } else {
        setEngine(realisticHd2Engine);
      }
    }
  }, [action, styleToEdit]);

  const MAX_LENGTH_OF_NAME = 30;
  const MAX_LENGTH_OF_PROMPT = 600;
  const MAX_LENGTH_OF_NEG_PROMPT = 700;

  const isTooLongPrompts = () => {
    const lengthOfName = name.length;
    if (lengthOfName > MAX_LENGTH_OF_NAME) {
      alert('Name should be less than 30 characters');
      return true;
    }

    const promptsTotalLength = prefixPrompt.length + suffixPrompt.length;
    if (promptsTotalLength > MAX_LENGTH_OF_PROMPT) {
      alert(
        'Total length of prefix and suffix prompt should be less than 600 characters',
      );
      return true;
    }

    if (negativePrompt.length > MAX_LENGTH_OF_NEG_PROMPT) {
      alert('Negative prompt should be less than 700 characters');
      return true;
    }
    return false;
  };

  const handleCreatingANewStyle = async () => {
    const isTooLong = isTooLongPrompts();
    if (isTooLong) return;
    const creatorUserId = loggedInUser?.id;
    if (!creatorUserId) return;

    const getName = () => {
      if (styleToEdit?.name === name) {
        return `${name} - Copy`;
      }
      return name;
    };
    const style: Omit<StyleInterface, 'id'> = {
      name: getName(),
      prefixPrompt,
      suffixPrompt,
      negativePrompt,
      engine,
      creatorUserId,
      type: 'custom',
      previewImageUrl: getExampleImageSrc(engine),
    };

    const resultOfAddingNewStyle = await manageStyle({
      style,
      action: 'create',
    });

    if (
      resultOfAddingNewStyle.status !== 200 ||
      resultOfAddingNewStyle.style === undefined
    ) {
      toast.error('Error adding new style');
      return;
    }

    setSelectedStyle(resultOfAddingNewStyle.style);
    toast.success('Successfully added new style');

    handleAfterSaving();
    closeModal();
  };

  const handleUpdatingStyle = async () => {
    if (!styleToEdit) return;

    const isTooLong = isTooLongPrompts();
    if (isTooLong) return;

    const creatorUserId = loggedInUser?.id;
    if (!creatorUserId) return;
    const style = {
      id: styleToEdit.id,
      name,
      prefixPrompt,
      suffixPrompt,
      negativePrompt,
      engine,
      creatorUserId,
      type: 'custom',
    };

    const resultOfEditingStyle = await manageStyle({
      style,
      action: 'update',
    });

    if (resultOfEditingStyle.status !== 200) {
      toast.error('Error editing style');
      return;
    }

    setSelectedStyle(style);
    toast.success('Successfully updated style');
    handleAfterSaving();
    closeModal();
  };

  const handleDeleteStyle = async () => {
    const areYouSure = window.confirm(
      'Are you sure you want to delete this style?',
    );
    if (!areYouSure) return;
    if (!styleToEdit) return;
    const resultOfRemovingStyle = await manageStyle({
      style: styleToEdit,
      action: 'delete',
    });

    if (resultOfRemovingStyle.status !== 200) {
      toast.error('Error removing style');
      return;
    }

    toast.success('Successfully removed style');
    closeModal();
  };

  // if is default style, then can only save as new
  // fromOtherUser is kinda hack for now, TODO figure out a better way!!
  const isDefaultStyle =
    styleToEdit?.type === 'default' || styleToEdit?.type === 'fromOtherUser';

  const saveText = action === 'create' ? 'Save' : 'Save as new';

  const isEditingCustomStyle = action === 'update' && !isDefaultStyle;

  const titleText = action === 'create' ? 'Create a new style' : 'Edit style';

  return (
    <>
      <TransitioningModal
        isOpen={show}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: '-15px',
            backgroundColor: BACKGROUND_COLOR,
            padding: '16px',
            paddingBottom: '64px',
            paddingTop: 0,
            width: '100%',
            height: '80%',
            maxWidth: '800px',
            minHeight: '300px',
            overflowY: 'scroll',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
      >
        <StickiedTopPart>
          {isEditingCustomStyle && (
            <RemoveStyleButton onClick={handleDeleteStyle} />
          )}
          <Caption>{titleText}</Caption>
          <CloseButton onClick={closeModal} />
        </StickiedTopPart>
        <ContainerForInputFields>
          <CustomInputField
            value={name}
            setValue={setName}
            placeholder="Enter a name"
            title="Name for your style"
          />
          <CustomInputField
            value={prefixPrompt}
            setValue={setPrefixPrompt}
            placeholder="Example: a professional photo of"
            title="Prompt to add before user prompt"
            isPrompt
          />
          <CustomInputField
            value={suffixPrompt}
            setValue={setSuffixPrompt}
            placeholder="Example: (detailed face and eyes:1.4)"
            title="Prompt to add after user prompt"
            isPrompt
          />
          <CustomInputField
            value={negativePrompt}
            setValue={setNegativePrompt}
            placeholder="Example: (bad quality)"
            title="Negative Prompt"
            isNegativePrompt
          />

          <EngineSelector engine={engine} setEngine={setEngine} />
        </ContainerForInputFields>
        <CreateButton onClick={handleCreatingANewStyle}>
          <BiSave />
          {saveText}
        </CreateButton>

        {isEditingCustomStyle && (
          <CreateButton onClick={handleUpdatingStyle}>
            <BiSave />
            Update
          </CreateButton>
        )}
      </TransitioningModal>
    </>
  );
};

const Caption = styled.div`
  display: flex;
  justify-self: center;

  margin-top: 8px;
  margin-bottom: 16px;
`;

const RemoveStyleButton = styled(BiTrash)`
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${PRIMARY_COLOR};
    }
  }
`;

const CreateButton = styled(StyledButton)`
  font-size: 14px;
  // center
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: center;
  justify-self: center;
`;

const ContainerForInputFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
`;

interface ICustomInputField {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  title: string;
  isPrompt?: boolean;
  isNegativePrompt?: boolean;
}
const CustomInputField = ({
  value,
  setValue,
  placeholder,
  title,
  isPrompt,
  isNegativePrompt,
}: ICustomInputField) => {
  const { userPrompt, negativePrompt } = useImageGenerationSettingsContext();
  const handleCopyCurrentPrompt = () => {
    if (isPrompt) {
      setValue(value + userPrompt);
    }
    if (isNegativePrompt) {
      setValue(value + negativePrompt);
    }
  };

  const renderTheButton = isPrompt || isNegativePrompt;
  const textToRender = isPrompt
    ? 'Paste current prompt'
    : 'Paste current negative prompt';

  return (
    <OuterContainerForInputField>
      <Title>{title}</Title>
      <InputContainer>
        <StylishInput
          value={value}
          setValue={setValue}
          placeholder={placeholder}
        />
      </InputContainer>
      {renderTheButton && (
        <InputFieldButton onClick={handleCopyCurrentPrompt}>
          <MdOutlineCopyAll />
          {textToRender}
        </InputFieldButton>
      )}
    </OuterContainerForInputField>
  );
};

const InputFieldButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  font-size: 12px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-color: ${BORDER_COLOR};
  gap: 4px;

  margin-top: -2px;
  margin-bottom: 2px;
`;

const OuterContainerForInputField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

export default ManageCustomStylesModal;
