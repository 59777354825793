import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

const StrongPrimaryButton = styled.button`
  background-color: ${PRIMARY_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  padding: 10px 20px;

  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  outline: none;
  touch-action: manipulation;


  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      transform: translate(-50%, -2px);
      transition: transform 0.2s;
    }
  }
`;

export default StrongPrimaryButton;