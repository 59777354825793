import { useCookies } from 'react-cookie';
import { getBaseUrlForFetching } from '../../App';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useState } from 'react';

const useSetDisplayName = () => {
  const headers = {
    'Content-type': 'Application/json',
  };
  const { setLoggedInUser } = useLoggedInUserContext();
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(['token']);
  const token = cookies.token;
  const setDisplayName = async (): Promise<boolean> => {
    const newName = window.prompt('Enter your new display name');
    if (!newName) {
      return false;
    }

    if (newName.length <= 4 || newName.length >= 20) {
      alert('Display name must be 4 to 20 characters');
      return false;
    }

    const functionName = 'setDisplayName';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    if (!token) {
      return false;
    }

    setLoading(true);

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({ newName, token }),
      headers,
    });

    setLoading(false);

    if (response.status !== 200) {
      return false;
    }
    setLoggedInUser((prevState) => {
      if (prevState !== null) {
        return { ...prevState, displayName: newName, id: prevState.id || '' };
      } else {
        return null;
      }
    });

    return true;
  };
  return {
    setDisplayName,
    loading,
  };
};

export default useSetDisplayName;
