import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { TabInCreateCharacter } from '../../types';
import Subcategory from './Subcategory';
import TabSelectorInCreateCharacter from './TabSelectorInCreateCharacter';
import { getCategoryForMagicMode } from './magicModeHelpers';
import StickyButtonsOnBottomInMagic from './StickyButtonsOnBottomInMagic';
import BasicContainerWithFadeIn from '../../components/common/BasicContainerWithFadeIn';
import { waitSeconds } from '../../components/helpers';
import { PRIMARY_TEXT_COLOR, TALL_PORTRAIT_SIZE } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import GeneratedMagicModeImages from './GeneratedImagesMagicMode';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import ToggleSwitchInMagicMode from './ToggleSwitchInMagicMode';
import { StyledButton } from '../../components/common/StyledButton';
import { useSwipeable } from 'react-swipeable';
import { scrollToTop } from '../../hooks/misc/helpers';

const MagicModeTab = () => {
  const [activeTab, setActiveTab] = useState<TabInCreateCharacter>('base');
  const { images, activeImage } = useGeneralContext();
  const { selectedTags, setSelectedTags, setSize } =
    useImageGenerationSettingsContext();
  const activeCategory = getCategoryForMagicMode(activeTab);

  const handleTabChange = () => {
    if (activeTab === 'other') return;
    if (activeTab === 'body') return setActiveTab('other');
    if (activeTab === 'base') return setActiveTab('head');
    if (activeTab === 'head') return setActiveTab('body');
  };

  const setTabOnTopBar = async (tab: TabInCreateCharacter) => {
    setActiveTab(tab);
    await waitSeconds(0.1);
    const topAmount = images.length === 0 ? 430 : 800;
    window.scrollTo({ top: topAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  // TODO: when carousel swiping this triggers as well, need to fix
  // maybe by detecting only if some thing is visible the swipe is enabled
  const DISABLE = true;
  const changeTab = async (direction: number) => {
    if (DISABLE) return;
    const tabs: TabInCreateCharacter[] = ['base', 'head', 'body', 'other'];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = (currentIndex + direction + tabs.length) % tabs.length;
    await setTabOnTopBar(tabs[nextIndex]);
  };

  const swipingHandlers = useSwipeable({
    onSwipedLeft: () => changeTab(1),
    onSwipedRight: () => changeTab(-1),
    trackMouse: true,
    delta: 10,
  });

  const [showGeneratedImages, setShowGeneratedImages] = useState(false);

  useEffect(() => {
    const hasGeneratedImages =
      images.length > 0 &&
      activeImage?.selectedTags &&
      activeImage.selectedTags.length > 0;

    if (hasGeneratedImages) {
      setShowGeneratedImages(true);
    }
  }, [images, activeImage]);

  useEffect(() => {
    if (activeImage.selectedTags) {
      setSelectedTags(activeImage.selectedTags);
    }
    setSize(TALL_PORTRAIT_SIZE);
  }, []);

  const handleClearTags = () => {
    setSelectedTags([]);
  };

  const showClearButton = selectedTags.length > 0;

  const { setUploadedImageUrl, setDenoisingStrength, setIsCreatingVariations } =
    useImageGenerationSettingsContext();
  const [keepSameFaceMode, setKeepSameFaceMode] = useState(false);

  useEffect(() => {
    if (keepSameFaceMode) {
      setDenoisingStrength(0.85);
      setIsCreatingVariations(true);
      setUploadedImageUrl(activeImage.imageUrl);
    } else {
      setUploadedImageUrl('');
    }
  }, [keepSameFaceMode]);

  const [showSameFaceButton, setShowSameFaceButton] = useState(false);

  useEffect(() => {
    const showSameFaceButton =
      activeCategory.id === 'base' &&
      !!activeImage?.imageUrl &&
      !!activeImage?.selectedTags &&
      activeImage?.selectedTags.length > 0;
    setShowSameFaceButton(showSameFaceButton);
  }, [activeCategory, activeImage]);

  return (
    <BasicContainerWithFadeIn {...swipingHandlers}>
      {showGeneratedImages ? (
        <GeneratedMagicModeImages />
      ) : (
        <WelcomeComponent />
      )}
      <TabSelectorInCreateCharacter
        activeTab={activeTab}
        setActiveTab={setTabOnTopBar}
      />
      {showClearButton && (
        <ClearTagsButton onClick={handleClearTags}>
          🧹 Clear tags
        </ClearTagsButton>
      )}
      <SubCategoriesContainer>
        {showSameFaceButton && (
          <ToggleSwitchInMagicMode
            value={keepSameFaceMode}
            setValue={setKeepSameFaceMode}
          />
        )}
        {activeCategory.subcategories.map((subcategory) => (
          <Subcategory
            key={subcategory.id}
            subcategory={subcategory}
            autoOpen
          />
        ))}
      </SubCategoriesContainer>

      <StickyButtonsOnBottomInMagic
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleTabChange={handleTabChange}
      />
    </BasicContainerWithFadeIn>
  );
};

export default MagicModeTab;

const ClearTagsButton = styled(StyledButton)``;

const SubCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin-top: 16px;

  margin-bottom: 180px;
`;

const WelcomeComponent = () => {
  return (
    <WelcomeContainer>
      <HeroThing>
        <Title>Generate Women</Title>
        Using Tags
      </HeroThing>

      <TextInWelcome>
        <p>🏷️ Choose tags below to generate a girl</p>
        <p>🎲 Leave an option 'Empty' to let the AI surprise you</p>
        <p>✨ Hit 'Generate' to start generation!</p>
        <p>
          🍀 <i>Perfect results might take a few attempts</i>
        </p>
      </TextInWelcome>
    </WelcomeContainer>
  );
};

const backgroundImageUrl =
  'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/b65bd857-477c-400b-8cff-34ba2a19f0e5.jpeg';

const HeroThing = styled.div`
  position: relative;
  margin-left: 4px;
  background-image: url(${backgroundImageUrl});
  background-size: cover;
  background-position: center 20%;
  height: 80px;
  width: 84vw;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px; // Adds some padding around the content
  color: white;
  z-index: 1; // Ensures the content is on top of the background image

  ${desktopMediaQuery} {
    width: 400px;
    height: 200px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(
      0,
      0,
      0,
      0.3
    ); // Change the last value to adjust the darkness
    z-index: -1; // Ensures the overlay is behind the content
  }
`;

const Title = styled.h1`
  font-size: 28px;
  margin: 0; // Removes default margin
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-top: 26px;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
  ${desktopMediaQuery} {
    margin-left: 10vw;
  }
`;

const TextInWelcome = styled.div`
  font-size: 16px;
  ${desktopMediaQuery} {
    font-size: 18px;
  }

  color: ${PRIMARY_TEXT_COLOR};
  text-align: left;
  margin-bottom: 0px;

  max-width: 100%;

  padding-left: 24px;
`;
