import ReactModal from "react-modal";
import styled from "styled-components";
import { BACKGROUND_COLOR, PRIMARY_COLOR } from "../../constants";
import { StyledButton } from "../common/StyledButton";

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const HighTrafficModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(true);
  };
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          border: "none",
          borderRadius: "16px",
          padding: "32px",
          width: "80%",
          maxWidth: "600px",
          margin: "auto",
          marginTop: "200px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <TitleText>Site down for maintenance 🚧 </TitleText>
      <ModalText>Check back in 15 minutes</ModalText>
      <ModalText>So sorry about this 🙏 </ModalText>
    </Modal>
  );
};

//<AcceptButton onClick={handleClose}>OK!</AcceptButton>
const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 50px;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default HighTrafficModal;
