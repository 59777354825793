import React, { useEffect } from 'react';
import styled from 'styled-components';
import HomePageImageFeed from './HomePageImageFeed';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import NotificationBar from './HomePageNotification/NotificationBar';
import { scrollToTop } from '../../hooks/misc/helpers';
import HomePageGenerator from '../HomePageGenerator/HomePageGenerator';
import { desktopMediaQuery } from '../../styleHelpers';

const HomePage: React.FC = () => {
  const { isLoadingLogin } = useLoggedInUserContext();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <OuterContainer>
      <NotificationBar />

      <HomePageGenerator />

      <OrBrowseText />
      {isLoadingLogin ? (
        <LoadingAnimation
          loaderToChoose={2}
          loading={true}
          style={{ marginTop: '80px' }}
        />
      ) : (
        <HomePageImageFeed />
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 120px;
`;

const OrBrowseText = () => {
  return (
    <OrBrowseTextContainer>
      <ShortLine />
      example images below
      <ShortLine />
    </OrBrowseTextContainer>
  );
};

const OrBrowseTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: 500;
  color: ${SECONDARY_TEXT_COLOR};
  text-align: center;

  ${desktopMediaQuery} {
    margin-left: 5vw;
  }
`;

const ShortLine = styled.div`
  width: 13vw;
  height: 2px;
  background-color: ${SECONDARY_TEXT_COLOR};
  margin: 0 auto;
`;

export default HomePage;
