/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { getBaseUrlForFetching } from '../../App';

/**
 * Used to check if the user's session is still valid in Cloudflare.
 * User is asked to refresh page if not
 * @returns true if the user needs to refresh the page
 */
export const useSessionCheck = () => {
  const [needRefresh, setNeedRefresh] = useState(false);

  const functionName = 'check-session';
  const baseUrl = getBaseUrlForFetching();
  const fetchUrl = `${baseUrl}/${functionName}`;

  const CHECK_INTERVAL_MINUTES = 720;

  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      return;
    }
    if (needRefresh) {
      return;
    }
    const intervalId = setInterval(() => {
      /*
      fetch(fetchUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== 'ok') {
            setNeedRefresh(true);
          }
        })
        .catch(() => {
          setNeedRefresh(true);
        });
        */
    }, CHECK_INTERVAL_MINUTES * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return needRefresh;
};
