import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../constants';

const BenefitsToRegister: React.FC = () => {
  return (
    <Container>
      <Row>Get 30 free credits for Gold features</Row>
      <Row>Save your images ❤️</Row>
      <Row>Upload reference images and more!</Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -24px;
  margin-bottom: 16px;
  padding-top: 0;
  width: 100%;
`;

const Row = ({ children }: { children: React.ReactNode }) => (
  <p
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      marginBottom: '-2px',
    fontSize: '14px',
    }}
  >
    <IoMdCheckmark color={PRIMARY_COLOR} /> {children}
  </p>
);

export default BenefitsToRegister;
