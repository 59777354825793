import { toast } from 'react-toastify';
import { useCacheContext } from '../../context/CacheContext';
import { FeedChannel, SharedImage } from '../../types';
import { customFetch } from '../customFetch';

type FetchPostedImages = {
  channelName?: string;
  sort?: string;
  channelList: FeedChannel[];
};

const getChannel = (channelName: string, channelList: FeedChannel[]) => {
  if (channelName.toLowerCase() === 'all') {
    return undefined;
  }
  return channelList.find((c) => c.name.toLowerCase() === channelName);
};

const useGetPostedImages = () => {
  const { postedImagesOnFeedCache, setPostedImagesOnFeedCache } =
    useCacheContext();

  const getPostedImages = async ({
    channelName: SC = 'all',
    sort: SSO = 'hot',
    channelList,
  }: FetchPostedImages): Promise<SharedImage[]> => {
    const channelName = SC.toLowerCase();
    const sort = SSO.toLowerCase();

    const key = `${channelName}-${sort}`;

    const cachedImages = postedImagesOnFeedCache[key];
    if (cachedImages && cachedImages.expiresAt > new Date()) {
      console.log('Returning cached images');
      return cachedImages.images;
    }

    const channel = getChannel(channelName, channelList);

    const functionName = 'getPostedImages';
    try {
      const images = await customFetch(functionName, {
        channel,
        sort,
      });

      if (!images) {
        toast.error('Failed to get images');
      }

      const expiresAt = new Date();
      expiresAt.setMinutes(expiresAt.getMinutes() + 5);
      setPostedImagesOnFeedCache((prev) => ({
        ...prev,
        [key]: {
          images,
          expiresAt,
        },
      }));
      return images;
    } catch (error) {
      console.error('Error fetching posted images:', error);
      return [];
    }
  };

  return getPostedImages;
};

export default useGetPostedImages;
