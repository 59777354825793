import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AdComponent = () => {
  const adRef = useRef<HTMLDivElement>(null);
  const disableAds= true;
  const [adLoadFailed, setAdLoadFailed] = useState<boolean>(false);

  const clss = '__clb-2036449';

  useEffect(() => {
    if (adRef.current) {
      const script = document.createElement('script');
      const random = Math.random();
      const src1 = '//chaseherbalpasty.com/lv/esnk/2036449/code.js';
      const src2 = '//bullionglidingscuttle.com/lv/esnk/2036449/code.js';
      const srcToUse = random < 0.5 ? src1 : src2;
      script.src = srcToUse;
      script.async = true;
      script.setAttribute('data-cfasync', 'false');
      script.className = clss;

      adRef.current.appendChild(script);
      script.onload = () => console.log('Ad script loaded', srcToUse);

      script.onerror = (error) => {
        console.error('Ad script failed to load');
        console.error(error);
        setAdLoadFailed(true);
      };

      return () => {
        adRef.current?.removeChild(script);
      };
    }
  }, []);

  if (disableAds) {
    return null;
  }

  return adLoadFailed ? null : (
    <Container id={'mobile-adx'} className={clss} ref={adRef}>
      {/* Ad will be injected into this div */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  // 300 x 250 scaled down 80%
  width: 300px;
  height: 250px;
  overflow: hidden;

  margin-top: 4px;
  margin-bottom: 12px;

  & > * {
    width: 100%;
    height: 100%;
  }
`;

export default AdComponent;
