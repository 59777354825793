import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../misc/helpers";
import { FakeImage, FolderInterface } from "../../types";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { toast } from "react-toastify";

type Returns = {
  status: number;
};

const useCreateFolder = () => {
  const [{ token }] = useCookies(["token"]);
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();

  // if logged in user is not gold, then check if they have more than 30 folders. If yes, then return
  const checkIfIsTooManyFolders = (limit: number) => {
    if (loggedInUser?.type !== "gold") {
      const existingFolders = loggedInUser?.folders ?? [];
      if (existingFolders.length > limit) {
        return true;
      }
    }
    return false;
  };

  const updateLoggedInUserFolders = (folderToAdd: FolderInterface) => {
    if (!loggedInUser) {
      return;
    }

    const existingFolders = loggedInUser.folders ?? [];

    const newFolders = [...existingFolders, folderToAdd];
    const newLoggedInUser = { ...loggedInUser, folders: newFolders };
    setLoggedInUser(newLoggedInUser);
  };

  const createFolder = async (
    folderName: string,
    firstImageToAdd: FakeImage
  ): Promise<Returns> => {
    if (!token) {
      return { status: 401 };
    }

    if (checkIfIsTooManyFolders(30)) {
      toast.error("You have over 30 folders. Upgrade to Gold or delete some.");
      return { status: 403 };
    }

    const functionName = "createFolder";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        folderName,
        token,
        firstImageToAdd,
      }),
      headers,
    });

    const { status } = response;

    if (status !== 200) {
      console.log(`Error creating folder: ${folderName}`);
      return { status };
    }

    const body = await response.json();

    const { folder } = body;

    updateLoggedInUserFolders(folder);

    return { status };
  };
  return createFolder;
};

export default useCreateFolder;
