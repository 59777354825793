import styled from "styled-components";
import { BACKGROUND_COLOR } from "../../../constants";
import { StyledButton } from "../../common/StyledButton";
import { StyledInputSmall } from "../../common/StyledInputSmall";
import TransitioningModal from "../TransitioningModal";
import { useState } from "react";

interface Props {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  handleCreateNewFolder: (folderName: string) => void;
}
const AddNewFolderModal = ({
  showModal,
  setShowModal,
  handleCreateNewFolder,
}: Props) => {
  const [folderName, setFolderName] = useState("");

  const handleClick = () => {
    handleCreateNewFolder(folderName);
    setFolderName("");
    setShowModal(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };
  return (
    <TransitioningModal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 300,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: "36px",
          paddingRight: "48px",
          width: "100%",
          maxWidth: "300px",
          maxHeight: "200px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShowModal(false)}
    >
      <Container>
        Choose a name for the Folder
        <StyledInputSmall
          placeholder="Folder Name"
          autoFocus
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <StyledButton onClick={handleClick}>Create</StyledButton>
      </Container>
    </TransitioningModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

export default AddNewFolderModal;
