import { useNavigate } from "react-router-dom";
import "./TopBar.css";
import OnlyFakesLogo from "./OnlyFakesLogo";
import TopRightCornerPartOfTopBar from "./TopRightCornerPartOfTopBar";
import {
  TopBarContainer,
  LeftSideContainerInTopBar,
  RightSideContainerInTopBar,
} from "./TopBarCommonComponents";
import { getIsOnDesktop } from "../../../styleHelpers";

const URL_PART_WHEN_TO_HIDE_TOP_BAR = "feed?id=";

/**
 * Determines whether the top bar should be hidden based on the current URL.
 * @returns {boolean} `true` if the top bar should be hidden, `false` otherwise.
 */
export const shouldHideTopBar = () => window.location.href.includes(URL_PART_WHEN_TO_HIDE_TOP_BAR) && !getIsOnDesktop();

const TopBar = () => {
  const navigate = useNavigate();

  const handleTakeToLandingPage = () => {
    navigate("/");
  };

  /*
  const shouldUseVisible = window.location.href.includes("character");

  const { isVisible } = useHandleControlNavbar();
  */

  const showTheBar = !shouldHideTopBar();


  return (
    <TopBarContainer isVisible={showTheBar}>
      <LeftSideContainerInTopBar onClick={handleTakeToLandingPage}>
        <OnlyFakesLogo />
      </LeftSideContainerInTopBar>
      <RightSideContainerInTopBar>
        <TopRightCornerPartOfTopBar />
      </RightSideContainerInTopBar>
    </TopBarContainer>
  );
};

export default TopBar;
