import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ANON_NAME_TO_USE } from '../../constants';
import { PROFILE_PATH } from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';
import { UserAvatar } from '../UserProfiles/UserAvatar';
import { SmallStyledButton } from '../common/StyledButton';
import useGetUserDetailsWithSharedImage from '../../hooks/feed/useGetUserDetailsWithSharedImage';
import { SharedImage } from '../../types';

interface UserNameAndAvatarProps {
  image: SharedImage | null;
  inHomePage?: boolean;
}

const UserNameAndAvatar: React.FC<UserNameAndAvatarProps> = ({
  image,
  inHomePage,
}) => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState<string>(ANON_NAME_TO_USE);
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>(
    undefined,
  );
  const [isUserGold, setIsUserGold] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const getUserDetailsWithSharedImage = useGetUserDetailsWithSharedImage();

  useEffect(() => {
    const fetchData = async () => {
      if (!image) return;
      const user = await getUserDetailsWithSharedImage(image);
      if (user) {
        if (user.displayName) {
          setDisplayName(user.displayName);
        }
        if (user.profileImageUrl) {
          setProfileImageUrl(user.profileImageUrl);
        }
        if (user.type === 'gold') {
          setIsUserGold(true);
        }
        setUserId(user.id);
      }
    };
    fetchData();
  }, [image]);

  const handleGoToProfile = () => {
    userId && navigate(`${PROFILE_PATH}/${userId}`);
  };

  const isLongName = displayName.length > 11;
  const textToShowInViewProfileButton = isLongName ? 'View' : 'View Profile';

  return (
    <ContainerForNameAndAvatar onClick={handleGoToProfile}>
      <UserAvatar
        url={profileImageUrl}
        imageDetailVariant
        inHomePage={inHomePage}
      />
      <DisplayName>
        {displayName} {isUserGold && <span>⭐</span>}
      </DisplayName>
      <ViewProfileButton>{textToShowInViewProfileButton}</ViewProfileButton>
    </ContainerForNameAndAvatar>
  );
};

const ContainerForNameAndAvatar = styled.div<{ inHomePage?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  ${desktopMediaQuery} {
    justify-content: center;
    gap: 32px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const DisplayName = styled.div`
  font-weight: bold;
  font-size: 16;

  margin-top: 4px;

  white-space: nowrap;
`;

const ViewProfileButton = styled(SmallStyledButton)`
  margin-left: auto;
  margin-right: 8px;
  margin-top: 2px;
  ${desktopMediaQuery} {
    margin-right: 16px;
  }
  background-color: transparent;
`;

export default UserNameAndAvatar;
