import styled from 'styled-components';
import StylishInput from '../../components/common/StyledInput';
import { ClearInputIcon } from '../../components/common/ClearInputIcon';
import { FlexBoxRow } from '../../components/common/FlexBoxRow';
import { InputContainer } from '../../components/common/InputContainer';
import { MdOutlineCopyAll } from 'react-icons/md';
import { StyledButton } from '../../components/common/StyledButton';
import { BORDER_COLOR } from '../../constants';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';

interface P {
  prompt: string;
  setPrompt: (prompt: string) => void;
}

const PromptInput = ({ prompt, setPrompt }: P) => {
  const { userPrompt } = useImageGenerationSettingsContext();
  const handleCopyCurrentPrompt = () => {
    setPrompt(prompt + userPrompt);
  };
  return (
    <OuterContainer>
      <TopLabelsContainer>
        <Label>Prompt 💬</Label>
        <InputFieldButton onClick={handleCopyCurrentPrompt}>
          <MdOutlineCopyAll />
          Paste (recommended)
        </InputFieldButton>
        <FlexBoxRow>
          <LengthLabel>{prompt.length} / 750</LengthLabel>
          <ClearInputIcon onClick={() => setPrompt('')} />
        </FlexBoxRow>
      </TopLabelsContainer>

      <Container>
        <InputContainer>
          <StylishInput
            value={prompt}
            setValue={setPrompt}
            placeholder="What you want to be changed"
          />
        </InputContainer>
      </Container>
    </OuterContainer>
  );
};

const InputFieldButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  font-size: 12px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-color: ${BORDER_COLOR};
  gap: 4px;

  margin-top: -2px;
  margin-bottom: 2px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 6px;
`;

const TopLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 18px;
`;

const Label = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
`;

const LengthLabel = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default PromptInput;
