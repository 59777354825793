import React, { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  PRIMARY_COLOR,
  BORDER_COLOR,
  SECONDARY_TEXT_COLOR,
  BACKGROUND_COLOR,
} from '../../constants';
import {
  SubcategoryId,
  SubcategoryInMagicMode,
  TagInMagicMode,
} from '../../types';
import { desktopMediaQuery } from '../../styleHelpers';
import { fadeIn } from '../../components/ImageStuff/animations';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { FaDice, FaRunning, FaSmile, FaTshirt } from 'react-icons/fa';
import {
  FaCamera,
  FaCity,
  FaEye,
  FaGlobe,
  FaHourglassHalf,
  FaPersonDress,
  FaScissors,
} from 'react-icons/fa6';
import { IconType } from 'react-icons';
import CustomTagModal from './CustomTagModal';

interface Props {
  subcategory: SubcategoryInMagicMode;
  autoOpen?: boolean;
}

// Ensure the mapping matches the IconType from react-icons
const iconMapping: { [key in SubcategoryId]: IconType } = {
  age: FaHourglassHalf,
  origin: FaGlobe,
  'hair-color': FaScissors,
  'hair-style': FaScissors,
  face: FaSmile,
  'eye-color': FaEye,
  'body-type': FaPersonDress,
  boobs: FaPersonDress,
  clothing: FaTshirt,
  view: FaCamera,
  scene: FaCity,
  action: FaRunning,
};

const Subcategory: React.FC<Props> = ({ subcategory, autoOpen }) => {
  const [isOpen, setIsOpen] = useState(!!autoOpen);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { selectedTags, setSelectedTags } = useImageGenerationSettingsContext();

  const handleSelectTag = (tag: TagInMagicMode) => {
    window.scrollBy({
      top: 240, // scroll down by 160px
      behavior: 'smooth',
    });

    const isMultiSelect = subcategory ? subcategory.multiSelect : true; // Default to true if not found

    // TODO: rewrite this whole logic
    // Some complicated magic to make sure the user prompt is updated correctly when you can only select one tag
    setSelectedTags((current: TagInMagicMode[]) => {
      const isTagSelected = current.some(
        (selectedTag) => selectedTag.id === tag.id,
      );

      if (isMultiSelect) {
        if (isTagSelected) {
          return current.filter((currentTag) => currentTag.id !== tag.id);
        }
        return [...current, tag];
      } else {
        // For single-select subcategories, always replace the tag from the same subcategory
        return current
          .filter(
            (currentTag) => currentTag.subcategoryId !== tag.subcategoryId,
          )
          .concat(tag);
      }
    });
  };

  const buttonRef = useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelectRandomTag = async () => {
    const randomTag =
      subcategory.tags[Math.floor(Math.random() * subcategory.tags.length)];
    handleSelectTag(randomTag);
    // TODO: set some visual feedback that the random tag was selected
  };

  const handleSelectEmpty = () => {
    setSelectedTags((current) =>
      current.filter((tag) => tag.subcategoryId !== subcategory.id),
    );
  };

  const isTagSelected = (tagId: string) => {
    return selectedTags.some((tag) => tag.id === tagId);
  };
  const getShouldShowEmptyAsSelected = () => {
    return !subcategory.tags.some((tag) => isTagSelected(tag.id));
  };

  const [showCustomTagModal, setShowCustomTagModal] = useState(false);
  const handleClickAddCustomTag = async () => {
    /*
    if (loggedInUser?.type !== 'gold') {
      toast.error('Get Gold Subscription ⭐ to use this feature');
      await waitSeconds(1)
      setGoldModalOpen(true);
      return;
    }
      */
    setShowCustomTagModal(true);
  };

  const isCustomSelected = selectedTags.some(
    (tag) => tag.subcategoryId === subcategory.id && tag.isCustomTag,
  );

  const shouldShowEmptyAsSelecetd =
    !isCustomSelected && getShouldShowEmptyAsSelected();

  const Icon = iconMapping[subcategory.id];

  return (
    <Container>
      <Label onClick={toggleOpen} autoOpen={autoOpen}>
        <Icon key={subcategory.id} size={16} />
        {subcategory.label}
        {subcategory.multiSelect && (
          <ChooseOneOrMoreText>Multichoice</ChooseOneOrMoreText>
        )}
      </Label>
      <TagsContainer>
        <SelectyEmptyButton
          onClick={handleSelectEmpty}
          isSelected={shouldShowEmptyAsSelecetd}
          ref={buttonRef}
        />
        {subcategory.tags.map((tag) => (
          <TagButton
            key={tag.id}
            isSelected={isTagSelected(tag.id)}
            onClick={() => handleSelectTag(tag)}
          >
            {tag.label}
          </TagButton>
        ))}
        <TagButton
          onClick={handleClickAddCustomTag}
          isSelected={isCustomSelected}
        >
          + Custom
        </TagButton>
      </TagsContainer>

      <CustomTagModal
        show={showCustomTagModal}
        setShow={setShowCustomTagModal}
        handleSelectTag={handleSelectTag}
        subcategory={subcategory}
      />
    </Container>
  );
};

export default Subcategory;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  width: 90vw;
  margin-left: 8px;

  ${desktopMediaQuery} {
    width: 700px;
    margin-left: 400px;
  }

  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Label = styled.div<{ autoOpen?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  width: 90%;

  font-size: 18px;

  margin-bottom: 8px;
  margin-left: 2px;

  transition: 0.3s;
`;

const ChooseOneOrMoreText = styled.div`
  font-size: 14px;
  margin-left: 16px;
  margin-top: 4px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const TagsContainer = styled.div`
  display: flex;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  gap: 8px;

  overflow-x: auto;
  max-width: 90vw;

  ${desktopMediaQuery} {
    width: 1000px;
  }

  flex-wrap: wrap;

  min-height: '50px';
  padding-bottom: 20px;
`;

interface TagButtonProps {
  isSelected: boolean;
  isRandomButton?: boolean;
}

const TagButton = styled.button<TagButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${BACKGROUND_COLOR};
  border-radius: 4px;

  transition: 0.3s;

  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: -2px;

  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  min-width: 75px;
  font-size: 16px;
  height: 28px;
  white-space: nowrap;

  border: 1px solid
    ${({ isSelected }) => (isSelected ? PRIMARY_COLOR : BORDER_COLOR)};

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border: 1px solid ${PRIMARY_COLOR};
    }
  }
`;

interface RandomButtonProps extends TagButtonProps {
  onClick: () => void;
  ref: React.RefObject<HTMLButtonElement>;
}
const SelectyEmptyButton = forwardRef(
  (props: RandomButtonProps, ref: React.Ref<any>) => {
    const { onClick, isSelected } = props;
    return (
      <TagButton
        onClick={onClick}
        isSelected={isSelected}
        ref={ref}
        isRandomButton
      >
        <FaDice
          style={{
            marginRight: '4px',
          }}
          size={14}
        />
        Empty
      </TagButton>
    );
  },
);
