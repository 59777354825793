import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import LabelWithTooltip from '../../../components/common/LabelWithTooltip';
import { EngineType, StyleInterface } from '../../../types';
import {
  getDefaultStyleViaId,
  getPreviewStyles,
} from '../../../utils/imageStylesHelpers';
import PreviewStyles from './PreviewStyles';
import ShowMoreButton from './ShowMoreButton';
import ShowAllStylesModal from './ShowAllStylesModal/ShowAllStylesModal';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';
import { desktopMediaQuery } from '../../../styleHelpers';
import { RiEyeCloseLine } from 'react-icons/ri';
import { FiSettings } from 'react-icons/fi';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { setRecentlyUsedStylesToLocalStorage } from '../../../localStorage/stylesStorage';

interface P {
  selectedStyle: StyleInterface;
  setSelectedStyle: Dispatch<SetStateAction<StyleInterface>>;
  setEngine: Dispatch<SetStateAction<EngineType>>;
}

const TOOLTIP_TEXT =
  "Style is a combination of 'engine', prompts and negative prompts. You can create your own style by clicking on the 'Menu' button.";

const StylesSection = ({ selectedStyle, setSelectedStyle, setEngine }: P) => {
  const {
    showHiddenStyleSettings,
    setShowHiddenStyleSettings,
    showAllStylesModal,
    setShowAllStylesModal,
    setDidClickEditButton,
  } = useImageGenerationSettingsContext();
  const { images } = useGeneralContext();
  const previewStyles = getPreviewStyles();

  const handleClickShowAll = () => {
    setShowAllStylesModal(true);
    setDidClickEditButton(false);
  };

  const handleChooseStyle = (style: StyleInterface) => {
    if (!style) {
      console.error('style is undefined, choosing none');
      const noneStyle = getDefaultStyleViaId('0');
      setSelectedStyle(noneStyle);
      return;
    }
    setSelectedStyle(style);

    if (window.innerWidth < 600 && images.length === 0) {
      window.scrollTo({
        top: 150,
        behavior: 'smooth',
      });
    }
    setRecentlyUsedStylesToLocalStorage(style);
    if (selectedStyle.id === '0') return;
    const stylesEngine = style?.engine;
    if (!stylesEngine) return;

    setEngine(stylesEngine);
  };

  const handleClickOnShowHiddenStyleSettings = () => {
    if (!showHiddenStyleSettings) {
    }
    setShowHiddenStyleSettings(!showHiddenStyleSettings);
  };

  return (
    <Container>
      <LabelContainer>
        <LabelWithTooltip
          labelText="🎨 Choose a style"
          tooltipText={TOOLTIP_TEXT}
        />
        <ShowHiddenStyleSettingsButton
          onClick={handleClickOnShowHiddenStyleSettings}
          isActive={showHiddenStyleSettings}
        />
      </LabelContainer>
      <ContainerForStyleSelection>
        <PreviewStyles
          selectedStyle={selectedStyle}
          previewStyles={previewStyles}
          handleChooseStyle={handleChooseStyle}
        />
        <ShowMoreButton
          onClick={handleClickShowAll}
          selectedStyle={selectedStyle}
        />
      </ContainerForStyleSelection>

      <ShowAllStylesModal
        show={showAllStylesModal}
        setShow={setShowAllStylesModal}
        handleChooseStyle={handleChooseStyle}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;

  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8px;
  align-self: flex-start;
  margin-bottom: -8px;
  width: 100%;
`;

interface ShowHiddenStyleSettingsButtonProps {
  isActive: boolean;
  onClick: () => void;
}
const ShowHiddenStyleSettingsButton = ({
  isActive,
  onClick,
}: ShowHiddenStyleSettingsButtonProps) => (
  <ShowHiddenStyleSettingsButtonContainer onClick={onClick} isActive={isActive}>
    {isActive ? <RiEyeCloseLine /> : <FiSettings />}
    {isActive ? 'Hide Edit' : 'Edit Style'}
  </ShowHiddenStyleSettingsButtonContainer>
);

const ShowHiddenStyleSettingsButtonContainer = styled.div<ShowHiddenStyleSettingsButtonProps>`
  font-size: 14px;
  margin-right: 8px;

  display: flex;
  align-items: center;
  gap: 4px;

  margin-bottom: 4px;

  cursor: pointer;

  ${desktopMediaQuery} {
    &:hover {
    }
  }
`;

const ContainerForStyleSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  gap: 2px;
`;

export default StylesSection;
