import styled from "styled-components";
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
} from "../../../constants";
import { desktopMediaQuery } from "../../../styleHelpers";

interface P {
  isSeedFrozen: boolean;
  setIsSeedFrozen: (isSeedFrozen: boolean) => void;
}

const LockSeedCheckbox = ({ isSeedFrozen, setIsSeedFrozen }: P) => {
  return (
    <Container onClick={() => setIsSeedFrozen(!isSeedFrozen)}>
      <StyledCheckbox
        type="checkbox"
        checked={isSeedFrozen}
        onChange={() => setIsSeedFrozen(!isSeedFrozen)}
      />
      <Label>Freeze</Label>
    </Container>
  );
};

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 20px;
  height: 20px;
  background: ${BACKGROUND_COLOR};
  border: 2px solid ${BORDER_COLOR};
  border-radius: 4px;
  position: relative;

  &:checked::before {
    content: "";
    position: absolute;
    left: 5px;
    bottom: 3px;
    width: 5px;
    height: 10px;
    border: solid ${PRIMARY_COLOR};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${PRIMARY_COLOR};
    }
  }

  width: 100px;
  margin-top: 4px;
`;

const Label = styled.label`
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${PRIMARY_COLOR};
    }
  }

  margin-left: 4px;
`;

export default LockSeedCheckbox;
