import styled from "styled-components";
import LabelWithTooltip from "../../../components/common/LabelWithTooltip";
import { StyledInputSmall } from "../../../components/common/StyledInputSmall";

interface P {
  seed: number;
  setSeed: (seed: number) => void;
}

const TOOLTIP_TEXT =
  "Same seed with same prompt generates the same image. Freeze it to do small tweaking, such as if you want different colored hair";

const SeedInputBox = ({ seed, setSeed }: P) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numValue = newValue === "" ? 0 : parseInt(newValue, 10);

    if (!isNaN(numValue) && numValue <= 4294967295) {
      setSeed(numValue);
    }
  };
  const value = seed === 0 ? "" : seed.toString();
  return (
    <Container>
      <LabelWithTooltip labelText="Seed" tooltipText={TOOLTIP_TEXT} />
      <StyledInputContainer>
        <StyledInputSmall
          type="number"
          max="4294967295"
          value={value}
          onChange={handleChange}
        />
      </StyledInputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 18px;
  margin-left: 2px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -8px;
  margin-left: 28px;
  width: 100%;
`;

export default SeedInputBox;
