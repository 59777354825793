import styled from 'styled-components';
import InputSectionInQuickGenerator from './InputSectionInQuickGenerator';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery, getIsOnDesktop } from '../../styleHelpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import GeneratedImagesContainer from '../AdvancedModeTab/GeneratedImagesContainer';
import { GENERATE_PATH, MAGIC_PATH } from '../../pathNames';
import TitleSectionInHomeGenerator from './TitleSectionInHomeGenerator';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import {
  trackFirstTimeUserClicksOpenTagGen,
  trackOpenInAdvancedGeneratorClicked,
} from '../../utils/analytics';
import FirstTimeUserStorage from '../../localStorage/firstTimeUserStorage';
import { useEffect, useState } from 'react';
import ExampleTagsPromptBox from '../AdvancedModeTab/ExampleTagsPromptBox';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';

const HomePageGenerator = () => {
  const { images, loading } = useGeneralContext();
  const {loggedInUser} = useLoggedInUserContext();

  const hasGeneratedImages = images.length > 0;

  const isOnDesktop = getIsOnDesktop();

  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean | null>(null);
  useEffect(() => {
    if (loggedInUser) {
      setIsFirstTimeUser(false);
      return;
    }
    const firstTimeUserStorage = new FirstTimeUserStorage();
    const hasVisitedBefore = firstTimeUserStorage.hasChecked();
    setIsFirstTimeUser(!hasVisitedBefore);
  }, [loggedInUser]);

  const shouldShowOpenTagGeneratorLink = isFirstTimeUser && !hasGeneratedImages;

  const shouldShowOpenAdvancedGeneratorLink =
    !hasGeneratedImages && isOnDesktop && !isFirstTimeUser;

  const textToShowOnTagGenCTA = false
    ? 'Click to generate in easy mode'
    : '..or generate with easy mode';

  const exampleTags = [
    'Hot blonde',
    'Cute brunette',
    'wearing blue dress',
    '(wearing red lingerie)',
    '(full body portrait)',
    'big boobs',
    'Swedish 25 yo',
    'nude',
    'sexy redhead',
    'in a dorm room',
    '(in a hotel room:1.1)',
    '(topless)',
    'messy blonde hair',
    'sitting in a gaming chair',
    'very short brown pixie hair',
    '(hour glass figure:1.2)',
  ];

  const [tags, setTags] = useState<string[]>(exampleTags);

  const { userPrompt, setUserPrompt } = useImageGenerationSettingsContext();

  const handleClickedTag = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));

    if (userPrompt === '') {
      setUserPrompt(tag);
      return;
    }
    setUserPrompt(userPrompt + ', ' + tag);
  };
  return (
    <Container hasGeneratedImages={hasGeneratedImages}>
      {hasGeneratedImages ? (
        <div>
          <GeneratedImagesContainer hideRandomTip smallerForHomepage />
          {!loading && (
            <LinkToAdvancedGenerator
              to={GENERATE_PATH}
              onClick={trackOpenInAdvancedGeneratorClicked}
            >
              Open in advanced generator{' '}
              <FaArrowRight color={'white'} size={18} />
            </LinkToAdvancedGenerator>
          )}
        </div>
      ) : (
        <TitleSectionInHomeGenerator />
      )}
      <InputSectionInQuickGenerator />

      <TagsContainer>
        <ExampleTagsPromptBox handleClickedTag={handleClickedTag} tags={tags} wider/>
      </TagsContainer>

      {shouldShowOpenTagGeneratorLink && (
        <BottomAdvGenLink
          to={MAGIC_PATH}
          smallerPadding={hasGeneratedImages}
          onClick={trackFirstTimeUserClicksOpenTagGen}
        >
          {textToShowOnTagGenCTA}
          <FaArrowRight color={PRIMARY_TEXT_COLOR} size={18} />
        </BottomAdvGenLink>
      )}

      {shouldShowOpenAdvancedGeneratorLink && (
        <BottomAdvGenLink to={GENERATE_PATH}>
          Open advanced generator{' '}
          <FaArrowRight color={PRIMARY_TEXT_COLOR} size={18} />
        </BottomAdvGenLink>
      )}
    </Container>
  );
};

const Container = styled.div<{ hasGeneratedImages: boolean }>`
  display: flex;
  flex-direction: column;

  //if hasnt generated images then height 60vh, otherwise 100%;
  height: ${({ hasGeneratedImages }) => (hasGeneratedImages ? '100%' : '60vh')};
  justify-content: center;
  align-items: center;

  width: 94vw;
  ${desktopMediaQuery} {
    margin-left: 5vw;
    width: 50vw;
  }
`;

const LinkToAdvancedGenerator = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 4px;

  color: ${SECONDARY_TEXT_COLOR};
  font-size: 16px;

  text-decoration: none;

  margin-top: -4px;
  margin-bottom: 16px;

  &:hover {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

const TagsContainer = styled.div`
  margin-top: 16px;
`;

const BottomAdvGenLink = styled(LinkToAdvancedGenerator)<{
  smallerPadding?: boolean;
}>`
  padding-top: ${({ smallerPadding }) => (smallerPadding ? '8' : '48')}px;
`;

export default HomePageGenerator;
