import styled from "styled-components";
import GenerateButton from "../AdvancedModeTab/ButtonsBelowImage/GenerateButton";
import PromptInput from "./PromptInputInInpainting";
import { StyledButton } from "../../components/common/StyledButton";
import NegativePromptInputInInpainting from "./NegativePromptInputInInpainting";
import LabelWithTooltip from "../../components/common/LabelWithTooltip";
import BrushSizeSetter from "./BrushSizeSetter";
import StyledSlider from "./StyledSlider";
import { DESKTOP_WIDTH } from "../../constants";
import { MdOutlineUndo } from "react-icons/md";
import { sharedIconStyle } from "../../components/common/IconButtonStuff";
import SeedInputBox from "../AdvancedModeTab/AdvancedOptions/SeedInputBox";
import SpeedModeSelector from "../AdvancedModeTab/ButtonsBelowImage/SpeedModeSelector";
import { SpeedModeType } from "../../types";

interface P {
  brushSize: number;
  setBrushSize: (brushSize: number) => void;
  clearCanvas: (canvas: HTMLCanvasElement) => void;
  generateInpainting: (canvas: HTMLCanvasElement) => void;
  canvas: HTMLCanvasElement;
  prompt: string;
  setPrompt: (prompt: string) => void;
  negativePrompt: string;
  setNegativePrompt: (negativePrompt: string) => void;
  imageStrength: number;
  setImageStrength: (imageStrength: number) => void;
  seed: number;
  setSeed: (seed: number) => void;

  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;

  handleUndo: () => void;
}

// clear&undo should maybe be below the image on desktop.
const OptionsBelowCanvas = ({
  brushSize,
  setBrushSize,
  clearCanvas,
  generateInpainting,
  canvas,
  prompt,
  setPrompt,
  negativePrompt,
  setNegativePrompt,
  imageStrength,
  setImageStrength,
  seed,
  setSeed,
  speedMode,
  setSpeedMode,

  handleUndo,
}: P) => {
  return (
    <Container
      onKeyDown={(e) => {
        if (window.innerWidth < DESKTOP_WIDTH) {
          return;
        }
        if (e.key === "Enter") {
          generateInpainting(canvas);
        }
      }}
    >
      <UndoIconContainer onClick={handleUndo}>
        <UndoIcon /> Undo
      </UndoIconContainer>
      <BrushSizeSetter brushSize={brushSize} setBrushSize={setBrushSize} />
      <ButtonsContainer>
        <SpeedModeSelector speedMode={speedMode} setSpeedMode={setSpeedMode} />
        <ClearButton onClick={() => clearCanvas(canvas)}>Clear 🧽 </ClearButton>
        <GenerateButton
          handleSendPrompt={() => generateInpainting(canvas)}
          inInpaintingPage
          speedMode={speedMode}
        />
      </ButtonsContainer>
      <PromptInput prompt={prompt} setPrompt={setPrompt} />
      <MarginBetweenInputs />
      <NegativePromptInputInInpainting
        negativePrompt={negativePrompt}
        setNegativePrompt={setNegativePrompt}
      />
      <ImageStrengthContainer>
        <LabelContainer>
          <LabelWithTooltip
            labelText="Strength"
            tooltipText="Choose how much of the original image to keep under the selection. 0 keeps most of it, while 1 follows mostly your prompt"
          />
        </LabelContainer>

        <StyledSlider
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={imageStrength}
          onChange={(e) => setImageStrength(Number(e.target.value))}
        />
        {imageStrength}
      </ImageStrengthContainer>

      <SeedContainer>
        <SeedInputBox seed={seed} setSeed={setSeed} />
      </SeedContainer>
    </Container>
  );
};

// TODO: it just looks wrong, fix at some point
const UndoIconContainer = styled.div`
  ${sharedIconStyle}
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  // on dekstop
  @media (min-width: ${DESKTOP_WIDTH}px) {
    align-self: center;
  }
`;

const UndoIcon = styled(MdOutlineUndo)``;

const MarginBetweenInputs = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 32px;
  //deksto
  @media (min-width: ${DESKTOP_WIDTH}px) {
    position: absolute;
    top: 100px;
    right: 60px;
    width: 500px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const ClearButton = styled(StyledButton)``;

const LabelContainer = styled.div`
  margin-left: 12px;
`;

const ImageStrengthContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 8px;

  margin-left: 12px;
`;

const SeedContainer = styled.div`
  margin-top: 24px;
  width: 50%;
  margin-left: 24px;
`;

export default OptionsBelowCanvas;
