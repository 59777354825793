import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useGetPostedImages from '../../hooks/feed/useGetPostedImages';
import { FeedChannel, SharedImage } from '../../types';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../pathNames';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import {
  filterImagesByAlreadySeen,
  filterImagesByButton,
} from '../FeedPage/feedPageHelpers';
import CategoryFilterButtons from './CategoryFilterButtons';
import BasicContainerWithFadeIn from '../../components/common/BasicContainerWithFadeIn';
import HomePageImageFeedContent from './HomePageImageFeedContent';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { desktopMediaQuery } from '../../styleHelpers';

const CATEGORIES = ['All', 'Realistic', 'Anime', 'Digital'];

const HomePageImageFeed: React.FC = () => {
  const [allImages, setAllImages] = useState<SharedImage[]>([]);
  const [imagesToShowIfBrowsingMore, setImagesToShowIfBrowsingMore] = useState<
    SharedImage[]
  >([]);
  const [imagesToDisplay, setImagesToDisplay] = useState<SharedImage[]>([]);
  const getPostedImages = useGetPostedImages();
  const navigate = useNavigate();

  const { loggedInUser } = useLoggedInUserContext();

  const { selectedCategory, setSelectedCategory } = useImagesContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const sortToUse = loggedInUser ? 'hot' : 'top';
      setLoading(true);
      const sort = sortToUse;
      const channelName = 'all';
      const channelList: FeedChannel[] = [];
      const images = await getPostedImages({
        sort,
        channelName,
        channelList,
      });
      setAllImages(images);
      setLoading(false);
    };
    fetchImages();
  }, [loggedInUser]);

  useEffect(() => {
    setLoading(true);
    if (allImages.length === 0) return;
    const imagesFilteredByButton = filterImagesByButton(
      allImages,
      selectedCategory,
      undefined,
    );

    const shouldFilterSeen = loggedInUser ? true : false;
    const imagesFilteredByAlreadySeen = filterImagesByAlreadySeen(
      imagesFilteredByButton,
      shouldFilterSeen,
    );

    setImagesToShowIfBrowsingMore(imagesFilteredByAlreadySeen);

    const top10Images = imagesFilteredByAlreadySeen.slice(0, 10);
    setImagesToDisplay(top10Images);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [selectedCategory, allImages]);

  const handleNavToFeedPage = () => {
    navigate(FEED_PATH);
  };

  return (
    <FeedOfImagesContainer>
      <CategoryFilterButtons
        categories={CATEGORIES}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      {loading ? (
        <LoadingAnimation
          loaderToChoose={2}
          loading={true}
          style={{ marginTop: '80px' }}
        />
      ) : (
        <HomePageImageFeedContent
          imagesToShowIfBrowsingMore={imagesToShowIfBrowsingMore}
          imagesToDisplay={imagesToDisplay}
          handleNavToFeedPage={handleNavToFeedPage}
        />
      )}
    </FeedOfImagesContainer>
  );
};

const FeedOfImagesContainer = styled(BasicContainerWithFadeIn)`
  width: 94vw;
  margin-top: 8px;

  ${desktopMediaQuery} {
    margin-left: 5vw;
  }
`;

export default HomePageImageFeed;
