import styled from "styled-components";
import StyledSlider from "./StyledSlider";

interface P {
  brushSize: number;
  setBrushSize: (brushSize: number) => void;
}

const BrushSizeSetter: React.FC<P> = ({ brushSize, setBrushSize }) => (
  <Container>
    <BrushSizeIndicator brushSize={brushSize} />
    <Wrapper>
      Brush size 🖌️
      <StyledSlider
        type="range"
        min="10"
        max="150"
        value={brushSize}
        onChange={(e) => setBrushSize(Number(e.target.value))}
      />
      +
    </Wrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;

  width: 100%;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  padding: 12px;
  font-weight: 600;
  white-space: nowrap;
`;

// Brush size indicator shows how many pixels across it will be
const BrushSizeIndicator = styled.div<{ brushSize: number }>`
  margin: 0 auto;
  width: ${(p) => p.brushSize}px;
  height: ${(p) => p.brushSize}px;
  border-radius: 50%;
  opacity: 0.2;
  background-color: white;
`;

export default BrushSizeSetter;
