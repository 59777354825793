import { useCookies } from "react-cookie";
import {
  ManageStyleActionsWithDelete,
  StyleInterface,
  StyleInterfaceWithoutId,
} from "../../types";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../misc/helpers";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";

interface Returns {
  status: number;
  style?: StyleInterface;
}

interface ManageStyleParams {
  style: StyleInterfaceWithoutId;
  action: ManageStyleActionsWithDelete;
}

const handleUpdatingLoggedInUserStyles = (
  prev: any,
  style: StyleInterface,
  action: ManageStyleActionsWithDelete
) => {
  let newStyles = [...prev.customStyles];

  if (action === "create") {
    newStyles.push(style);
  } else if (action === "update") {
    const index = newStyles.findIndex((s: StyleInterface) => s.id === style.id);
    if (index > -1) {
      newStyles[index] = style;
    }
  } else if (action === "delete") {
    newStyles = newStyles.filter((s: StyleInterface) => s.id !== style.id);
  }

  return { ...prev, customStyles: newStyles };
};

const useManageStyle = () => {
  const [{ token }] = useCookies(["token"]);
  const { setLoggedInUser } = useLoggedInUserContext();
  const manageStyle = async ({
    style,
    action,
  }: ManageStyleParams): Promise<Returns> => {
    if (!token) {
      console.error("Missing token");
      return { status: 500 };
    }

    const functionName = "manageStyle";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const body = JSON.stringify({ token, style, action });

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body,
        headers,
      });

      if (response.status === 200) {
        const style = await response.json();

        if (style) {
          setLoggedInUser((prev) =>
            handleUpdatingLoggedInUserStyles(prev, style, action)
          );
          return { status: 200, style };
        }
      }
      return { status: 500 };
    } catch (error) {
      console.error(error);
      return { status: 500 };
    }
  };

  return manageStyle;
};

export default useManageStyle;
