import { getBaseUrlForFetching } from "../../App";
import { SharedImage } from "../../types";
import { headers } from "../misc/helpers";

const useGetSharedImagesWithIds = () => {
  const getSharedImagesWithIds = async (
    ids: string[]
  ): Promise<SharedImage[]> => {
    const functionName = "getSharedImagesWithIds";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ ids }),
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const data = await response.json();

    return data.body;
  };
  return getSharedImagesWithIds;
};

export default useGetSharedImagesWithIds;
