import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../misc/helpers";
import { FolderInterface, FakeImage } from "../../types";

interface Returns {
  status: number;
}

const useSaveImageToFolder = () => {
  const [{ token }] = useCookies(["token"]);

  const saveImageToFolder = async (
    folder: FolderInterface,
    image: FakeImage
  ): Promise<Returns> => {
    if (!token) {
      return { status: 401 };
    }

    const functionName = "saveImageToFolder";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        token,
        folder,
        image,
      }),
      headers,
    });

    const { status } = response;

    if (status !== 200) {
      console.log(`Error saving image to folder: ${folder.id}`);
      return { status };
    }

    return { status };
  };
  return saveImageToFolder;
};

export default useSaveImageToFolder;
