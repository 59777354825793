export const getBadWord = (prompt: string) => {
  const badWords = [
    "adorable",
    "kim possible",
    "Katara",
    "petit",
    "youthful face",
    "youthful",
    "shotacon",
    "minor",
    "daughter",
    "young",
    "youth",
    "teen",
    "teens",
    "1teen",
    "teenager",
    "teenage",
    "teenagers",
    "young",
    "loli",
    "lolly",
    "lolicon",
    "lolita",
    "child",
    "childhood",
    "babyish-looking",
    "baby",
    "kid",
    "kids",
    "babygirl",
    "schoolgirl",
    "last of us",
  ];
  const words = prompt.split(/[\s.,()]+/);
  const badWord = words.find((word) => badWords.includes(word.toLowerCase()));
  return badWord;
};
