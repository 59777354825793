import styled from "styled-components";
import LabelWithTooltip from "../../../components/common/LabelWithTooltip";
import StyledSlider from "../../Inpainting/StyledSlider";
import { desktopMediaQuery } from "../../../styleHelpers";

interface P {
  denoisingStrength: number;
  setDenoisingStrength: (denoisingStrength: number) => void;

  isCreatingVariations?: boolean;
}

const DenoisingStrengthSlider = ({
  denoisingStrength,
  setDenoisingStrength,
  isCreatingVariations,
}: P) => {
  const calculateStringToRender = (denoisingStrength: number) => {
    let percentage;
    if (isCreatingVariations) {
      percentage = ((denoisingStrength - 0.6) / (1 - 0.6) * 90 + 10);
    } else {
      percentage = ((denoisingStrength - 0.2) / (1 - 0.2) * 90 + 10);
      if (percentage === 54.99999999999999) {
        percentage = 50;
      }
    }
    percentage = Math.floor(percentage / 10) * 10;
    return percentage.toFixed(0) + "%";
  }
  const strToRender = calculateStringToRender(denoisingStrength);

  const minToChoose = isCreatingVariations ? "0.6" : "0.2";

  const step = isCreatingVariations ? "0.05" : "0.1";
  return (
    <ImageStrengthContainer>
      <LabelContainer>
        <LabelWithTooltip
          labelText="AI Creativity Strength"
          tooltipText="With high creativity strength the image will change a lot. In principle, use strength to choose how much of the original image to keep. Low keeps most of it, while high follows mostly the prompt"
        />
      </LabelContainer>

      <SliderContainer>
        Low
        <StyledSlider
          type="range"
          min={minToChoose}
          max="1"
          step={step}
          value={denoisingStrength}
          onChange={(e) => setDenoisingStrength(Number(e.target.value))}
        />
      High
      </SliderContainer>
      {strToRender}
    </ImageStrengthContainer>
  );
};

const ImageStrengthContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align center
  align-items: center;

  margin-top: 24px;
  gap: 8px;

  font-size: 14px;

  width: 100%;
  ${desktopMediaQuery} {
    width: 400px;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-left: 12px;
`;

export default DenoisingStrengthSlider;
