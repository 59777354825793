import styled from "styled-components";
import Tooltip from "./Tooltip";
import { PRIMARY_TEXT_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

interface P {
  labelText: string;
  tooltipText: string;
}

const LabelWithTooltip = ({ labelText, tooltipText }: P) => {
  return (
    <Container>
      <LabelContainer>{labelText}</LabelContainer>
      <TooltipContainer>
        <Tooltip text={tooltipText} />
      </TooltipContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-right: 4px;
`;

const LabelContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  ${desktopMediaQuery} {
    font-size: 16px;
  }
  color: ${PRIMARY_TEXT_COLOR};
`;

const TooltipContainer = styled.div`
  margin-left: 4px;
  margin-top: 2px;
`;

export default LabelWithTooltip;
