import styled from 'styled-components';
import { StyleInterface } from '../../../types';
import { StyleCard, StyleName } from './StyleCardParts';
import { getBgImageToShow } from '../../../utils/imageStylesHelpers';

interface P {
  selectedStyle: StyleInterface;
  previewStyles: StyleInterface[];
  handleChooseStyle: (style: StyleInterface) => void;
}

const PreviewStyles: React.FC<P> = ({
  selectedStyle,
  previewStyles: previewStyles,
  handleChooseStyle,
}) => {
  const isSelected = (style: StyleInterface) => selectedStyle.id === style.id;
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    style: StyleInterface,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    handleChooseStyle(style);
  };

  return (
    <Container>
      {previewStyles.map((style) => (
        <StyleCard
          key={style.id}
          style={{
            backgroundImage: getBgImageToShow(style),
          }}
          onClick={(event) => handleClick(event, style)}
          isSelected={isSelected(style)}
        >
          <StyleName>{style.name}</StyleName>
        </StyleCard>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 3;
  width: 100%;
`;

export default PreviewStyles;
