import styled from 'styled-components';

const LoadingToShowWhileRedirecting = () => <Container>❤️</Container>;

export default LoadingToShowWhileRedirecting;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 80px;
`;
