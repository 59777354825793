import styled from 'styled-components';
import TransitioningModal from '../../../../components/Modals/TransitioningModal';
import { BACKGROUND_COLOR } from '../../../../constants';
import { ManageStyleActions, StyleInterface } from '../../../../types';
import { useEffect, useState } from 'react';
import { getDefaultStylesForAllModal } from '../../../../utils/imageStylesHelpers';
import StyleCardInModal from './StyleCardInModal';
import { StyledButton } from '../../../../components/common/StyledButton';
import { BiPlus } from 'react-icons/bi';
import { desktopMediaQuery, getIsOnDesktop } from '../../../../styleHelpers';
import { waitSeconds } from '../../../../components/helpers';
import ManageCustomStylesModal from './ManageCustomStylesModal';
import { CloseButton, StickiedTopPart } from './CommonInShowAllStylesModal';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import { toast } from 'react-toastify';
import { useImageGenerationSettingsContext } from '../../../../context/ImageGenerationSettingsProvider';
import { getRecentlyUsedStylesFromLocalStorage } from '../../../../localStorage/stylesStorage';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
  handleChooseStyle: (style: StyleInterface) => void;
}

const ShowAllStylesModal = ({ show, setShow, handleChooseStyle }: I) => {
  const [action, setAction] = useState<ManageStyleActions>('create');
  const closeModal = () => setShow(false);

  const defaultStyles = getDefaultStylesForAllModal();

  const { loggedInUser } = useLoggedInUserContext();

  const [showCustomizeStyleModal, setShowCustomizeStyleModal] = useState(false);
  const { selectedStyle, didClickEditButton } =
    useImageGenerationSettingsContext();

  useEffect(() => {
    if (didClickEditButton && show) {
      if (!loggedInUser) {
        toast.error('You must be logged in to edit a style');
        return;
      }
      setStyleToEdit(selectedStyle);
      setAction('update');
      setShowCustomizeStyleModal(true);
    }
  }, [show]);

  const [recentlyUsed, setRecentlyUsed] = useState<StyleInterface[]>([]);
  const [restOfStyles, setRestOfStyles] = useState<StyleInterface[]>([]);

  useEffect(() => {
    let allStyles = [...defaultStyles];
    if (!loggedInUser) {
      setRestOfStyles(defaultStyles);
      return;
    }
    const cStyles = loggedInUser?.customStyles;

    if (cStyles) {
      allStyles = allStyles.concat(cStyles);
    }

    //is selectedStyle part of styles (check with id)
    const isSelectedStyleInStyles = allStyles.some(
      (style) => style.id === selectedStyle.id,
    );
    if (!isSelectedStyleInStyles) {
      const styleToPush = {
        ...selectedStyle,
        type: 'fromOtherUser',
      };
      allStyles.push(styleToPush);
    }

    const recentlyUsedStylesFromLocalStorage =
      getRecentlyUsedStylesFromLocalStorage();
    setRecentlyUsed(recentlyUsedStylesFromLocalStorage);

    const rest = allStyles.filter(
      (style: StyleInterface) =>
        !recentlyUsedStylesFromLocalStorage.find(
          (s: StyleInterface) => s.id === style.id,
        ),
    );

    setRestOfStyles(rest);
  }, [show, loggedInUser?.customStyles]);

  const openCreateCustomStyleModal = () => {
    if (!loggedInUser) {
      toast.error('You must be logged in to create a style');
      return;
    }
    setAction('create');
    setShowCustomizeStyleModal(true);
  };

  const [styleToEdit, setStyleToEdit] = useState<StyleInterface | undefined>();
  const handleClickEdit = (style: StyleInterface) => {
    if (!loggedInUser) {
      toast.error('You must be logged in to edit a style');
      return;
    }
    setStyleToEdit(style);
    setAction('update');
    setShowCustomizeStyleModal(true);
  };

  const fadeOutModalSlowly = () => {
    const modal = document.querySelector('.ReactModal__Content') as HTMLElement;
    if (!modal) return;
    modal.style.transition = 'opacity 0.4s';
    modal.style.opacity = '0';
  };

  const handleClickStyle = async (style: StyleInterface) => {
    handleChooseStyle(style);
    fadeOutModalSlowly();
    await waitSeconds(0.3);
    closeModal();
  };

  const handleAfterSaving = async () => {
    fadeOutModalSlowly();
    await waitSeconds(0.3);
    closeModal();
  };

  const isDesktop = getIsOnDesktop();

  const newTextToRender = isDesktop ? 'Create a style' : 'New';

  return (
    <>
      <TransitioningModal
        isOpen={show}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            backgroundColor: BACKGROUND_COLOR,
            padding: '16px',
            paddingTop: 0,
            width: '100%',
            height: '90%',
            marginTop: '-40px',

            maxWidth: '800px',
            minHeight: '300px',
            overflowY: 'scroll',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
      >
        <StickiedTopPart>
          <CreateCustomStyleButton onClick={openCreateCustomStyleModal}>
            <BiPlus />
            {newTextToRender}
          </CreateCustomStyleButton>
          <Caption>Choose a Style</Caption>
          <CloseButton onClick={closeModal} />
        </StickiedTopPart>
        {
          //if there are no recently used styles, don't show the section
          recentlyUsed.length > 0 && (
            <>
              <SubtitleText>Recently used</SubtitleText>
              <StyleCardsContainer>
                {recentlyUsed.map((style) => (
                  <StyleCardInModal
                    key={style.id}
                    selectedStyle={selectedStyle}
                    style={style}
                    handleChooseStyle={handleClickStyle}
                    handleChooseEdit={handleClickEdit}
                  />
                ))}
                <EmptyDiv />
              </StyleCardsContainer>
            </>
          )
        }

        <SubtitleText>All styles</SubtitleText>
        <StyleCardsContainer>
          {restOfStyles.map((style) => (
            <StyleCardInModal
              key={style.id}
              selectedStyle={selectedStyle}
              style={style}
              handleChooseStyle={handleClickStyle}
              handleChooseEdit={handleClickEdit}
            />
          ))}
          <EmptyDiv />
        </StyleCardsContainer>
      </TransitioningModal>

      <ManageCustomStylesModal
        show={showCustomizeStyleModal}
        setShow={setShowCustomizeStyleModal}
        action={action}
        styleToEdit={styleToEdit}
        handleAfterSaving={handleAfterSaving}
      />
    </>
  );
};

const CreateCustomStyleButton = styled(StyledButton)`
  font-size: 14px;
  padding: 4px;
  padding-right: 8px;

  display: flex;
  align-items: center;
  gap: 2px;
`;

const Caption = styled.div`
  display: flex;
  margin-left: -20px;
`;

const StyleCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${desktopMediaQuery} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  gap: 1px;

  width: 97%;
`;

const SubtitleText = styled.div`
  font-size: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const EmptyDiv = styled.div`
  height: 50px;
`;

export default ShowAllStylesModal;
