import { SharedImage, UserDetails } from '../../types';
import { headers } from '../misc/helpers';
import { getBaseUrlForFetching } from '../../App';
import { useCacheContext } from '../../context/CacheContext';

const useGetUserDetailsWithSharedImage = () => {
  const {
    userDetailsCache,
    setUserDetailsCache,
    userDetailsCacheViaUsername,
    setUserDetailsCacheViaUsername,
  } = useCacheContext();

  const fetchData = async (image: SharedImage): Promise<UserDetails | null> => {
    if (!image) {
      return null;
    }

    const userIdFromImage = image?.userId;

    // This is the old method, only old images have username
    const usernameFromImage = image?.user?.username;

    if (userIdFromImage) {
      const cachedUserDetails = userDetailsCache[userIdFromImage];
      if (cachedUserDetails) {
        return cachedUserDetails;
      }
    } else if (usernameFromImage) {
      const cachedUserDetails = userDetailsCacheViaUsername[usernameFromImage];
      if (cachedUserDetails) {
        return cachedUserDetails;
      }
    }

    const functionName = 'getUserDetailsWithSharedImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;
    try {
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          image,
        }),
      });

      if (!response.ok) {
        console.error('Error fetching user details:', response);
        return null;
      }

      const data = await response.json();

      if (userIdFromImage) {
        setUserDetailsCache((prev) => ({
          ...prev,
          [userIdFromImage]: data,
        }));
      } else if (usernameFromImage) {
        setUserDetailsCacheViaUsername((prev) => ({
          ...prev,
          [usernameFromImage]: data,
        }));
      }

      return data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };
  return fetchData;
};

export default useGetUserDetailsWithSharedImage;
