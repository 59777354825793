import styled from "styled-components";
import StyledSelectOriginal from "../../../components/common/StyledSelect";
import { desktopMediaQuery } from "../../../styleHelpers";

export const ContainerForSelect = styled.div<{ wider?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-right: 16px;
  margin-top: 9px;

  width: 90%;
  // 70px or 140px when wider is true
  max-width: ${({ wider }) => (wider ? "140px" : "70px")};

  ${desktopMediaQuery} {
    max-width: ${({ wider }) => (wider ? "200px" : "80px")};
  }
`;

export const StyledSelectForBelowImage = styled(StyledSelectOriginal)`
  width: 100%;
  height: 38px;
  font-size: 12px;

  text-align-last: center; /* This will center the text in the select itself */

  transition: border-color 0.2s;

  option {
    /* This will attempt to center the text in the dropdown */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
