import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from "../../../constants";
import { desktopMediaQuery } from "../../../styleHelpers";

export const StyleCard = styled.div<{
  isSelected?: boolean;
  isLessWide?: boolean;
  isInModal?: boolean; // Style Se
}>`
  //relative
  position: relative;
  height: ${({ isInModal }) => (isInModal ? "150px" : "200px")};

  width: 100%;

  border: 2px solid black;
  border: ${({ isSelected }) => isSelected && `2px solid ${PRIMARY_COLOR}`};
  z-index: ${({ isSelected }) => isSelected && "3"};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  transition: 0.3s;
  ${desktopMediaQuery} {
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
    }
  }
`;

export const StyleName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: 600;
  padding: 2px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;

  width: 100%;
`;
