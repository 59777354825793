import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../misc/helpers";

const useDeleteFolder = () => {
  const [{ token }] = useCookies(["token"]);

  const deleteFolder = async (folderId: string) => {
    if (!token || !folderId) {
      console.error("Missing token or folderId");
      return;
    }

    const functionName = "deleteFolder";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body: JSON.stringify({ folderId, token }),
        headers,
      });

      if (response.status !== 200) {
        throw new Error(`Error deleting folder: ${folderId}`);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
    }
  };

  return deleteFolder;
};

export default useDeleteFolder;
