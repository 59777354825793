import styled from "styled-components";
import { GenericSetState, EngineType } from "../../../types";
import LabelWithTooltip from "../../../components/common/LabelWithTooltip";
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../constants";
import { useState } from "react";
import EngineModal from "./EngineModal";
import { BsArrowRight } from "react-icons/bs";
import { getEngineNameFromModelId } from "../../../utils/engineHelpers";
import { desktopMediaQuery } from "../../../styleHelpers";

interface P {
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
}

const TOOLTIP_TEXT =
  "The engine (model) is what the AI uses to generate the image. Different engines have different strengths and weaknesses";

const EngineSelector = ({ engine, setEngine }: P) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Container>
      <LabelWithTooltip labelText="Engine" tooltipText={TOOLTIP_TEXT} />
      <ChooseEngineButton onClick={() => setShowModal(true)} engine={engine} />
      <EngineModal
        showModal={showModal}
        setShowModal={setShowModal}
        engine={engine}
        setEngine={setEngine}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 22px;
  align-items: center;
  align-self: flex-start;

  margin-left: 16px;
`;

interface Button {
  onClick: () => void;
  engine: EngineType;
}
const ChooseEngineButton = ({ onClick, engine }: Button) => {
  const engineName = getEngineNameFromModelId(engine.modelId);

  return (
    <StyledButton onClick={onClick}>
      <EngineNameText>{engineName}</EngineNameText>
      <ArrowLeft />
    </StyledButton>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 50vw;
  max-width: 315px;
  height: 26px;

  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: ${PRIMARY_TEXT_COLOR};
  border-radius: 4px;
  border: 1px solid ${BORDER_COLOR};
  outline: none;
  padding: 8px;
  margin-top: -9px;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

const EngineNameText = styled.div`
  margin-top: 2px;
  margin-left: 4px;
  align-self: flex-start;
`;

const ArrowLeft = styled(BsArrowRight)`
  text-align: right;
  margin-bottom: 1px;
  font-size: 16px;
`;

export default EngineSelector;
