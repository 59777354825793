import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { useImagesContext } from "../../context/ImagesContextProvider";
import { FakeImage } from "../../types";
import { headers } from "../misc/helpers";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";

interface Returns {
  status: number;
}
const useSaveImage = () => {
  const [{ token }] = useCookies(["token"]);

  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { savedImages, setSavedImages } = useImagesContext();

  const addTrackIdToLoggedInUser = (trackId: string) => {
    if (!loggedInUser) {
      return;
    }

    const previousSavedImageTrackIds = loggedInUser.savedImageTrackIds ?? [];

    if (previousSavedImageTrackIds.includes(trackId)) {
      return;
    }

    const newSavedImageTrackIds = [...previousSavedImageTrackIds, trackId];
    const newLoggedInUser = {
      ...loggedInUser,
      savedImageTrackIds: newSavedImageTrackIds,
    };
    setLoggedInUser(newLoggedInUser);
  };

  const saveImage = async (
    image: FakeImage,
    sharedImageRefId?: string // if the saved image is a shared image, this is the id of the shared image
  ): Promise<Returns> => {
    if (!token) {
      return { status: 401 };
    }

    const newSavedImages = [image, ...savedImages];
    setSavedImages(newSavedImages);

    addTrackIdToLoggedInUser(image.trackId);

    const functionName = "saveImage";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const { status } = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        image,
        token,
        sharedImageRefId,
      }),
      headers,
    });

    return { status };
  };
  return saveImage;
};

export default useSaveImage;
