import React from "react";
import { FolderInterface } from "../../../types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FOLDERS_PATH } from "../../../pathNames";

import { MdEdit } from "react-icons/md";
import EditFolderModal from "./EditFolderModal";

interface FolderCardProps {
  folder: FolderInterface;
}

const FolderCard: React.FC<FolderCardProps> = ({ folder }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const handleShowFolderContents = () => {
    navigate(`${FOLDERS_PATH}/${folder.id}`);
  };

  const handleClickEditButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowModal(true);
  };

  return (
    <Container onClick={handleShowFolderContents}>
      <FolderImage src={folder.previewImageUrl} />
      <EditButton onClick={handleClickEditButton} />
      <FolderName>{folder.name}</FolderName>

      <EditFolderModal
        showModal={showModal}
        setShowModal={setShowModal}
        folderId={folder.id}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;

  max-width: 240px;
`;

const FolderImage = styled.img`
  width: 100%;
  display: block;
`;

const FolderName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2); // Semi-transparent black background
  color: white; // White text for visibility
  text-align: center;
  font-size: 16px;
  font-weight: 600;

  padding-top: 8px;
  padding-bottom: 12px;
`;

const EditButton = styled(MdEdit)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2); // Semi-transparent black background
  border-radius: 50%;
  padding: 8px;
  margin: 4px;
  cursor: pointer;
`;

export default FolderCard;
