import { useGeneralContext } from "../../context/GeneralContextProvider";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";

const useHandleDeductCredits = () => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { firstTimeUserToken } = useGeneralContext();

  const handleDeductCredits = (amount: number) => {
    if (amount === 0) {
      return "ok";
    }
    // if gold user, return ok without deducting credits
    if (loggedInUser?.type === "gold") {
      return "ok";
    }

    if (firstTimeUserToken !== "") {
      return "ok";
    }

    if (!loggedInUser?.credits || loggedInUser.credits < amount) {
      // Return an error message or throw an error
      return "Insufficient credits";
    }

    const updatedUser = {
      ...loggedInUser,
      credits: loggedInUser.credits - amount,
    };

    // Update loggedInUser in context
    setLoggedInUser(updatedUser);
    return "ok";
  };

  return handleDeductCredits;
};

export default useHandleDeductCredits;
