import { LoggedInUser } from "../../../types";

/** if Gold, then infinite, if not logged in then 0
 * otherwise the amount of credits
 */
export const getAmountOfCredits = (loggedInUser: LoggedInUser | null) => {
  if (loggedInUser?.type === "gold") {
    return "∞";
  }

  if (!loggedInUser || !loggedInUser.credits) {
    return 0;
  }

  return loggedInUser.credits;
};

/**
 * User can claim free credits once a day
 *
 * Note: if the logic of this is changed, also change the function in server: isClaimAvailable
 */
export const getTimeUntilNextFreeCredits = (loggedInUser: LoggedInUser) => {
  const lastClaimedFreeCredits = loggedInUser.lastClaimedFreeCredits;
  if (!lastClaimedFreeCredits) return "00:00:00";

  const lastClaimedFreeCreditsDate = new Date(lastClaimedFreeCredits);
  const now = new Date();
  const oneDayInMs = 24 * 60 * 60 * 1000;
  const nextClaimTime = new Date(
    lastClaimedFreeCreditsDate.getTime() + oneDayInMs
  );

  if (now >= nextClaimTime) {
    return "00:00:00";
  }

  const remainingTimeMs = nextClaimTime.getTime() - now.getTime();
  const hours = Math.floor(remainingTimeMs / (60 * 60 * 1000));
  const minutes = Math.floor(
    (remainingTimeMs % (60 * 60 * 1000)) / (60 * 1000)
  );
  const seconds = Math.floor((remainingTimeMs % (60 * 1000)) / 1000);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

/**
 * If email is verified, but lastClaimedFreeCredits is null, then it means the user has never claimed free credits; give them 30
 *
 * Note: if this is changed, also change the function in server (creditService.ts) and ConfirmEmailModal and ClaimFreeCreditsOption
 */
export const getAmountToClaim = (loggedInUser: LoggedInUser) => {
  if (loggedInUser.verifiedEmail && !loggedInUser.lastClaimedFreeCredits) {
    return 30;
  }
  return 10;
};
