import React from 'react';
import { StyleCard, StyleName } from '../StyleCardParts';
import { StyleInterface } from '../../../../types';
import {
  MdEdit,
  MdOutlineDashboardCustomize,
  MdRemoveCircleOutline,
} from 'react-icons/md';
import styled from 'styled-components';
import { getBgImageToShow } from '../../../../utils/imageStylesHelpers';

interface Props {
  selectedStyle: StyleInterface;
  style: StyleInterface;
  handleChooseStyle: (style: StyleInterface) => void;
  handleChooseEdit: (style: StyleInterface) => void;
}

const StyleCardInModal: React.FC<Props> = ({
  selectedStyle,
  style,
  handleChooseStyle,
  handleChooseEdit,
}) => {
  const backgroundImage = getBgImageToShow(style);
  const isSelected = selectedStyle.id === style.id;
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    style: StyleInterface,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    handleChooseStyle(style);
  };

  const handleClickEdit = (
    event: React.MouseEvent<SVGElement>,
    style: StyleInterface,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    handleChooseEdit(style);
  };

  const isNoneStyle = style.id === '0';
  const isCustomStyle = style.type === 'custom';
  return (
    <StyleCard
      style={{ backgroundImage, backgroundPosition: 'top' }}
      onClick={(event) => handleClick(event, style)}
      isSelected={isSelected}
      isInModal
    >
      {!isNoneStyle && (
        <EditButton onClick={(event) => handleClickEdit(event, style)} />
      )}
      <StyleName>{style.name}</StyleName>
      {isNoneStyle && (
        <NoneContainer>
          <MdRemoveCircleOutline size={74} />
        </NoneContainer>
      )}

      {isCustomStyle && (
        <NoneContainer>
          <MdOutlineDashboardCustomize size={64} />
        </NoneContainer>
      )}
    </StyleCard>
  );
};

const EditButton = styled(MdEdit)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2); // Semi-transparent black background
  border-radius: 50%;
  padding: 8px;
  margin: 4px;
  cursor: pointer;
`;

// this should be in the middle of the parent container
const NoneContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  opacity: 0.3;
`;

export default StyleCardInModal;
