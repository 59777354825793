import { createContext, useContext, ReactNode, useState } from 'react';
import {
  UserDetailsCache,
  GenericSetState,
  PublicUserProfileCache,
  PostedImagesCache,
} from '../types';

interface ICacheContext {
  userDetailsCache: UserDetailsCache;
  setUserDetailsCache: GenericSetState<UserDetailsCache>;
  userDetailsCacheViaUsername: UserDetailsCache; // Keyed by username (old images). Used to cache shared images for a user in ProfileDetail
  setUserDetailsCacheViaUsername: GenericSetState<UserDetailsCache>;

  publicUserProfileCache: PublicUserProfileCache; // Keyed by userId. Used to cache shared images for a user in ProfileDetail
  setPublicUserProfileCache: GenericSetState<PublicUserProfileCache>;

  postedImagesOnFeedCache: PostedImagesCache; // Keyed by channel-sort. Ex. 'all-hot' or 'blondes-top'. Used to cache images in FeedPage
  setPostedImagesOnFeedCache: GenericSetState<PostedImagesCache>;
}

const defaultState = {
  userDetailsCache: {},
  setUserDetailsCache:
    undefined as unknown as GenericSetState<UserDetailsCache>,
  userDetailsCacheViaUsername: {},
  setUserDetailsCacheViaUsername:
    undefined as unknown as GenericSetState<UserDetailsCache>,

  publicUserProfileCache: {},
  setPublicUserProfileCache:
    undefined as unknown as GenericSetState<PublicUserProfileCache>,

  postedImagesOnFeedCache: {},
  setPostedImagesOnFeedCache:
    undefined as unknown as GenericSetState<PostedImagesCache>,
};

const CacheContext = createContext<ICacheContext>(defaultState);

export const useCacheContext = () => useContext<ICacheContext>(CacheContext);

const CacheContextProvider = ({ children }: { children: ReactNode }) => {
  const [userDetailsCache, setUserDetailsCache] = useState<UserDetailsCache>(
    {},
  );
  const [userDetailsCacheViaUsername, setUserDetailsCacheViaUsername] =
    useState<UserDetailsCache>({});

  const [publicUserProfileCache, setPublicUserProfileCache] =
    useState<PublicUserProfileCache>({});

  const [postedImagesOnFeedCache, setPostedImagesOnFeedCache] =
    useState<PostedImagesCache>({});

  return (
    <CacheContext.Provider
      value={{
        userDetailsCache,
        setUserDetailsCache,
        userDetailsCacheViaUsername,
        setUserDetailsCacheViaUsername,

        publicUserProfileCache,
        setPublicUserProfileCache,

        postedImagesOnFeedCache,
        setPostedImagesOnFeedCache,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

export default CacheContextProvider;
