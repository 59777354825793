import styled from "styled-components";
import { desktopMediaQuery } from "../../styleHelpers";

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5%;
  color: white;
  ${desktopMediaQuery} {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;
