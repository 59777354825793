import { useState, useEffect } from "react";

function useProgressTracking(
  generationStartedTimestamp: number,
  generationReadyTimestamp: number,
  imageGenerating?: boolean
) {
  const [progress, setProgress] = useState(5);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      if (imageGenerating) {
        const now = Date.now();
        const totalTimeEstimate =
          generationReadyTimestamp - generationStartedTimestamp;

        setTimeLeft(generationReadyTimestamp - now);
        const timePassed = now - generationStartedTimestamp;
        const progressPercentage = Math.floor(
          (timePassed / totalTimeEstimate) * 100
        );
        if (progressPercentage > 99) {
          setProgress(99);
          return;
        }
        setProgress(progressPercentage);
      }
    };

    const interval = setInterval(updateProgress, 250);
    return () => clearInterval(interval);
  }, [imageGenerating, generationStartedTimestamp, generationReadyTimestamp]);

  return { progress, timeLeft };
}

export default useProgressTracking;
