import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import { StyledInputSmall } from '../../common/StyledInputSmall';
import { useEffect, useRef, useState } from 'react';
import useFeedChannels from '../../../hooks/feed/useFeedChannels';
import ChannelsComboBox from './ChannelsComboBox';
import { FeedChannel } from '../../../types';
import { waitSeconds } from '../../helpers';
import { StyledButton } from '../../common/StyledButton';
import useShareImage from '../../../hooks/images/useShareImage';
import LoadingIndicator from '../../common/LoadingIndicator';
import AddMoreImagesModalButton from './AddMoreImagesModalButton/AddMoreImagesModalButton';
import { useImagesContext } from '../../../context/ImagesContextProvider';
import { FaImages } from 'react-icons/fa6';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const CreateNewPostModal = ({ showModal, setShowModal }: I) => {
  const { imageToBePosted, albumToBePosted, setAlbumToBePosted } = useImagesContext();
  const [title, setTitle] = useState('');
  const { fetchFeedChannels } = useFeedChannels();
  const [feedChannels, setFeedChannels] = useState<FeedChannel[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const channels = await fetchFeedChannels();

      channels && setFeedChannels(channels);
    };
    if (showModal) {
      setAlbumToBePosted([]);
      fetch();
    }
  }, [showModal]);

  const channelNames = feedChannels.map((channel) => channel.name);
  const [chosenChannelName, setChosenChannelName] = useState('');

  const handleClose = () => {
    setShowModal(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  const scrollIntoView = async () => {
    if (ref.current) {
      const scrollableParent = ref.current.closest('.ScrollableDivClassName');
      if (scrollableParent) {
        const elementTop = ref.current.offsetTop;
        await waitSeconds(0.1);
        const scrollAmount = elementTop + 240;
        scrollableParent.scrollTo({
          top: scrollAmount,
          behavior: 'smooth',
        });
      }
    }
  };

  const shareImage = useShareImage();
  const [loading, setLoading] = useState(false);
  const handlePost = async () => {
    setLoading(true);

    if (chosenChannelName === '') {
      await shareImage({ imageToBePosted, title });
    } else {
      const existingChannel = feedChannels.find(
        (channel) => channel.name === chosenChannelName,
      );

      // If the channel doesn't exist, create it
      if (!existingChannel) {
        if (chosenChannelName.length < 3) {
          alert('Channel name must be at least 3 characters long');
          setLoading(false);
          return;
        }
        await shareImage({
          imageToBePosted,
          title,
          newChannelName: chosenChannelName,
        });
      } else {
        await shareImage({ imageToBePosted, title, channel: existingChannel });
      }
    }

    setTitle('');
    setLoading(false);
  };

  const isCreatingAlbum = albumToBePosted && albumToBePosted.length > 0;

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '8px',
            width: '90vw',
            maxHeight: '90vh',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '40px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal onClick={handleClose} />
        <ScrollableDiv className="ScrollableDivClassName" ref={ref}>

          <ImageContainer>
            {isCreatingAlbum && <AlbumIcon />}
            <ImageToBePosted src={imageToBePosted.imageUrl} />
          </ImageContainer>

          <AddMoreImagesModalButton />

          <InputContainer>
            <StyledInputSmall
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Post title (optional)"
            />

            <ChannelsComboBox
              options={channelNames}
              value={chosenChannelName}
              setValue={setChosenChannelName}
              placeholder="Choose a channel (optional)"
              onFocusCallback={scrollIntoView}
            />
          </InputContainer>

          <StyledButton onClick={handlePost}>
            {loading ? <LoadingIndicator differentBgColor /> : 'Post Image'}
          </StyledButton>
        </ScrollableDiv>
      </Modal>
    </>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  overflow-y: auto;
  max-height: 80vh;
  padding-right: 8px;
  padding-left: 8px;
  margin-top: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const AlbumIcon = styled(FaImages)`
  position: absolute;
  bottom: 8px;
  right: 2px;
  font-size: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px;
`;

const ImageToBePosted = styled.img`
  width: 40vw;
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  margin-top: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 16px;

  width: 90%;
  max-width: 450px;

  gap: 16px;

  margin-top: 16px;
  margin-bottom: 16px;
`;

export default CreateNewPostModal;
