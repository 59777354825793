import {
  CategoryInMagicMode,
  SubcategoryInMagicMode,
  TabInCreateCharacter,
  TagInMagicMode,
} from '../../types';

const AGE_TAGS = [
  {
    id: '18',
    label: '18 yo',
    prompt: '18 years old',
    subcategoryId: 'age',
  },
  {
    id: '20s',
    label: '20s',
    prompt: '22 years old',
    subcategoryId: 'age',
  },
  {
    id: '30s',
    label: '30s',
    prompt: '32 years old',
    subcategoryId: 'age',
  },
  {
    id: '40s',
    label: '40s',
    prompt: '42 years old',
    subcategoryId: 'age',
  },
  {
    id: '50s',
    label: '50s',
    prompt: '52 years old',
    subcategoryId: 'age',
  },
  /*
  {
    id: '60s',
    label: '60s',
    prompt: '62 years old',
    subcategoryId: 'age',
  },
  {
    id: '70s',
    label: '70s',
    prompt: '72 years old',
    subcategoryId: 'age',
  },
  */
];

const ORIGIN_TAGS: TagInMagicMode[] = [
  {
    id: 'sweden',
    label: '🇸🇪 Sweden',
    prompt: 'Sweden',
    subcategoryId: 'origin',
  },
  {
    id: 'united_states',
    label: '🇺🇸 United States',
    prompt: 'United States',
    subcategoryId: 'origin',
  },
  {
    id: 'india',
    label: '🇮🇳 India',
    prompt: 'India',
    subcategoryId: 'origin',
  },
  {
    id: 'germany',
    label: '🇩🇪 Germany',
    prompt: 'Germany',
    subcategoryId: 'origin',
  },
  {
    id: 'nigeria',
    label: '🇳🇬 Nigeria',
    prompt: 'Nigeria',
    subcategoryId: 'origin',
  },
  {
    id: 'saudi arabia',
    label: '🇸🇦 Saudi Arabia',
    prompt: 'Saudi Arabia',
    subcategoryId: 'origin',
  },
  {
    id: 'united_kingdom',
    label: '🇬🇧 United Kingdom',
    prompt: 'United Kingdom',
    subcategoryId: 'origin',
  },
  {
    id: 'france',
    label: '🇫🇷 France',
    prompt: 'France',
    subcategoryId: 'origin',
  },
  {
    id: 'brazil',
    label: '🇧🇷 Brazil',
    prompt: 'Brazil',
    subcategoryId: 'origin',
  },
  {
    id: 'russia',
    label: '🇷🇺 Russia',
    prompt: 'Russia',
    subcategoryId: 'origin',
  },
  {
    id: 'spain',
    label: '🇪🇸 Spain',
    prompt: 'Spain',
    subcategoryId: 'origin',
  },
  {
    id: 'italy',
    label: '🇮🇹 Italy',
    prompt: 'Italy',
    subcategoryId: 'origin',
  },
  {
    id: 'netherlands',
    label: '🇳🇱 Netherlands',
    prompt: 'Netherlands',
    subcategoryId: 'origin',
  },
  {
    id: 'mexico',
    label: '🇲🇽 Mexico',
    prompt: 'Mexico',
    subcategoryId: 'origin',
  },
  {
    id: 'malaysia',
    label: '🇲🇾 Malaysia',
    prompt: 'Malaysia',
    subcategoryId: 'origin',
  },
  {
    id: 'japan',
    label: '🇯🇵 Japan',
    prompt: 'Japan',
    subcategoryId: 'origin',
  },
];

// TODO: Add style separately...
const BASE_TAB_SUBCATEGORIES: SubcategoryInMagicMode[] = [
  { label: 'Age', id: 'age', tags: AGE_TAGS },
  { label: 'Origin', id: 'origin', tags: ORIGIN_TAGS },
];

const HAIR_COLOR_TAGS: TagInMagicMode[] = [
  {
    id: 'blonde',
    label: '🟡 Blonde',
    prompt: 'Blonde hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'brunette',
    label: '🟤 Brunette',
    prompt: 'Brunette hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'redhead',
    label: '🔴 Redhead',
    prompt: 'Redhead hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'black',
    label: '⚫ Black',
    prompt: 'Black hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'rainbow',
    label: '🌈 Rainbow',
    prompt: 'Rainbow-colored hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'electric-blue',
    label: '🔵 Electric Blue',
    prompt: 'Electric blue hair',
    subcategoryId: 'hair-color',
  },
  /*
  {
    id: 'ginger',
    label: 'Ginger',
    prompt: 'Ginger hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'neon',
    label: 'Neon',
    prompt: 'Neon-colored hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'balayage',
    label: 'Balayage',
    prompt: 'Balayage hair',
    subcategoryId: 'hair-color',
  },
  {
    id: 'silver',
    label: 'Silver',
    prompt: 'Silver hair',
    subcategoryId: 'hair-color',
  },
  */
];

const HAIR_STYLE_TAGS: TagInMagicMode[] = [
  {
    id: 'long',
    label: 'Long',
    prompt: 'Long hair',
    subcategoryId: 'hair-style',
  },
  /*
  {
    id: 'medium hair',
    label: 'Medium',
    prompt: 'Medium hair',
    subcategoryId: 'hair-style',
  },
  */
  {
    id: 'short',
    label: 'Short',
    prompt: 'Short hair',
    subcategoryId: 'hair-style',
  },
  {
    id: 'ponytail',
    label: 'Ponytail hair',
    prompt: 'Ponytail',
    subcategoryId: 'hair-style',
  },
  {
    id: 'hijab',
    label: 'Hijab',
    prompt: '(Hijab) headwear',
    subcategoryId: 'hair-style',
  },
  /*
  {
    id: 'curly',
    label: 'Curly',
    prompt: 'Curly hair',
    subcategoryId: 'hair-style',
  },
  */
  {
    id: 'straight',
    label: 'Straight',
    prompt: 'Straight hair',
    subcategoryId: 'hair-style',
  },
  {
    id: 'wavy',
    label: 'Wavy',
    prompt: 'Wavy hair',
    subcategoryId: 'hair-style',
  },
  /*
  {
    id: 'messy',
    label: 'Messy',
    prompt: 'Messy hair',
    subcategoryId: 'hair-style',
  },
  */
  {
    id: 'messy-bun',
    label: 'Messy Bun',
    prompt: 'Messy bun hair',
    subcategoryId: 'hair-style',
  },
  {
    id: 'braided',
    label: 'Braided',
    prompt: 'Braided hair',
    subcategoryId: 'hair-style',
  },
];

const FACE_TAGS: TagInMagicMode[] = [
  {
    id: 'supermodel',
    label: 'Supermodel',
    prompt: 'supermodel face',
    subcategoryId: 'face',
  },
  {
    id: 'no-makeup',
    label: 'No makeup',
    prompt: 'no makeup face, natural',
    subcategoryId: 'face',
  },
  {
    id: 'cute',
    label: 'Cute',
    prompt: 'cute face',
    subcategoryId: 'face',
  },
  {
    id: 'sexy',
    label: 'Sexy',
    prompt: 'sexy face',
    subcategoryId: 'face',
  },
  {
    id: 'next-door',
    label: 'Girl next door',
    prompt: 'Pretty girl next door',
    subcategoryId: 'face',
  },
  {
    id: 'classical beauty',
    label: 'Classical beauty',
    prompt: 'Classically beautiful face',
    subcategoryId: 'face',
  },
  {
    id: 'goth',
    label: 'Goth',
    prompt: '(Goth) face',
    subcategoryId: 'face',
  },
  {
    id: 'emo',
    label: 'Emo',
    prompt: '(Emo) face',
    subcategoryId: 'face',
  },
  {
    id: 'punk',
    label: 'Punk',
    prompt: 'Punk-rock face',
    subcategoryId: 'face',
  },
  {
    id: 'hipster',
    label: 'Hipster',
    prompt: 'Hipster face',
    subcategoryId: 'face',
  },
  {
    id: 'nerdy',
    label: 'Nerdy',
    prompt: '(Nerdy) face, glasses',
    subcategoryId: 'face',
  },
];

const EYE_COLOR_TAGS: TagInMagicMode[] = [
  {
    id: 'blue',
    label: '🔵 Blue',
    prompt: 'Blue eyes',
    subcategoryId: 'eye-color',
  },
  {
    id: 'green',
    label: '🟩 Green',
    prompt: 'Green eyes',
    subcategoryId: 'eye-color',
  },
  {
    id: 'brown',
    label: '🟫 Brown',
    prompt: 'Brown eyes',
    subcategoryId: 'eye-color',
  },
];

/*
const EXPRESSIONS = [
  {
    id: 'seductive',
    label: 'Smiling',
    prompt: 'seductive face',
  },
  {
    id: 'smiling',
    label: 'Smiling',
    prompt: 'Smiling',
  },
  {
    id: 'serious',
    label: 'Serious',
    prompt: 'Serious',
  },
  {
    id: 'seductive',
    label: 'Seductive',
    prompt: 'Seductive expression',
  },
];
*/

const HEAD_TAB_SUBCATEGORIES: SubcategoryInMagicMode[] = [
  { label: 'Hair color', id: 'hair-color', tags: HAIR_COLOR_TAGS },
  {
    label: 'Hair style',
    id: 'hair-style',
    tags: HAIR_STYLE_TAGS,
    multiSelect: true,
  },
  { label: 'Face', id: 'face', tags: FACE_TAGS },
  {
    label: 'Eye color',
    id: 'eye-color',
    tags: EYE_COLOR_TAGS,
  },
  /*
  { label: 'Expression', id: 'expression', tags: EXPRESSIONS },
  { label: 'Head accessories', id: 'head-accessories', tags: [] },
  { label: 'Eyes', id: 'eyes', tags: [] },
  { label: 'Nose', id: 'nose', tags: [] },
  { label: 'Mouth', id: 'mouth', tags: [] },
  */
];

const BODY_TYPE_TAGS: TagInMagicMode[] = [
  {
    id: 'petite',
    label: 'Petite',
    prompt: 'Petite body',
    subcategoryId: 'body-type',
  },
  {
    id: 'curvy',
    label: 'Curvy',
    prompt: 'Curvy body',
    subcategoryId: 'body-type',
  },
  {
    id: 'athletic',
    label: 'Athletic',
    prompt: 'Athletic body',
    subcategoryId: 'body-type',
  },
  {
    id: 'chubby',
    label: 'Chubby',
    prompt: '(Chubby) body',
    subcategoryId: 'body-type',
  },
];

const BOOB_TAGS: TagInMagicMode[] = [
  /*
  {
    id: 'flat chest',
    label: 'Flat',
    prompt: '(Flat chest)',
    negativePrompt: '(Big breasts:1.1)',
    subcategoryId: 'boobs',
  },
  */
  {
    id: 'small boobs',
    label: 'Small',
    prompt: 'Small boobs',
    negativePrompt: '(Big breasts)',
    subcategoryId: 'boobs',
  },
  {
    id: 'medium boobs',
    label: 'Medium',
    prompt: 'Medium boobs',
    negativePrompt: 'fake breasts, big breasts',
    subcategoryId: 'boobs',
  },
  {
    id: 'big boobs',
    label: 'Big',
    prompt: 'Big boobs',
    subcategoryId: 'boobs',
  },
  {
    id: 'saggy boobs',
    label: 'Saggy',
    prompt: '(Saggy), natural boobs',
    subcategoryId: 'boobs',
  },
  {
    id: 'fake boobs',
    label: 'Fake',
    prompt: 'Silicon boobs',
    subcategoryId: 'boobs',
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BUTT_TAGS: TagInMagicMode[] = [
  {
    id: 'small ass',
    label: 'Small',
    prompt: 'small ass',
    subcategoryId: 'butt',
  },
  {
    id: 'medium ass',
    label: 'Medium-sized',
    prompt: 'medium-sized ass',
    subcategoryId: 'butt',
  },
  {
    id: 'fit ass',
    label: 'Fit/athletic',
    prompt: 'Athletic ass',
    subcategoryId: 'butt',
  },
  {
    id: 'big ass',
    label: 'Big',
    prompt: 'Big ass',
    subcategoryId: 'butt',
  },
];

const CLOTHING_TAGS: TagInMagicMode[] = [
  {
    id: 'nude',
    label: 'Nude',
    prompt: '(nude:1.1)',
    negativePrompt: 'clothes, underwear',
    subcategoryId: 'clothing',
  },
  {
    id: 'bathrobe',
    label: 'Bathrobe',
    prompt: 'Bathrobe',
    subcategoryId: 'clothing',
  },
  {
    id: 'bdsm',
    label: 'BDSM',
    prompt: 'BDSM outfit',
    subcategoryId: 'clothing',
  },
  {
    id: 'bikini',
    label: 'Bikini',
    prompt: 'Bikini',
    subcategoryId: 'clothing',
  },
  {
    id: 'cheerleader',
    label: 'Cheerleader',
    prompt: 'Cheerleader outfit',
    subcategoryId: 'clothing',
  },
  {
    id: 'topless',
    label: 'Topless',
    prompt: '(Topless:1.1)',
    negativePrompt: '(top, shirt)',
    subcategoryId: 'clothing',
  },
  {
    id: 'choker',
    label: 'Choker',
    prompt: 'Choker',
    subcategoryId: 'clothing',
  },
  {
    id: 'summerdress',
    label: 'Summer Dress',
    prompt: 'Summer dress, colorful flowers',
    subcategoryId: 'clothing',
  },
  {
    id: 'fishnet',
    label: 'Fishnet',
    prompt: 'Fishnet attire',
    subcategoryId: 'clothing',
  },
  {
    id: 'flight_attendant',
    label: 'Flight Attendant',
    prompt: '(Flight attendant uniform)',
    subcategoryId: 'clothing',
  },
  {
    id: 'high_heels',
    label: 'High Heels',
    prompt: 'High heels',
    subcategoryId: 'clothing',
  },
  {
    id: 'secretary',
    label: 'Secretary',
    prompt: 'Secretary outfit, tight skirt',
    subcategoryId: 'clothing',
  },
  {
    id: 'suit',
    label: 'Suit',
    prompt: 'suit and shirt',
    subcategoryId: 'clothing',
  },
  {
    id: 'teacher',
    label: 'Teacher',
    prompt: 'Teacher outfit, skirt, shirt',
    subcategoryId: 'clothing',
  },
  {
    id: 'thigh_socks',
    label: 'Thigh Socks',
    prompt: 'Thigh-high socks',
    subcategoryId: 'clothing',
  },
  {
    id: 'waitress',
    label: 'Waitress',
    prompt: '(Waitress) uniform',
    subcategoryId: 'clothing',
  },
  {
    id: 'yoga_pants',
    label: 'Yoga Pants',
    prompt: 'Yoga pants',
    subcategoryId: 'clothing',
  },
  {
    id: 'tank_top',
    label: 'Tank Top',
    prompt: 'Tank top',
    subcategoryId: 'clothing',
  },
  {
    id: 'mini_skirt',
    label: 'Mini Skirt',
    prompt: 'Mini skirt',
    subcategoryId: 'clothing',
  },
  {
    id: 'maid',
    label: 'Maid',
    prompt: '(Maid) outfit',
    subcategoryId: 'clothing',
  },
  {
    id: 'shirt',
    label: 'Shirt',
    prompt: 'Shirt',
    subcategoryId: 'clothing',
  },
  {
    id: 'jeans',
    label: 'Jeans',
    prompt: 'Jeans',
    subcategoryId: 'clothing',
  },
];

const BODY_TAB_SUBCATEGORIES: SubcategoryInMagicMode[] = [
  { label: 'Body type', id: 'body-type', tags: BODY_TYPE_TAGS },
  { label: 'Boobs', id: 'boobs', tags: BOOB_TAGS },
  { label: 'Clothing', id: 'clothing', tags: CLOTHING_TAGS, multiSelect: true },
  /*
  { label: 'Butt', id: 'butt', tags: BUTT_TAGS },
  {
    label: 'Clothing modifiers',
    id: 'clothing-modifiers',
    tags: [],
  },
  */
];

const SCENE_TAGS: TagInMagicMode[] = [
  {
    id: 'beach',
    label: 'Beach',
    prompt: 'Beach',
    subcategoryId: 'scene',
  },
  {
    id: 'bedroom',
    label: 'Bedroom',
    prompt: 'Bedroom',
    subcategoryId: 'scene',
  },
  {
    id: 'forest',
    label: 'Forest',
    prompt: 'Forest',
    subcategoryId: 'scene',
  },
  {
    id: 'gym',
    label: 'Gym',
    prompt: 'Gym',
    subcategoryId: 'scene',
  },
  {
    id: 'night club',
    label: 'Night club',
    prompt: 'Night club',
    subcategoryId: 'scene',
  },
  {
    id: 'kitchen',
    label: 'Kitchen',
    prompt: 'Kitchen',
    subcategoryId: 'scene',
  },
  {
    id: 'living-room',
    label: 'Living room',
    prompt: 'Living room',
    subcategoryId: 'scene',
  },
  {
    id: 'office',
    label: 'Office',
    prompt: 'Office',
    subcategoryId: 'scene',
  },
  {
    id: 'pool',
    label: 'Pool',
    prompt: 'Pool',
    subcategoryId: 'scene',
  },
  {
    id: 'school',
    label: 'School',
    prompt: 'School',
    subcategoryId: 'scene',
  },
  {
    id: 'street',
    label: 'Street',
    prompt: 'Street',
    subcategoryId: 'scene',
  },
  {
    id: 'strip-club',
    label: 'Strip club',
    prompt: 'Strip club',
    subcategoryId: 'scene',
  },
  {
    id: 'subway',
    label: 'Subway',
    prompt: 'Subway',
    subcategoryId: 'scene',
  },
];

// composition
const VIEW_TAGS: TagInMagicMode[] = [
  {
    id: 'portrait',
    label: 'Portrait',
    prompt: 'portrait of ',
    subcategoryId: 'view',
  },
  {
    id: 'full-body',
    label: 'Full body',
    prompt: '(Full-body professional shot:1.2)',
    negativePrompt: '(Close-up:1.1)',
    subcategoryId: 'view',
  },
  {
    id: 'back',
    label: 'Back view',
    prompt: '(Photo from behind:1.1)',
    subcategoryId: 'view',
  },
  /*
  {
    id: 'close-up',
    label: 'Close-up',
    prompt: 'Close-up',
    subcategoryId: 'view',
  },

  {
    // POV porn thing here? Maybe need to change "base" first?
    id: 'first-person',
    label: 'First person',
    prompt: 'First person view',
  },
  {
    id: 'fisheye',
    label: 'Fisheye',
    prompt: '(Fisheye shot:1.1)',
    subcategoryId: 'view',
  },
  {
    id: 'wide-angle',
    label: 'Wide angle',
    prompt: 'Wide angle view',
    subcategoryId: 'view',
  },
  */
];

const ACTION_TAGS: TagInMagicMode[] = [
  {
    id: 'standing',
    label: 'Standing',
    prompt: 'Standing',
    subcategoryId: 'action',
  },
  {
    id: 'sitting',
    label: 'Sitting',
    prompt: '(Sitting)',
    subcategoryId: 'action',
  },
  {
    id: 'lying',
    label: 'Lying',
    prompt: '(Lying down:1.1)',
    subcategoryId: 'action',
  },
  {
    id: 'dancing',
    label: 'Dancing',
    prompt: '(Dancing)',
    subcategoryId: 'action',
  },
  {
    id: 'posing',
    label: 'Posing',
    prompt: '(Posing)',
    subcategoryId: 'action',
  },
  {
    id: 'walking',
    label: 'Walking',
    prompt: '(Walking)',
    subcategoryId: 'action',
  },
  /*
  {
    id: 'running',
    label: 'Running',
    prompt: 'Running',
    subcategoryId: 'action',
  },
  {
    id: 'jumping',
    label: 'Jumping',
    prompt: 'Jumping',
    subcategoryId: 'action',
  },
  {
    id: 'squatting',
    label: 'Squatting',
    prompt: 'Squatting',
    subcategoryId: 'action',
  },
  {
    id: 'bending-over',
    label: 'Bending over',
    prompt: 'Bending over',
    subcategoryId: 'action',
  },
  */
];

const OTHER_TAB_SUBCATEGORIES: SubcategoryInMagicMode[] = [
  {
    label: 'Composition',
    id: 'view',
    tags: VIEW_TAGS,
  },
  {
    label: 'Place',
    id: 'scene',
    tags: SCENE_TAGS,
  },
  {
    label: 'Action',
    id: 'action',
    tags: ACTION_TAGS,
  },
  /*
  {
    label: 'others',
    id: 'others',
    tags: [],
  },
  */
];

const categories: CategoryInMagicMode[] = [
  {
    id: 'base',
    label: 'Base',
    subcategories: BASE_TAB_SUBCATEGORIES,
  },
  {
    id: 'head',
    label: 'Head',
    subcategories: HEAD_TAB_SUBCATEGORIES,
  },
  {
    id: 'body',
    label: 'Body',
    subcategories: BODY_TAB_SUBCATEGORIES,
  },
  {
    id: 'other',
    label: 'other',
    subcategories: OTHER_TAB_SUBCATEGORIES,
  },
];

export const getCategoryForMagicMode = (activeTab: TabInCreateCharacter) =>
  categories.find((category) => category.id === activeTab) ?? categories[0];

export const getSubCategories = (
  activeTab: string,
): SubcategoryInMagicMode[] => {
  switch (activeTab) {
    case 'base':
      return BASE_TAB_SUBCATEGORIES;
    case 'head':
      return HEAD_TAB_SUBCATEGORIES;
    case 'body':
      return BODY_TAB_SUBCATEGORIES;
    case 'other':
      return OTHER_TAB_SUBCATEGORIES;
    default:
      return BASE_TAB_SUBCATEGORIES;
  }
};
