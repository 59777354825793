import { toast } from 'react-toastify';
import { FeedChannel } from '../../types';
import { customFetch } from '../customFetch';

type FetchPostedImages = {
  channelName?: string;
  sort?: string;
  channelList: FeedChannel[];
};

export type LandingImage = {
  imageUrl: string;
  id: string;
};

const getChannel = (channelName: string, channelList: FeedChannel[]) => {
  if (channelName.toLowerCase() === 'all') {
    return undefined;
  }
  return channelList.find((c) => c.name.toLowerCase() === channelName);
};

const useGetPostedImagesForLanding = () => {
  const getPostedImages = async ({
    channelName: SC = 'all',
    sort: SSO = 'hot',
    channelList,
  }: FetchPostedImages): Promise<LandingImage[]> => {
    const channelName = SC.toLowerCase();
    const sort = SSO.toLowerCase();

    const channel = getChannel(channelName, channelList);

    const functionName = 'getPostedImages';
    try {
      const images = await customFetch(functionName, {
        channel,
        sort,
        isLandingPage: true,
      });

      if (!images) {
        toast.error('Failed to get images');
      }

      return images;
    } catch (error) {
      console.error('Error fetching posted images:', error);
      return [];
    }
  };

  return getPostedImages;
};

export default useGetPostedImagesForLanding;
