import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { desktopMediaQuery } from "../../styleHelpers";

export const CloseButtonInModal = styled(AiOutlineClose)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  z-index: 10;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  color: white;
`;
