import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";
import { BACKGROUND_COLOR } from "../../../../constants";
import { desktopMediaQuery } from "../../../../styleHelpers";

// TODO: Rename these
export const StickiedTopPart = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  align-items: center;

  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${BACKGROUND_COLOR};

  padding-top: 8px;
  padding-bottom: 4px;
`;

export const CloseButton = styled(AiOutlineClose)`
  font-size: 32px;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  color: white;
`;
