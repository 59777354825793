import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { StyledButton } from '../../common/StyledButton';
import TransitioningModal from '../TransitioningModal';
import { useNavigate } from 'react-router-dom';
import { MAGIC_PATH, FEED_PATH, ADVANCED_PATH } from '../../../pathNames';
import { useState } from 'react';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const NotificationModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '30px',
            width: '78%',
            maxHeight: '90vh',
            maxWidth: '350px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '40px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: PRIMARY_TEXT_COLOR,
          }}
          onClick={handleClose}
        />

        {showMoreInfo ? (
          <MoreInfo />
        ) : (
          <ShorterContent handleClose={handleClose} />
        )}
      </Modal>
    </>
  );
};

/*
<OKButton onClick={handleClose}>OK ✅</OKButton>
const OKButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;
*/

const ShowMoreButton = styled(StyledButton)`
  border: 1.5px solid ${BORDER_COLOR};
  margin-top: 16px;
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;

  text-align: left;
`;

const List = styled(ModalText)`
  margin-top: 8px;
`;

const TitleText = styled(ModalText)`
  font-size: 20px;
  text-align: center;
`;

const ShorterContent = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const handleTakeToMagicPage = () => {
    navigate(MAGIC_PATH);
    handleClose();
  };

  const handleTakeToAdvancedPage = () => {
    navigate(ADVANCED_PATH);
    handleClose();
  };

  const handleTakeToFeed = () => {
    navigate(FEED_PATH);
    handleClose();
  };
  return (
    <>
      <TitleText>Welcome! 👋</TitleText>

      <List>
        <p>🪄 Magic Mode lets you create women by choosing 'tags'</p>
        <p>
          🔧 The Advanced Mode let's you write a 'prompt' to create what you
          wish
        </p>
      </List>

      <ShowMoreButton onClick={handleTakeToMagicPage}>
        ✨ Open Magic Mode
      </ShowMoreButton>
      <ShowMoreButton onClick={handleTakeToAdvancedPage}>
        ✏️️ Use Advanced Mode
      </ShowMoreButton>
      <ShowMoreButton onClick={handleTakeToFeed}>
        🔍 Browse images on the Feed
      </ShowMoreButton>
    </>
  );
};

const MoreInfo = () => (
  <ScrollableDiv>
    <TitleText>🎨 Styles (beta)</TitleText>

    <ModalText>
      Styles-feature makes it much easier to generate great images without
      adding 500 characters of "prompt magic".
    </ModalText>

    <br />
    <List>
      <b>Under the hood, it works by:</b>
      <p>✨ Adding hidden prompt before and after user prompt</p>
      <p>🚫 Throwing in a hidden negative prompt</p>
      <p>🔧 Changing the engine</p>
    </List>
    <br />

    <ModalText>
      I suggest trying out the default styles and NOT adding any extra prompts,
      such as (detailed face and eyes) or negative prompts, such as (deformed)
    </ModalText>

    <ModalText>
      Click the edit 🖌️ buttons in the More menu to learn how the styles are
      formed, and then create your own!
    </ModalText>

    <ModalText>
      Note: you can skip using styles by using the "None" style
    </ModalText>

    <br />

    <ModalText style={{ marginTop: '16px' }}>
      ⭐ But wait, there's more: I'm opening up all Gold engines for free!
    </ModalText>

    <ModalText>
      Please bear with me as I continue to improve the feature and fix bugs.
      Give me feedback through Discord, Reddit or Email. I just had a baby so I
      don't have energy to work too much atm, though :)
    </ModalText>
  </ScrollableDiv>
);

const ScrollableDiv = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding-right: 16px;
  margin-top: 16px;
`;

export default NotificationModal;
