import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ImageGridProps {
  dataLength: number;
  fetchMore: () => void;
  hasMore: boolean;
  isOnDesktop: boolean;
  children: React.ReactNode;
}

const NoImgs = () => <h3>No images... Try refreshing</h3>;

/**
 * Examples:
 *
 * const fetchMore = () => {
 *
 *   const nextItems = images.slice(items.length, items.length + SHOW_X_PER);
 *
 *   setItems(items.concat(nextItems));
 *
 * };
 *
 *
 * const hasMore = items.length < images.length;
 *
 * const dataLength = items?.length;
 */
const InfiniteImageGrid: React.FC<ImageGridProps> = ({
  dataLength,
  fetchMore,
  hasMore,
  isOnDesktop,
  children,
}) => (
  <InfiniteScroll
    dataLength={dataLength}
    next={fetchMore}
    hasMore={hasMore}
    loader={<NoImgs />}
    scrollThreshold={0.9}
    style={{
      width: '100%',
      display: 'grid',
      gridTemplateColumns: isOnDesktop ? 'repeat(4, 2fr)' : 'repeat(2, 1fr)',
      gridTemplateRows: isOnDesktop ? 'repeat(4, 2fr)' : 'repeat(2, 1fr)',
      gridGap: '1px',
      overflow: 'hidden',
    }}
  >
    {children}
  </InfiniteScroll>
);

export default InfiniteImageGrid;
