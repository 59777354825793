import React from 'react';
import CommunityImage from './CommunityImage';
import { PLACEHOLDER_IMAGE_PATH } from '../../components/ImageStuff/GeneratedImage';

const PlaceholderComponent: React.FC = () => {
  const placeholderImages = Array(4).fill({
    imageUrl: PLACEHOLDER_IMAGE_PATH,
    id: 'placeholder',
  });

  return (
    <>
      {placeholderImages.map((image, index) => (
        <CommunityImage key={index} image={image} nonLazyLoading />
      ))}
    </>
  );
};

export default PlaceholderComponent;
