import { useCookies } from "react-cookie";

const useSetUserCookie = () => {
  const [, setCookie] = useCookies(["token"]);

  const setUserCookie = (token: string) => {
    const maxAge = 7776000; // 90 days in seconds

    setCookie("token", token, {
      path: "/",
      secure: true,
      sameSite: "none",
      maxAge,
    });
  };

  return setUserCookie;
};

export default useSetUserCookie;
