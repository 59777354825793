import ReactModal from 'react-modal';
import styled from 'styled-components';
import { BACKGROUND_COLOR, PRIMARY_COLOR } from '../../constants';
import { GenericSetState } from '../../types';
import { StyledButton } from '../common/StyledButton';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const ServerIssuesModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 5,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          border: 'none',
          borderRadius: '16px',
          padding: '32px',
          width: '80%',
          maxWidth: '600px',
          margin: 'auto',
          marginTop: '200px',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      // click anywhere to close modal
      onRequestClose={handleClose}
    >
      <TitleText>Server issues 😭</TitleText>
      <ModalText>
        Due to issues with servers, image generation is down for most engines.
      </ModalText>
      <ModalText>
         Bear with us 🐻 we're working hard to fix this as soon as possible!
        Thanks for your patience 🙏
      </ModalText>
      <ModalText>Please check back in 30 minutes!</ModalText>
      <AcceptButton onClick={handleClose}>OK!</AcceptButton>
    </Modal>
  );
};

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 50px;
`;

const ModalText = styled.div`
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default ServerIssuesModal;
