import { useCookies } from 'react-cookie';
import { getBaseUrlForFetching } from '../../App';
import { PLACEHOLDER_IMAGE_PATH } from '../../components/ImageStuff/GeneratedImage';
import {
  generateTrackId,
  getCreditsRequired,
  getRandomSeed,
  transformPrompt,
  waitSeconds,
} from '../../components/helpers';
import { MS_IN_SECOND } from '../../constants';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { getWaitTimeForSpeedMode } from '../../generationConstants';
import { addImageToLocalStorage } from '../../localStorage/imageStorage';
import {
  FakeImage,
  GenerateImageObject,
  SpeedModeType,
  StyleInterface,
  EngineType,
} from '../../types';
import {
  checkCombinationsForBlocking,
  checkNegPromptForBlocking,
  checkPromptForAllCelebs,
  checkPromptForBlocking,
  checkPromptForCelebs,
} from '../../utils/clientSideBlocking';
import { getBadWord } from '../misc/getBadWords';
import useHandleUpdateImages from './useHandleUpdateImages';
import { useImagesContext } from '../../context/ImagesContextProvider';
import useHandleDeductCredits from './useHandleDeductCredits';
import { toast } from 'react-toastify';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { handlePopUnderAd } from './helpers';
import { useModalsContext } from '../../context/ModalsContextProvider';

interface P {
  isSeedFrozen: boolean;
  cfg: number;
  amountToGenerate: number;
  speedMode?: SpeedModeType;
  uploadedImageUrl?: string;
  selectedStyle?: StyleInterface;
}
export const headersCorsInc = {
  'Content-type': 'Application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers':
    'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
};

const useHandleSendPrompt = ({ isSeedFrozen }: P) => {
  const {
    setImages,
    setActiveImage,
    loading,
    setLoading,

    setShowSomethingWentWrong,

    setGenerationStartedTimestamp,
    setEstimateGenerationReadyTimestamp,

    setCancelled,

    firstTimeUserToken,
    setFirstTimeUserToken,

    hasGeneratedAnImage,
    setHasGeneratedAnImage,

    setShowTopRightMenu,

    isFirstTimeUser,
  } = useGeneralContext();

  const { setShowHintToRegisterModal, hasClosedHintToRegisterModal } =
    useModalsContext();

  const {
    userPrompt,
    negativePrompt,
    seedState: seed,
    size,
    engine,
    cfg,
    setSeed,
    amountToGenerate,
    speedMode,
    setSpeedMode,
    uploadedImageUrl,
    isCreatingVariations,
    denoisingStrength,
    selectedStyle,
    selectedTags,
  } = useImageGenerationSettingsContext();

  const { loggedInUser } = useLoggedInUserContext();
  const [, setFetchToken] = useCookies(['fetchToken']);
  const handleUpdateImages = useHandleUpdateImages();
  const handleDeductCredits = useHandleDeductCredits();

  const { setFailedTrackIds } = useImagesContext();

  const serviceDown = false;

  const getSpeedModeToUse = (
    speedMode: SpeedModeType,
    speedModeFromParams?: SpeedModeType,
  ) => {
    return speedModeFromParams ? speedModeFromParams : speedMode;
  };

  const handleSendPrompt = async (
    prompt?: string,
    negPrompt?: string,
    style?: StyleInterface,
    customDenoisingStrength?: number,
    customEngine?: EngineType,
    speedModeFromParams?: SpeedModeType,
    createdUsingQuickGenerator?: boolean,
  ) => {
    if (engine.isOffline) {
      if (loggedInUser?.username !== 'alpo') {
        alert('This engine is currently offline. Apologies! 🙏');
        return;
      }
    }
    /*
    if (engine.modelId === 'realistic_vision_v5.1' && speedMode === 'turbo') {
      alert("Turbo mode isn't available for this engine at the moment, please check back in 30 minutes");
      return;
    }
    */
    if (!loggedInUser && hasGeneratedAnImage && !hasClosedHintToRegisterModal) {
      setShowHintToRegisterModal(true);
      return;
    }

    const speedModeToUse = getSpeedModeToUse(speedMode, speedModeFromParams);
    const resultOfAdStuff = handlePopUnderAd(speedModeToUse);
    if (!resultOfAdStuff) {
      return;
    }

    if (speedModeToUse === 'adTurbo' && amountToGenerate > 1) {
      alert('You cannot generate multiple images in Ad mode');
      return;
    }

    if (speedModeToUse === 'turbo' && !loggedInUser && !isFirstTimeUser) {
      setShowHintToRegisterModal(true);
      return;
    }

    if (!prompt && userPrompt === '') {
      toast.error('Please enter a prompt');
      return;
    }

    if (cfg < 1) {
      alert('Please use CFG 1 or higher');
      return;
    }

    const isMe = loggedInUser?.username === 'malossi';
    if (serviceDown && !isMe) {
      alert(
        'Due to a third party issue the image generation is down 😭 check back in an hour or so',
      );
      return;
    }
    setCancelled(false);
    if (loading) {
      alert('You are already creating an image');
      return;
    }
    setLoading(true);
    const fullPrompt = prompt
      ? transformPrompt(prompt)
      : transformPrompt(userPrompt);

    const badWord = getBadWord(fullPrompt);
    if (badWord) {
      alert(`Please do not use "${badWord}" in your prompt`);
      setLoading(false);
      return;
    }

    const isBannedRegex = checkPromptForBlocking(fullPrompt);
    if (isBannedRegex) {
      alert(
        `Forbidden prompt. If you think this is a mistake, please contact support@onlyfakes.app with the prompt`,
      );
      setLoading(false);
      return;
    }

    const isblockedNegativePrompt = checkNegPromptForBlocking(negativePrompt);
    if (isblockedNegativePrompt) {
      alert(
        `Forbidden prompt. If you think this is a mistake, please contact support@onlyfakes.app
        `,
      );
      setLoading(false);
      return;
    }

    const isTryingToMakeCeleb = checkPromptForCelebs(fullPrompt);
    const isTryingToMakeAllCelebs = checkPromptForAllCelebs(fullPrompt);
    if (isTryingToMakeCeleb || isTryingToMakeAllCelebs) {
      alert(
        `Forbidden prompt. If you think this is a mistake, please contact support@onlyfakes.app with the prompt`,
      );
      setLoading(false);
      return;
    }

    const shouldBlockForCombination = checkCombinationsForBlocking(fullPrompt);
    if (shouldBlockForCombination) {
      alert(
        `Forbidden prompt. If you think this is a mistake, please contact support@onlyfakes.app with the prompt`,
      );
      setLoading(false);
      return;
    }

    const creditsRequired = getCreditsRequired(
      speedModeToUse,
      amountToGenerate,
      engine,
    );
    const handleDeductCreditsResult = handleDeductCredits(creditsRequired);
    console.log(handleDeductCreditsResult);
    if (!loggedInUser) {
      setSpeedMode('adTurbo');
      //toast.info('Sign up to not see ads and get free credits ⏲');
      //await waitSeconds(2);
      //setShowHintToRegisterModal(true);
      /*
        toast.error(
          'Not enough credits! Sign up to get free credits or use Ad mode ⏲',
        );
        setLoading(false);
        */
    } else if (handleDeductCreditsResult !== 'ok') {
      const reasons: { [key: string]: boolean | undefined } = {
        goldEngine: engine.isGoldOnly,
        fast: speedModeToUse === 'fast' || speedModeToUse === 'turbo',
        amount: amountToGenerate > 1,
      };

      const textKey =
        Object.keys(reasons).find((key) => reasons[key]) || 'goldEngine';

      const text = {
        goldEngine: 'Choose a non-Gold engine',
        fast: 'Choose a lower speed mode ⏲',
        amount: 'Generate less images',
      }[textKey];

      toast.error(`You don't have enough credits to do this. ${text}`);
      await waitSeconds(1);
      toast.info('You can use Ad mode to generate images for free ⏲');
      setSpeedMode('adTurbo');
      setLoading(false);
      setShowTopRightMenu(true);
      return;
    }

    const getExpectedWait = (speedMode: SpeedModeType) => {
      let queueTime = getWaitTimeForSpeedMode(speedMode);
      if (amountToGenerate !== 1) {
        return queueTime * 0.9 * amountToGenerate;
      }

      return queueTime;
    };

    const expectedWait = getExpectedWait(speedModeToUse) * MS_IN_SECOND;
    const now = Date.now();
    setGenerationStartedTimestamp(now);
    setEstimateGenerationReadyTimestamp(now + expectedWait);

    const getSeed = () => {
      if (isSeedFrozen) {
        if (seed > 4294967294) return getRandomSeed();
        if (seed === 0) return getRandomSeed();
        return seed as number;
      }

      const randomSeed = getRandomSeed();
      setSeed(randomSeed);
      return randomSeed;
    };

    // TODO: refactor so not to two different objects!!!

    const trackId = generateTrackId();
    const finalSeed = getSeed();
    const negativePromptToUse = negPrompt || negativePrompt;
    const engineToUse = customEngine || engine;
    const styleToUse = style || selectedStyle;
    const denoiseToUse = customDenoisingStrength || denoisingStrength;
    const generateImageObject: GenerateImageObject = {
      fullPrompt,
      negativePrompt: negativePromptToUse,
      seed: finalSeed,
      size,
      engine: engineToUse,
      cfg,
      trackId,
      amountToGenerate,
      speedMode: speedModeToUse,
      uploadedImageUrl,
      isCreatingVariations,
      denoisingStrength: denoiseToUse,
      style: styleToUse,
      selectedTags,

      createdUsingQuickGenerator,
    };

    if (firstTimeUserToken !== '') {
      generateImageObject.firstTimeUserToken = firstTimeUserToken;
    }

    const body = JSON.stringify({ generateImageObject });

    const functionName = 'generateImageAJ';

    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const imageToAdd: FakeImage = {
      prompt: userPrompt,
      negativePrompt: negativePromptToUse,
      seed: finalSeed,
      height: Number(size.height),
      width: Number(size.width),
      engine: engineToUse,
      guidanceScale: cfg,
      trackId,
      imageUrl: PLACEHOLDER_IMAGE_PATH,
      generating: true,
      style: styleToUse,
      selectedTags,
    };

    const handleAddImagesToStates = async (amountToGenerate: number) => {
      for (let i = 1; i <= amountToGenerate; i++) {
        const image = {
          ...imageToAdd,
          trackId: `${trackId}-${i}`,
          seed: imageToAdd.seed + i - 1,
        };

        setImages((images) => images.concat(image));
        addImageToLocalStorage(image);

        if (i < amountToGenerate) {
          await waitSeconds(0.3);
        }
      }
    };

    // if 2 or more images
    if (amountToGenerate > 1) {
      await handleAddImagesToStates(amountToGenerate);
    } else {
      setImages((images) => images.concat(imageToAdd));
      setActiveImage(imageToAdd);
      addImageToLocalStorage(imageToAdd);
    }
    await waitSeconds(0.1);

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body,
      headers: headersCorsInc,
      credentials: 'include',
    });

    if (response.status !== 200) {
      setFailedTrackIds((s) => [...s, imageToAdd.trackId]);

      setShowSomethingWentWrong(true);
      const failedImg = imageToAdd;
      failedImg.failed = true;
      failedImg.generating = false;
      failedImg.imageUrl = PLACEHOLDER_IMAGE_PATH;
      handleUpdateImages(failedImg);
      setLoading(false);
      setCancelled(true);

      setFailedTrackIds((trackIds) => trackIds.concat(trackId));
      throw new Error(`Failed to generate image, trackId: ${trackId}`);
    }

    const data = await response.json();

    if (data.pleaseRefresh) {
      alert(
        'Old version detected. Refreshing the page to continue. You will not lose credits. Sorry for the inconvenience 🙏',
      );
      window.location.reload();
      return;
    }

    if (data.queue === 420) {
      await waitSeconds(15);
      alert(
        "You have been banned by the automatic system. If you're sure you didn't break the rules, please send your username to support@onlyfakes.app. Sorry for the inconvenience!",
      );
      return;
    }

    if (data.fetchToken) {
      setFirstTimeUserToken('');
      setHasGeneratedAnImage(true);
      const maxAge = 7776000;
      setFetchToken('fetchToken', data.fetchToken, {
        path: '/',
        secure: true,
        sameSite: 'strict',
        maxAge,
      });
    }
  };
  return handleSendPrompt;
};

export default useHandleSendPrompt;
