import React, { useState } from 'react';
import styled from 'styled-components';
import { SmallStyledButton } from '../../../common/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import AddImagesModal from './AddImagesModal';

const AddMoreImagesModalButton: React.FC = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  return (
    <>
      <Button onClick={handleClick}>
        <FaPlus size={12} />
        Add More Images
      </Button>
      <AddImagesModal show={show} setShow={setShow} />
    </>
  );
};

export default AddMoreImagesModalButton;

const Button = styled(SmallStyledButton)``;
