import styled from 'styled-components';
import { EngineType, GenericSetState } from '../../../types';
import {
  getEngines,
  getRecentlyUsedEngines,
} from '../../../utils/engineHelpers';
import EngineCard from './EngineCard';
import { RefObject, useRef } from 'react';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import {
  BORDER_COLOR,
  HOVER_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';

interface P {
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
  setShowModal: GenericSetState<boolean>;
}

const EngineList = ({ engine, setEngine, setShowModal }: P) => {
  const engineList = getEngines();
  const recentEngines = getRecentlyUsedEngines();
  const { loggedInUser } = useLoggedInUserContext();

  const realisticEngines = engineList.filter((e) => e.type === 'realistic');
  const animeEngines = engineList.filter((e) => e.type === 'anime');
  const digitalEngines = engineList.filter((e) => e.type === 'digital');

  const realisticRef = useRef<HTMLDivElement>(null);
  const animeRef = useRef<HTMLDivElement>(null);
  const digitalRef = useRef<HTMLDivElement>(null);
  const recentRef = useRef<HTMLDivElement>(null);

  // used for handling scrolling on desktop
  const handleScroll = (
    refToUse: RefObject<HTMLDivElement>,
    direction: 'left' | 'right',
  ) => {
    const distance = 400;
    const elem = refToUse.current;

    if (!elem) return;

    const nextScroll =
      elem.scrollLeft + (direction === 'left' ? -distance : distance);
    elem.scrollTo({ left: nextScroll, behavior: 'smooth' });
  };

  const showArrows = window.innerWidth >= 1000;

  // if this comes online, remember to change FilterButton.tsx
  const showXxlEngine = loggedInUser?.username === 'malossi';
  const xxlhdEngine: EngineType = {
    modelId: 'xxlhd',
    name: 'XXLHD',
    type: 'realistic',
    isOffline: false,
    isGoldOnly: false,
    isNew: false,
  };
  return (
    <Container>
      {showXxlEngine && (
        <EngineCard
          key="xxlhd"
          engine={xxlhdEngine}
          setEngine={setEngine}
          setShowModal={setShowModal}
          isSelected={engine.modelId === 'xxlhd'}
        />
      )}
      {recentEngines.length !== 0 && (
        <>
          <EngineTitleContainer>
            Recently used
            {showArrows && (
              <Arrows
                onClickLeft={() => handleScroll(recentRef, 'left')}
                onClickRight={() => handleScroll(recentRef, 'right')}
              />
            )}
          </EngineTitleContainer>
          <CarouselContainer ref={recentRef}>
            {recentEngines.map((e) => (
              <EngineCard
                key={e.modelId}
                engine={e}
                setEngine={setEngine}
                setShowModal={setShowModal}
                isSelected={e.modelId === engine.modelId}
              />
            ))}
          </CarouselContainer>
        </>
      )}

      <EngineTitleContainer>
        Realistic engines
        {showArrows && (
          <Arrows
            onClickLeft={() => handleScroll(realisticRef, 'left')}
            onClickRight={() => handleScroll(realisticRef, 'right')}
          />
        )}
      </EngineTitleContainer>
      <CarouselContainer ref={realisticRef}>
        {realisticEngines.map((e) => (
          <EngineCard
            key={e.modelId}
            engine={e}
            setEngine={setEngine}
            setShowModal={setShowModal}
            isSelected={e.modelId === engine.modelId}
          />
        ))}
      </CarouselContainer>
      <EngineTitleContainer>
        Anime engines
        {showArrows && (
          <Arrows
            onClickLeft={() => handleScroll(animeRef, 'left')}
            onClickRight={() => handleScroll(animeRef, 'right')}
          />
        )}
      </EngineTitleContainer>
      <CarouselContainer ref={animeRef}>
        {animeEngines.map((e) => (
          <EngineCard
            key={e.modelId}
            engine={e}
            setEngine={setEngine}
            setShowModal={setShowModal}
            isSelected={e.modelId === engine.modelId}
          />
        ))}
      </CarouselContainer>
      <EngineTitleContainer>
        Digital engines
        {showArrows && (
          <Arrows
            onClickLeft={() => handleScroll(digitalRef, 'left')}
            onClickRight={() => handleScroll(digitalRef, 'right')}
          />
        )}
      </EngineTitleContainer>
      <CarouselContainer ref={digitalRef}>
        {digitalEngines.map((e) => (
          <EngineCard
            key={e.modelId}
            engine={e}
            setEngine={setEngine}
            setShowModal={setShowModal}
            isSelected={e.modelId === engine.modelId}
          />
        ))}
      </CarouselContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 100%;

  padding-bottom: 32px;
`;

interface PA {
  onClick: () => void;
}
const ArrowContainer = styled.div`
  display: flex;
  color: ${BORDER_COLOR};
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${HOVER_COLOR};
    }
  }
`;

const LeftArrow = ({ onClick }: PA) => (
  <ArrowContainer onClick={onClick}>
    <FiArrowLeftCircle />
  </ArrowContainer>
);

const RightArrow = ({ onClick }: PA) => (
  <ArrowContainer onClick={onClick}>
    <FiArrowRightCircle />
  </ArrowContainer>
);

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  gap: 8px;
`;

interface ArrowsProps {
  onClickLeft: () => void;
  onClickRight: () => void;
}

const Arrows = ({ onClickLeft, onClickRight }: ArrowsProps) => (
  <ArrowsContainer>
    <LeftArrow onClick={onClickLeft} />
    <RightArrow onClick={onClickRight} />
  </ArrowsContainer>
);

const CarouselContainer = styled.div`
  display: flex;
  white-space: nowrap;

  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  gap: 16px;
  min-height: 220px;
`;

const EngineTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  margin-top: 16px;

  justify-content: space-between;

  color: ${PRIMARY_TEXT_COLOR};
`;

export default EngineList;
