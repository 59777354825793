import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';

const EditButtonForStyles = () => {
  const { setShowAllStylesModal, setDidClickEditButton } = useImageGenerationSettingsContext();
  const handleClick = () => {
    setDidClickEditButton(true);
    setShowAllStylesModal(true);
  };
  return <EditButton onClick={handleClick} />;
};

const EditButton = styled(MdEdit)`
  position: absolute;
  color: white;
  top: 2px;
  right: 24px;
  font-size: 18px;

  &:hover {
    cursor: pointer;
  }
`;

export default EditButtonForStyles;
