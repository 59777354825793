import styled from 'styled-components';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { desktopMediaQuery } from '../../styleHelpers';
import { useState } from 'react';
import { PLACEHOLDER_IMAGE_PATH } from '../../components/ImageStuff/GeneratedImage';
import { LandingImage } from '../../hooks/feed/useGetPostedImagesForLanding';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../pathNames';

interface I {
  image: LandingImage;
  nonLazyLoading?: boolean;
}
const CommunityImage = ({ image, nonLazyLoading }: I) => {
  const [loaded, setLoaded] = useState(false);

  const imageUrl = image?.imageUrl;

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const navigate = useNavigate();
  const handleNavToImage = () => {
    const urlToNavigate = `${FEED_PATH}?id=${image.id}&imageUrl=${imageUrl}`;
    // FeedImagePage.tsx
    navigate(urlToNavigate);
  };

  return (
    <Image
      onClick={handleNavToImage}
      src={loaded ? imageUrl : PLACEHOLDER_IMAGE_PATH}
      alt={'Image Generated by AI Nude Generator'}
      loading={nonLazyLoading ? 'eager' : 'lazy'}
      onLoad={handleImageLoaded}
    />
  );
};
export default CommunityImage;

const Image = styled.img`
  height: 220px;
  width: 120px;
  object-fit: cover;
  margin-left: 2px;
  border-radius: 8px;

  animation: ${fadeInWithBounce} 0.5s forwards;

  ${desktopMediaQuery} {
    height: 400px;
    width: 220px;

    cursor: pointer;
  }
`;
