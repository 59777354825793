import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import useUploadImage from "../../hooks/images/useUploadImage";
import useInpaintImage from "../../hooks/images/useInpaintImage";
import OptionsBelowCanvas from "./OptionsBelowCanvas";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import { SpeedModeType } from "../../types";
import { BORDER_COLOR } from "../../constants";

const DEFAULT_NEGATIVE_IN_INPAINTING = "(disfigured, deformed, morphed:1.3), (blurry face:1.3) (oversaturated)"
const DrawingCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const [prompt, setPrompt] = useState<string>("");
  const [negativePrompt, setNegativePrompt] = useState<string>(DEFAULT_NEGATIVE_IN_INPAINTING);
  const [imageStrength, setImageStrength] = useState<number>(0.7);
  const [seed, setSeed] = useState<number>(-1);
  const [speedMode, setSpeedMode] = useState<SpeedModeType>("normal");

  const [brushSize, setBrushSize] = useState<number>(50);

  const { setLoading, activeImage } = useGeneralContext();

  const { imageUrl, width, height } = activeImage;
  const uploadImage = useUploadImage();
  const inpaintImage = useInpaintImage();

  const fillWithWhite = (
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D
  ) => {
    context.fillStyle = "#000000";
    context.fillRect(0, 0, canvas.width, canvas.height);
  };

  type CanvasState = ImageData;

  const historyRef = useRef<CanvasState[]>([]);
  const undoIndexRef = useRef<number>(-1);

  const undo = (context: CanvasRenderingContext2D) => {
    if (undoIndexRef.current <= 0) {
      return;
    }

    undoIndexRef.current--;
    // Clear the canvas before restoring previous state
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const imageData = historyRef.current[undoIndexRef.current];
    context.putImageData(imageData, 0, 0);
  };

  const saveState = (context: CanvasRenderingContext2D) => {
    const imageData = context.getImageData(
      0,
      0,
      context.canvas.width,
      context.canvas.height
    );
    // Remove states after current index if undo was used
    historyRef.current = historyRef.current.slice(0, undoIndexRef.current + 1);
    historyRef.current.push(imageData);
    undoIndexRef.current++;
  };

  // this is for to the brush size is updated when the user changes it
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!canvas || !context) {
      return;
    }

    context.lineWidth = brushSize;
    clearCanvas(canvas);
  }, [brushSize, canvasRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    const context = canvas?.getContext("2d");

    if (!canvas || !context || !image) {
      return;
    }

    saveState(context);

    canvas.width = image.width;
    canvas.height = image.height;

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const startDrawing = (event: MouseEvent | TouchEvent) => {
      isDrawing = true;
      const { offsetX, offsetY } = getCoordinates(event);
      lastX = offsetX;
      lastY = offsetY;
    };
    const stopDrawing = () => {
      isDrawing = false;
      saveState(context);
    };

    const draw = (x: number, y: number) => {
      if (!isDrawing) {
        return;
      }

      context.strokeStyle = "#ffffff";
      if (context.lineWidth < 10) {
        context.lineWidth = 50;
      }
      context.lineCap = "round";

      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(x, y);
      context.stroke();

      lastX = x;
      lastY = y;
    };

    const drawOnMove = (event: MouseEvent | TouchEvent) => {
      event.preventDefault();
      const { offsetX, offsetY } = getCoordinates(event);
      draw(offsetX, offsetY);
    };

    const getCoordinates = (event: MouseEvent | TouchEvent) => {
      const canvasRect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / canvasRect.width;
      const scaleY = canvas.height / canvasRect.height;

      if (event instanceof MouseEvent) {
        return {
          offsetX: (event.clientX - canvasRect.left) * scaleX,
          offsetY: (event.clientY - canvasRect.top) * scaleY,
        };
      }
      return {
        offsetX: (event.touches[0].clientX - canvasRect.left) * scaleX,
        offsetY: (event.touches[0].clientY - canvasRect.top) * scaleY,
      };
    };

    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("touchstart", startDrawing);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("touchend", stopDrawing);
    canvas.addEventListener("mousemove", drawOnMove);
    canvas.addEventListener("touchmove", drawOnMove);

    return () => {
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("touchstart", startDrawing);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("touchend", stopDrawing);
      canvas.removeEventListener("mousemove", drawOnMove);
      canvas.removeEventListener("touchmove", drawOnMove);
    };
  }, [canvasRef, imageRef]);

  const clearCanvas = (canvas: HTMLCanvasElement) => {
    const context = canvas.getContext("2d");

    if (!context) {
      return;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    fillWithWhite(canvas, context);
  };

  const getBase64Png = (canvas: HTMLCanvasElement): string => {
    // Create a temporary canvas with original dimensions
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width;
    tempCanvas.height = height;

    const tempContext = tempCanvas.getContext("2d");
    if (tempContext) {
      // Draw the content of the current canvas (scaled) onto the temporary canvas
      // this is so if the image is scaled, the image is retained
      tempContext.drawImage(canvas, 0, 0, width, height);
    }

    const base64String = tempCanvas.toDataURL();
    return base64String;
  };

  const generateInpainting = async (canvas: HTMLCanvasElement) => {
    const base64Png = getBase64Png(canvas);

    // TODO: don't upload image everytime! Should only upload once, and if using the same image then not uploading
    const maskResult = await uploadImage(base64Png);
    if (
      !maskResult ||
      maskResult.status !== 200 ||
      !maskResult.imageUrl ||
      !activeImage.imageUrl
    ) {
      console.error("Error uploading image");
      return;
    }
    setLoading(true);
    // TODO: add error handling here
    await inpaintImage(
      maskResult.imageUrl,
      activeImage,
      prompt,
      negativePrompt,
      imageStrength,
      seed,
      speedMode
    );
  };

  const canvas = canvasRef.current!;

  const [state, setState] = useState(0);
  useEffect(() => {
    setState(state + 1);
  }
  , [width]);


  return (
    <Container>
      <ImageContainer>
        <StyledCanvas ref={canvasRef} />
        {imageUrl ? (
          <StyledImage ref={imageRef} src={imageUrl} alt="Image to be edited" />
        ) : (
          <ErrorText />
        )}
      </ImageContainer>
      <OptionsBelowCanvas
        brushSize={brushSize}
        setBrushSize={setBrushSize}
        clearCanvas={clearCanvas}
        generateInpainting={generateInpainting}
        canvas={canvas}
        prompt={prompt}
        setPrompt={setPrompt}
        negativePrompt={negativePrompt}
        setNegativePrompt={setNegativePrompt}
        imageStrength={imageStrength}
        setImageStrength={setImageStrength}
        seed={seed}
        setSeed={setSeed}
        speedMode={speedMode}
        setSpeedMode={setSpeedMode}
        handleUndo={() => undo(canvas.getContext("2d")!)}
      />
    </Container>
  );
};

const ErrorText = () => (
  <div
    style={{
      color: "red",
    }}
  >
    Error: Please select an image to edit
  </div>
);

const ImageContainer = styled.div`
  position: relative;
  display: inline-block; // Keeps the container's size fit to its content

  align-self: center;
`;

const StyledImage = styled.img`
  display: block;
  max-width: 100%; // Makes the image responsive
  height: auto;
  border-radius: 4px;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  border: 1px solid ${BORDER_COLOR};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default DrawingCanvas;
