import React, { useState } from 'react';
import styled from 'styled-components';
import TransitioningModal from '../../components/Modals/TransitioningModal';
import { BACKGROUND_COLOR } from '../../constants';
import { SubcategoryInMagicMode, TagInMagicMode } from '../../types';
import Tooltip from '../../components/common/Tooltip';
import { StyledButton } from '../../components/common/StyledButton';
import { StyledInputSmall } from '../../components/common/StyledInputSmall';
import { CloseButtonInModal } from '../../components/common/CloseButtonInModal';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSelectTag: (tag: TagInMagicMode) => void;
  subcategory: SubcategoryInMagicMode;
}

const TOOLTIP_TEXT =
  'Write a custom prompt to add to your creation. Prompts are short descriptions of the things you want to create. If needed, add (paranthesis around) to make sure AI pays more attention to this prompt';

const CustomTagModal: React.FC<I> = ({ show, setShow, handleSelectTag, subcategory }) => {
  const [tagPrompt, setTagPrompt] = useState('');

  const handleAddCustomTag = () => {
    const tagToAdd: TagInMagicMode = {
      id: tagPrompt,
      subcategoryId: subcategory.id,
      label: tagPrompt,
      // to give this more emphasis
      prompt:`(${tagPrompt})`,
      isCustomTag: true,
    };
    handleSelectTag(tagToAdd);
    setShow(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddCustomTag();
    }
  };
  return (
    <TransitioningModal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 200,
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        },
        content: {
          position: 'relative',
          top: '15%',
          padding: '24px',
          paddingTop: '48px',
          backgroundColor: BACKGROUND_COLOR,
          width: '100%',
          maxWidth: '500px',
          height: '175px',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShow(false)}
    >
      <CloseButtonInModal onClick={() => setShow(false)} />
      <Container>
        <Caption>
          Custom tag for {subcategory.label}<Tooltip text={TOOLTIP_TEXT} />
        </Caption>
        <StyledInputSmall
          placeholder="Write prompt..."
          autoFocus
          value={tagPrompt}
          onChange={(e) => setTagPrompt(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <StyledButton onClick={handleAddCustomTag}>Add</StyledButton>
      </Container>
    </TransitioningModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

const Caption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
`;

export default CustomTagModal;
