import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import GeneralContextProvider from './context/GeneralContextProvider';
import LoggedInUserContextProvider from './context/LoggedInUserContextProvider';
import ImagesContextProvider from './context/ImagesContextProvider';
import ImageGenerationSettingsContextProvider from './context/ImageGenerationSettingsProvider';
import CacheContextProvider from './context/CacheContext';
import ModalsContextProvider from './context/ModalsContextProvider';

/*
Sentry.init({
  dsn: 'https://af179d84d504a28d91682016218914ae@o4505837962330112.ingest.sentry.io/4505894302646272',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/onlyfakes\.app/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.5,
});
*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <LoggedInUserContextProvider>
    <ModalsContextProvider>
      <GeneralContextProvider>
        <ImagesContextProvider>
          <ImageGenerationSettingsContextProvider>
            <CacheContextProvider>
              <App />
            </CacheContextProvider>
          </ImageGenerationSettingsContextProvider>
        </ImagesContextProvider>
      </GeneralContextProvider>
    </ModalsContextProvider>
  </LoggedInUserContextProvider>,
);
