import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  DESKTOP_WIDTH,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageNames } from '../../types';

import {
  FEED_PATH,
  HOME_PATH,
  INPAINTING_PATH,
  LIBRARY_PATH,
  PROFILE_PATH,
  GENERATE_PATH,
} from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';
import { MdOutlineCollections, MdSearch } from 'react-icons/md';
import { FaHome, FaMagic } from 'react-icons/fa';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<PageNames>('home');

  const handleNavHome = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('home');
    navigate(HOME_PATH);
  };

  const handleNavCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('create');
    navigate(GENERATE_PATH);
  };

  const handleNavFeed = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('explore');
    navigate(FEED_PATH);
  };

  const handleClick3 = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('library');
    navigate(LIBRARY_PATH);
  };

  const path = window.location.pathname;
  useEffect(() => {
    if (path === HOME_PATH) {
      setSelectedTab('home');
    } else if (path === GENERATE_PATH) {
      setSelectedTab('create');
    } else if (path === FEED_PATH) {
      setSelectedTab('explore');
    } else if (path === LIBRARY_PATH) {
      setSelectedTab('library');
    } else if (path === PROFILE_PATH) {
      setSelectedTab('profile');
    } else if (path === INPAINTING_PATH) {
      setSelectedTab('home');
    }
  }, [path]);

  const isHomeSelected = selectedTab === 'home';
  const isCreateSelected = selectedTab === 'create';
  const isFeedSelected = selectedTab === 'explore';
  const isLibrarySelected = selectedTab === 'library';

  return (
    <Container>
      <HomeIcon isSelected={isHomeSelected} onClick={(e) => handleNavHome(e)} />
      <CreateIcon
        isSelected={isCreateSelected}
        onClick={(e) => handleNavCreate(e)}
      />
      <FeedIcon isSelected={isFeedSelected} onClick={(e) => handleNavFeed(e)} />
      <LibraryIcon
        isSelected={isLibrarySelected}
        onClick={(e) => handleClick3(e)}
      />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  bottom: 0px;
  left: 2px;

  padding-bottom: 34px;
  background-color: ${BACKGROUND_COLOR};
  z-index: 3;

  box-shadow: 0px -4px 2px 0px rgba(0, 0, 0, 0.9);

  width: 99vw;
  //if on desktop, instead of being on bottom, show on left side
  @media (min-width: ${DESKTOP_WIDTH}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: 40px;
    bottom: unset;
    left: 0px;
    padding-top: 80px;

    height: 80%;

    // give more padding left and small border
    padding-left: 8px;

    box-shadow: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: color 0.2s, transform 0.2s ease-in-out; // Added transform transition

  // text wrap
  text-align: center;

  ${desktopMediaQuery} {
    padding: 50px;
    border-right: 1px solid ${LIGHT_GREY_COLOR};
    font-size: 28px;
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const IconText = styled.div`
  font-size: 12px;
`;

const tranisitonToShare = `
  
`;
interface IconProps {
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const StyledHomeIcon = styled(FaHome)`
  ${tranisitonToShare}
`;

const HomeIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <StyledHomeIcon color={PRIMARY_COLOR} />
      ) : (
        <StyledHomeIcon />
      )}
      <IconText>Home</IconText>
    </IconContainer>
  );
};

const StyledCreateIcon = styled(FaMagic)`
  ${tranisitonToShare}
  font-size: 22px;
  margin-top: 4px;
`;

const CreateIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <StyledCreateIcon color={PRIMARY_COLOR} />
      ) : (
        <StyledCreateIcon />
      )}
      <IconText>Generator</IconText>
    </IconContainer>
  );
};

const FeedStyledIcon = styled(MdSearch)`
  ${tranisitonToShare}
  font-size: 26px;
`;

const FeedIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <FeedStyledIcon color={PRIMARY_COLOR} />
      ) : (
        <FeedStyledIcon />
      )}
      <IconText>Explore</IconText>
    </IconContainer>
  );
};

const LibraryStyledIcon = styled(MdOutlineCollections)`
  ${tranisitonToShare}
`;

const LibraryIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <LibraryStyledIcon color={PRIMARY_COLOR} />
      ) : (
        <LibraryStyledIcon />
      )}
      <IconText>Library</IconText>
    </IconContainer>
  );
};

export default NavigationBar;
