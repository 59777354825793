import styled from 'styled-components';
import DrawingCanvas from './DrawingCanvas';
import GeneratedInpaintsContainer from './GeneratedInpaintsContainer';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { MAX_WIDTH } from '../../constants';
import { useEffect, useState } from 'react';
import useHandleUpdatingTitle from '../../hooks/misc/useHandleUpdatingTitle';
import { desktopMediaQuery } from '../../styleHelpers';
import { scrollToTop } from '../../hooks/misc/helpers';
import { FaArrowLeft } from 'react-icons/fa6';

const InpaintingPage = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  const { editedImages, setEditedImages } = useImagesContext();

  useEffect(() => {
    setEditedImages([]);
    scrollToTop();
  }, [setEditedImages]);
  useHandleUpdatingTitle();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState(0);

  return (
    <Container>
      <BackButton onClick={handleGoBack}>BACK</BackButton>

      <TitleText>✏️ Inpainting</TitleText>
      {editedImages.length === 0 ? (
        <>
          <InstructionsText />
        </>
      ) : (
        <GeneratedInpaintsContainer />
      )}
      <DrawingCanvas key={state} />
    </Container>
  );
};

const InstructionsText = () => (
  <TextForInstructions>
    1. Select areas of the image to edit
    <br />
    2. Write a prompt
    <br />
    3. Generate! AI will only edit what you selected
  </TextForInstructions>
);

const Container = styled.div`
  padding-bottom: 80px;
  max-width: ${MAX_WIDTH}px;

  @media (min-width: 1360px) and (max-width: 1500px) {
    margin-left: -200px;
  }
`;

const TextForInstructions = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(TextForInstructions)`
  font-size: 24px;
`;

// react icon for back button
const BackButton = styled(FaArrowLeft)`
  position: absolute;
  top: 80px;
  left: 16px;
  font-size: 24px;

  ${desktopMediaQuery} {
    top: 120px;
    left: 420px;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }

  @media (min-width: 1360px) and (max-width: 1500px) {
    left: 300px;
  }
`;

export default InpaintingPage;
