import { BsBrush } from "react-icons/bs";
import styled from "styled-components";
import { IconButton } from "./IconButtonInFeed";
import { sharedIconStyle } from "./IconButtonStuff";
import { ICON_COLOR, PRIMARY_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

interface P {
  inFeed?: boolean;
}
export const InpaintingButtonInModal = ({ inFeed }: P) => (
  <IconButton>
    <InpaintingIcon inFeed={inFeed} />
  </IconButton>
);

const InpaintingIcon = styled(BsBrush)<P>`
  ${sharedIconStyle}
  margin-right: 4px;
  font-size: 22px;
  ${desktopMediaQuery} {
    &:hover {
      // no hover effect if inFeed
      color: ${({ inFeed }) => (inFeed ? ICON_COLOR : PRIMARY_COLOR)};
    }
  }
`;
