import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { DESKTOP_WIDTH } from '../../constants';
import { desktopMediaQuery, getIsOnDesktop } from '../../styleHelpers';

interface P {
  value: string;
  setValue: (value: string) => void;
  autofocus?: boolean;
  placeholder: string;
  id?: string;
  callbackOnEnter?: () => void;
}

const HomePageGeneratorInput = ({
  value,
  setValue,
  autofocus,
  placeholder,
  id,
  callbackOnEnter,
}: P) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const maxLength = 300;

  const isDesktop = getIsOnDesktop();
  const adjustHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = isDesktop ? '50px' : '40px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  useEffect(() => {
    if (isDesktop) {
      textareaRef.current?.focus();
    }
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    if (val.length > maxLength) {
      return;
    }
    setValue(val);
    adjustHeight();
  };

  return (
    <Textarea
      autoCapitalize="none"
      id={id}
      value={value}
      onChange={onChange}
      onKeyDown={(e) => {
        // if on mobile, allow enter to create new line
        if (window.innerWidth < DESKTOP_WIDTH) {
          return;
        }
        if (e.key === 'Enter' && e.shiftKey) {
          return;
        }
        if (e.key === 'Enter') {
          e.preventDefault();
          callbackOnEnter && callbackOnEnter();
        }
      }}
      autoFocus={autofocus}
      placeholder={placeholder}
      ref={textareaRef}
    />
  );
};

export default HomePageGeneratorInput;

const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  overflow: hidden;

  padding: 12px;

  box-sizing: border-box;

  font-size: 16px;
  ${desktopMediaQuery} {
    font-size: 20px;
  }

  &:focus {
    outline: none;
  }

  min-height: 40px;
  height: auto;
  overflow-y: hidden;
`;
