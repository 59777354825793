import { headers } from '../misc/helpers';
import { getBaseUrlForFetching } from '../../App';
import { SharedImage, UserDetails } from '../../types';
import { useCacheContext } from '../../context/CacheContext';

interface Returns {
  userDetails?: UserDetails;
  sharedImages?: SharedImage[];
}

const useGetUsersPublicDataWithId = () => {
  const { publicUserProfileCache, setPublicUserProfileCache } =
    useCacheContext();

  const fetchData = async (userId?: string): Promise<Returns> => {
    if (!userId) {
      return {};
    }

    // Check if the user data is already in the cache
    const cachedUserDetails = publicUserProfileCache[userId];
    if (cachedUserDetails) {
      return cachedUserDetails;
    }

    const functionName = 'getUsersPublicDataWithId';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          userId,
        }),
      });

      if (!response.ok) {
        console.error('Error fetching user details:', response);
        return {};
      }

      const data = await response.json();

      // Update the cache with the fetched data
      setPublicUserProfileCache((prev) => ({
        ...prev,
        [userId]: data,
      }));

      return data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return {};
    }
  };

  return fetchData;
};

export default useGetUsersPublicDataWithId;
