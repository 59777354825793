import styled from "styled-components";
import { desktopMediaQuery } from "../../styleHelpers";
import { StyledButton } from "./StyledButton";

export const SecondaryButton = styled(StyledButton)`
  border: none;
  font-size: 14px;

  ${desktopMediaQuery} {
    &:hover {
      margin-bottom: 0;
      text-decoration: underline;
    }
  }
`;
