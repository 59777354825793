import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { StyledButton } from '../common/StyledButton';
import TransitioningModal from './TransitioningModal';
import { SecondaryButton } from '../common/SecondaryButton';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useModalsContext } from '../../context/ModalsContextProvider';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const HintToRegisterModal = ({ showModal, setShowModal }: I) => {
  const { setSpeedMode } = useImageGenerationSettingsContext();
  const {
    setAuthModalVariant,
    setIsAuthModalOpen,
    setHasClosedHintToRegisterModal,
  } = useModalsContext();
  const handleClose = () => {
    setHasClosedHintToRegisterModal(true);
    setSpeedMode('adTurbo');
    setShowModal(false);
  };

  const handleTakeToSignup = () => {
    setAuthModalVariant('register');
    setIsAuthModalOpen(true);
    handleClose();
  };

  const handleNoThanks = async () => {
    handleClose();
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '36px',
          width: '75%',
          maxWidth: '650px',
          maxHeight: '75vh',
          overflow: 'auto',
          margin: 'auto',
          marginTop: '2vh',
          paddingTop: '40px',
          paddingBottom: '40px',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerForVerifying>
        <TitleTextForSignup>
          Sign Up for Faster Generation Speed ⚡
        </TitleTextForSignup>

        <ModalTextForVerifyEmail>
          ✅ Logged in: Generate in ~14sec, no ads
        </ModalTextForVerifyEmail>
        <ModalTextForVerifyEmail>
          ⏱ Signup in 30 seconds
        </ModalTextForVerifyEmail>
        <ModalTextForVerifyEmail>
          💾 Save your Generations
        </ModalTextForVerifyEmail>
        <ModalTextForVerifyEmail>🤗 Join the community</ModalTextForVerifyEmail>
        <ModalTextForVerifyEmail>👥 Keep Same Face</ModalTextForVerifyEmail>
        <ModalTextForVerifyEmail>
          🖼️ Upload Reference Image
        </ModalTextForVerifyEmail>

        <BottomButtons>
          <SecondaryButton onClick={handleNoThanks}>
            Continue as guest
          </SecondaryButton>
          <OKButton onClick={handleTakeToSignup}>Sign up</OKButton>
        </BottomButtons>
      </ContainerForVerifying>
    </Modal>
  );
};

const BottomButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  // center
  width: 100%;
  justify-content: center;
`;

const ContainerForVerifying = styled.div`
  display: flex;
  flex-direction: column;
  // align start

  align-self: center;
  text-align: left;
`;

const OKButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-height: 70vh;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 20px;
`;

const TitleTextForSignup = styled(TitleText)`
  margin-bottom: 16px;
  font-size: 22px;
  text-align: left;
`;

const ModalTextForVerifyEmail = styled(ModalText)`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  text-align: left;
  align-items: center;
`;

export default HintToRegisterModal;
