import { FiDelete } from "react-icons/fi";
import styled from "styled-components";
import { sharedIconStyle } from "./IconButtonStuff";

export const ClearInputIcon = styled(FiDelete)`
  ${sharedIconStyle}
  margin-left: 12px;
  margin-top: 2px;
  margin-right: 2px;
  font-size: 20px;
`;
