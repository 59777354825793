import { IoShuffleOutline } from "react-icons/io5";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants";
import { IconButton } from "./IconButtonInFeed";

export const RemixButton = () => (
  <RemixIconButton>
    <IoShuffleOutline color={PRIMARY_COLOR} size={36} />
    <RemixText>Remix</RemixText>
  </RemixIconButton>
);

const RemixIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const RemixText = styled.div`
  font-size: 12px;
  // not totally white, grayish maybe
  color: #e0e0e0;
`;
