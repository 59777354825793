import styled from "styled-components";
import { DiscordLogo, RedditLogo } from "../../components/common/TopBar/SocialLogos";
import { DESKTOP_WIDTH } from "../../constants";

const NeedSupport = () => (
    <NeedSupportContainer>
      Need help? Email:{' '}
      <span>
        <u>support@onlyfakes.app</u>
      </span>
      or
      <SocialsContainer>
        <DiscordLogo /> <RedditLogo />
      </SocialsContainer>
    </NeedSupportContainer>
  );

  export default NeedSupport;
  
  const NeedSupportContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-bottom: 16px;
    font-size: 12px;
    @media (max-width: ${DESKTOP_WIDTH}px) {
      padding-bottom: 100px;
    }
  `;
  
  const SocialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;
    justify-content: center;
  `;