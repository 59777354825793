import React from "react";
import styled from "styled-components";

interface P {
  milliseconds: number;
}

const TimeLeft: React.FC<P> = ({ milliseconds }) => {
  const getTimeString = (milliseconds: number) => {
    if (milliseconds < 1000) return "00:01";
    return new Date(milliseconds).toISOString()?.substr(14, 5);
  };
  const timeLeftString = getTimeString(milliseconds);
  return <TimeLeftText>{timeLeftString}</TimeLeftText>;
};

export default TimeLeft;

const TimeLeftText = styled.div`
  position: absolute;
  left: 40px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
`;
