import styled from 'styled-components';
import FeedImageCard from './FeedImageCard';
import SeeMoreImages from './SeeMoreImages';
import { SmallStyledButton } from '../../../components/common/StyledButton';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useImagesContext } from '../../../context/ImagesContextProvider';
import useGetPostedImages from '../../../hooks/feed/useGetPostedImages';
import { useEffect, useState } from 'react';
import { SharedImage, FeedChannel } from '../../../types';
import LoadingAnimation from '../../../components/common/LoadingStuff/LoadingAnimation';

interface FeedImagePageProps {
  imageId: string;
  imageUrl: string | null;
}

const FeedImagePage = ({ imageId, imageUrl }: FeedImagePageProps) => {
  const { additionalImagesInShowMore } = useImagesContext();

  const handleClickSeeMoreImages = () => {
    const seeMoreImages = document.getElementById('seeMoreImages');
    seeMoreImages?.scrollIntoView({ behavior: 'smooth' });
  };

  const [loading, setLoading] = useState(false);

  const [imagesToDisplay, setImagesToDisplay] = useState<SharedImage[]>([]);
  const getPostedImages = useGetPostedImages();

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      const sort = 'hot';
      const channelName = 'all';
      const channelList: FeedChannel[] = [];
      const images = await getPostedImages({
        sort,
        channelName,
        channelList,
      });
      setImagesToDisplay(images);
      setLoading(false);
    };
    if (additionalImagesInShowMore.length === 0) {
      fetchImages();
    } else {
      setImagesToDisplay(additionalImagesInShowMore);
    }
  }, []);

  return (
    <Container>
      <FeedImageCard imageId={imageId} imageUrl={imageUrl} />

      {loading ? (
        <LoadingAnimation loading={true} loaderToChoose={1} />
      ) : (
        <>
          <SeeMoreImagesButton onClick={handleClickSeeMoreImages}>
            Browse More
          </SeeMoreImagesButton>

          <SeeMoreImages imagesToShow={imagesToDisplay} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 36px;
  padding-bottom: 104px;
`;

const SeeMoreImagesButton = styled(SmallStyledButton)`
  width: 60vw;
  margin-bottom: 8px;
  ${desktopMediaQuery} {
    max-width: 400px;
    margin-left: 10vw;
  }
`;

export default FeedImagePage;
