import TippyVanilla from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { BACKGROUND_COLOR, BORDER_COLOR, PRIMARY_COLOR } from "../../constants";
import styled from "styled-components";
import { AiOutlineQuestionCircle } from "react-icons/ai";

interface TooltipProps {
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => (
  <Tippy content={text}>
    <span>
      <AiOutlineQuestionCircle color={PRIMARY_COLOR} size="18px" />
    </span>
  </Tippy>
);

const Tippy = styled(TippyVanilla)`
  border: 0.5px solid ${BORDER_COLOR};
  background-color: ${BACKGROUND_COLOR};
  border-radius: 8px;
  padding: 4px;
`;

export default Tooltip;
