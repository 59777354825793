import styled from 'styled-components';
import { BACKGROUND_COLOR, PRIMARY_COLOR } from '../../constants';
import { StyledButton } from '../common/StyledButton';
import TransitioningModal from './TransitioningModal';

interface I {
  showModal: boolean;
}

const ServerIssuesModal = ({ showModal }: I) => {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 5,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '32px',
          width: '80%',
          maxWidth: '600px',
          margin: 'auto',
          marginTop: '200px',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      // click anywhere to refresh
      onRequestClose={handleRefresh}
    >
      <TitleText>Session expired, please refresh 🔄</TitleText>
      <ModalText>
        To combat significant bot farm attacks, we've teamed up with Cloudflare
        to introduce enhanced security measures. Please refresh the page and
        verify you're human.
      </ModalText>
      <ModalText>Please bear with us 🐻 </ModalText>
      <ModalText>Thanks for your patience 🙏</ModalText>
      <AcceptButton onClick={handleRefresh}>Refresh</AcceptButton>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 50px;
`;

const ModalText = styled.div`
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default ServerIssuesModal;
