import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FaArrowLeft } from 'react-icons/fa6';
import useGetUsersPublicDataWithId from '../../../hooks/feed/useGetUsersPublicDataWithId';
import { UserDetails, SharedImage } from '../../../types';
import SharedImagesGrid from '../../../components/ImageStuff/ImageGrid/SharedImagesGrid';
import ProfileDetailsTopBar from './ProfileDetailsTopBar';
import SortDropdown from '../../../components/ImageStuff/ImageGrid/SortDropdown';
import { sortImages } from '../../../components/ImageStuff/ImageGrid/imageGridHelpers';
import { useUpdateSetSortyByInProfile } from '../../../localStorage/sortSettingsInProfilesStorage';

const ProfileDetail: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const [sharedImages, setSharedImages] = useState<SharedImage[]>([]);

  const getUsersPublicDataWithId = useGetUsersPublicDataWithId();
  useEffect(() => {
    const fetchData = async () => {
      const { userDetails, sharedImages } = await getUsersPublicDataWithId(
        userId,
      );
      if (userDetails) {
        setUserDetails(userDetails);
      }
      if (sharedImages) {
        setSharedImages(sharedImages);
      }
    };
    fetchData();
  }, [userId]);

  const sortByOptions = ['Latest', 'Best', 'Oldest'];
  const [sortBy, setSortBy] = useState('Best');

  useUpdateSetSortyByInProfile(setSortBy);

  const sortedImages = sortImages(sharedImages, sortBy);
  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} />
      <ProfileDetailsTopBar
        userDetails={userDetails}
        sharedImages={sharedImages}
      />
      {sharedImages.length > 0 && (
        <ContainerForPostedImages>
          <SortDropdown
            sortBy={sortBy}
            setSortBy={setSortBy}
            options={sortByOptions}
          />
          <SharedImagesGrid sharedImages={sortedImages} />
        </ContainerForPostedImages>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;

  ${desktopMediaQuery} {
    max-width: 80vw;
    margin-left: 15vw;
  }
`;

const BackButton = styled(FaArrowLeft)`
  position: absolute;
  top: 68px;
  left: 16px;
  font-size: 22px;
  ${desktopMediaQuery} {
    top: 120px;
    left: 380px;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }
`;

const ContainerForPostedImages = styled.div`
  margin-top: 8px;
`;

export default ProfileDetail;
