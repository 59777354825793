import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  GOLD_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../../constants';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import { GenericSetState, LoggedInUser } from '../../../../types';
import { StyledButton } from '../../../common/StyledButton';
import { CloseButtonInModal } from '../../../common/CloseButtonInModal';
import TransitioningModal from '../../TransitioningModal';
import { BiCoin } from 'react-icons/bi';
import {
  getAmountOfCredits,
  getAmountToClaim,
  getTimeUntilNextFreeCredits,
} from '../../../common/TopBar/helpers';
import useClaimFreeCredits from '../../../../hooks/userHandling/useClaimFreeCredits';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import initializeFingerprint from '../../../../utils/initializeFingerprint';
import LoadingIndicator from '../../../common/LoadingIndicator';
import UnclaimedReferralsSection from './UnclaimedReferralsSection';
import EarnReferralCreditsSection from './EarnReferralCreditsSection';
import { useImageGenerationSettingsContext } from '../../../../context/ImageGenerationSettingsProvider';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

export const addCreditsToUserState = (
  amount: number,
  setLoggedInUser: GenericSetState<LoggedInUser | null>,
) => {
  // @ts-ignore
  setLoggedInUser((prev) => {
    if (!prev) return;
    const currentCredits = prev.credits ?? 0;
    const lastClaimedFreeCredits = new Date();
    return {
      ...prev,
      credits: currentCredits + amount,
      lastClaimedFreeCredits,
    };
  });
};

const FreeCreditsModal = ({ showModal, setShowModal }: I) => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { setSpeedMode } = useImageGenerationSettingsContext();
  const claimFreeCredits = useClaimFreeCredits();
  const [loading, setLoading] = useState(false);

  if (!loggedInUser) return null;

  const handleClose = () => {
    setShowModal(false);
  };

  const [fingerprint, setFingerprint] = useState<string>('');
  useEffect(() => {
    const init = async () => {
      const f = await initializeFingerprint();
      if (!f) return;
      setFingerprint(f);
    };
    init();
  }, []);

  const handleClaimFreeCredits = async () => {
    setLoading(true);
    const userId = loggedInUser?.id;
    if (!userId) {
      alert('You must be logged in to claim credits');
      return;
    }

    const result = await claimFreeCredits(fingerprint);
    setLoading(false);
    if (result.status === 420) {
      toast.error(
        'Duplicate user detected, if this is a mistake please contact support@onlyfakes.app',
      );
      handleClose();
      return;
    }
    if (result.status !== 200) {
      toast.error('Something went wrong');
      handleClose();
      return;
    }
    addCreditsToUserState(amountToClaim, setLoggedInUser);

    setSpeedMode('turbo');
    toast.success('Enjoy the free credits ❤️');
  };

  const amountOfCredits = getAmountOfCredits(loggedInUser);

  const amountToClaim = getAmountToClaim(loggedInUser);

  const [timeUntil, setTimeUntil] = useState<string>('00:00:00');
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeUntil(getTimeUntilNextFreeCredits(loggedInUser));
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [loggedInUser]); // Dependency array ensures effect runs when loggedInUser changes

  // this shouldn't happen; it's a string only if Gold, and if Gold this shouldn't be shown in the first place
  if (typeof amountOfCredits === 'string') return null;

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '32px',
          paddingBottom: '48px',
          width: '80%',
          maxWidth: '400px',
          margin: 'auto',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
      <CurrentCredits amountOfCredits={amountOfCredits} />
      <Content
        handleClaimFreeCredits={handleClaimFreeCredits}
        timeUntil={timeUntil}
        amountToClaim={amountToClaim}
        loading={loading}
        unclaimedReferrals={loggedInUser?.unclaimedReferrals}
      />
    </Modal>
  );
};

const CurrentCredits = ({ amountOfCredits }: { amountOfCredits: number }) => (
  <CurrentCreditsContainer>
    <b>Credits:</b>
    <BiCoin size={20} color={GOLD_COLOR} style={{ marginLeft: 8 }} />
    {'x '}
    {amountOfCredits}
  </CurrentCreditsContainer>
);

const CurrentCreditsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

interface ContentProps {
  handleClaimFreeCredits: () => void;
  timeUntil: string;
  amountToClaim: number;
  loading: boolean;
  unclaimedReferrals?: number;
}

const Content = ({
  handleClaimFreeCredits,
  timeUntil,
  amountToClaim,
  loading,
  unclaimedReferrals,
}: ContentProps) => {
  const {loggedInUser} = useLoggedInUserContext();
  const isAllowedToClaim = timeUntil === '00:00:00';

  const ref = useRef<HTMLDivElement>(null);
  const clickHereFor1FreeCredit = () => {
    if (!ref.current) return;
    const script = document.createElement('script');
    script.setAttribute('data-cfasync', 'false');
    script.type = 'text/javascript';
    script.src =
      '//childlessporcupinevaluables.com/t/9/fret/meow4/2036447/f5be198f.js';

    ref.current.appendChild(script);

    setTimeout(() => {
      document.body.removeChild(script);
    }, 20000); // Adjust the delay as needed
  };

  const showFree = loggedInUser?.username === 'malossi';

  return (
    <>
      <ModalText ref={ref}>
        <p>10 free credits every day 🎁</p>
        <p>Time until next free credits:</p>
        <TimeUntil>{timeUntil}</TimeUntil>
      </ModalText>
      {showFree && (
        <ClickHereToGetFreeCredits onClick={clickHereFor1FreeCredit}>
          Click here for 1 free credit
        </ClickHereToGetFreeCredits>
      )}
      {isAllowedToClaim && (
        <GetFreeCreditsSection
          handleClaimFreeCredits={handleClaimFreeCredits}
          loading={loading}
          amountToClaim={amountToClaim}
        />
      )}

      <UnclaimedReferralsSection unclaimedReferrals={unclaimedReferrals} />

      <EarnReferralCreditsSection />
    </>
  );
};

const ClaimFreeCredits = styled(StyledButton)`
  border-color: ${GOLD_COLOR};
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
`;

const ClickHereToGetFreeCredits = styled(StyledButton)`
  margin-bottom: 16px;
  font-size: 14px;
`;

interface GetFreeCreditsSectionProps {
  handleClaimFreeCredits: () => void;
  loading: boolean;
  amountToClaim: number;
}

const GetFreeCreditsSection: React.FC<GetFreeCreditsSectionProps> = ({
  handleClaimFreeCredits,
  loading,
  amountToClaim,
}) => (
  <ClaimFreeCredits onClick={handleClaimFreeCredits} disabled={loading}>
    {loading ? (
      <LoadingIndicator />
    ) : (
      <>
        Claim free credits: {amountToClaim}
        <BiCoin size={18} color={GOLD_COLOR} />
      </>
    )}
  </ClaimFreeCredits>
);

const Modal = styled(TransitioningModal)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  max-height: 90vh;
  overflow-y: auto;
`;

const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
  color: ${PRIMARY_TEXT_COLOR};
`;

const TimeUntil = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export default FreeCreditsModal;
