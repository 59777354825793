import React, { useEffect, useState } from 'react';
import { BlogPost } from '../../types';
import styled from 'styled-components';
import NotFound from '../../components/common/NotFound';
import { Link, useParams } from 'react-router-dom';
import { blogPosts } from './blogPosts';
import {
  BACKGROUND_COLOR,
  FEED_BASE_URL,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import useFeedChannels from '../../hooks/feed/useFeedChannels';
import { scrollToTop } from '../../hooks/misc/helpers';
import TopBarInLandingPage from '../../components/common/TopBar/TopBarInLandingPage';
import { desktopMediaQuery } from '../../styleHelpers';

const IndividualBlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const post: BlogPost | undefined = slug
    ? blogPosts.find((p) => p.slug === slug)
    : undefined;

  if (!post) {
    return <NotFound />;
  }

  document.title = `${post.title} | OnlyFakes app`; // Dynamic title
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute('content', post.metaDescription);

  const isAllChannelsPost = slug === 'browse-ai-generated-nsfw-images';

  const isIndividualChannelPost = post.channelName !== undefined;

  const { fetchFeedChannels } = useFeedChannels();
  const [channelNames, setChannelNames] = useState<string[]>([]);
  const [channelImage, setChannelImage] = useState<string | null>(null);

  useEffect(() => {
    scrollToTop();
    const fetchChannels = async () => {
      const channels = await fetchFeedChannels();
      channels && setChannelNames(channels.map((channel) => channel.name));
      if (isIndividualChannelPost && channels) {
        console.log('here');
        const chosenChannel = channels.find(
          (channel) => channel.name === post.channelName,
        );
        if (!chosenChannel?.cardImageUrl) return;
        console.log({ channelImage });
        setChannelImage(chosenChannel.cardImageUrl);
      }
    };

    (isAllChannelsPost || isIndividualChannelPost) && fetchChannels();
  }, [post.slug]);

  const getRandomBlogLinks = (blogPosts: BlogPost[]) =>
    blogPosts
      .filter((blogPost) => blogPost.slug !== slug)
      .sort(() => 0.5 - Math.random())
      .slice(0, 5);

  const randomThreeBlogs = getRandomBlogLinks(blogPosts);

  return (
    <Container>
      <TopBarInLandingPage />
      {channelImage && (
        <img
          src={channelImage}
          alt={`Image from channel ${post.channelName}`}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: post.content }} />

      {isAllChannelsPost && (
        <ChannelList>
          {channelNames.map((channelName) => (
            <a href={`${FEED_BASE_URL}/${channelName}`} key={channelName}>
              #{channelName}
            </a>
          ))}
        </ChannelList>
      )}

      <RelatedBlogsContainer>
        <h2>Related Blogs</h2>
        {randomThreeBlogs.map((blog) => (
          <RelatedBlog key={blog.slug} to={`/blog/${blog.slug}`}>
            {blog.title}
          </RelatedBlog>
        ))}
      </RelatedBlogsContainer>

      <PostDate>{new Date(post.date).toLocaleDateString()}</PostDate>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  color: ${PRIMARY_TEXT_COLOR};
  line-height: 1.6;

  img {
    max-height: 512px;
    max-width: 90vw;
  }

  a {
    // color when already clicked/visited is PRIMARY_COLOR
    color: ${PRIMARY_TEXT_COLOR};
    &:visited {
      color: ${PRIMARY_COLOR};
    }
  }

  #cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    border: 1px solid ${PRIMARY_COLOR};
    color: ${PRIMARY_COLOR};
    margin-top: 10px;
    color: ${PRIMARY_TEXT_COLOR};
    background-color: ${BACKGROUND_COLOR};
    ${desktopMediaQuery} {
      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        transition: transform 0.2s;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    gap: 8px;

    width: 100%;

    border-width: 2px;

    ${desktopMediaQuery} {
      max-width: 500px;
      font-size: 20px;
      padding: 12px;
    }
  }
`;

const PostDate = styled.span`
  display: block;
  color: ${SECONDARY_TEXT_COLOR};
  font-size: 0.9em;
  margin-top: 40px;
`;

const ChannelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  a {
    color: ${PRIMARY_TEXT_COLOR};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const RelatedBlogsContainer = styled.div`
  margin-top: 40px;
`;

const RelatedBlog = styled(Link)`
  display: block;
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  margin-bottom: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

export default IndividualBlogPost;
