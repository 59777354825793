import { waitSeconds } from '../../components/helpers';

export const headers = {
  'Content-type': 'Application/json',
  'Access-Control-Allow-Origin': '*',
};

export const scrollToTop = async ({ topValue }: { topValue?: number } = {}) => {
  await waitSeconds(0.2);
  window.scrollTo({ top: topValue || 0, behavior: 'smooth' });
};
