import React from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import BlogPostCard from './BlogPostCard';
import { blogPosts } from './blogPosts';
import { HOME_PATH } from '../../pathNames';
import { useNavigate } from 'react-router-dom';
import TopBarInLandingPage from '../../components/common/TopBar/TopBarInLandingPage';
import { StyledButton } from '../../components/common/StyledButton';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaArrowRight } from 'react-icons/fa6';

const BlogPostsPage: React.FC = () => {
  const navigate = useNavigate();
  const navToApp = () => {
    navigate(HOME_PATH);
  };

  return (
    <Container>
      <TopBarInLandingPage />
      <TopPartContainer>
        <Title>Blog</Title>

        <GoToGeneratorButton onClick={navToApp}>
          Go to Image Generator <FaArrowRight />
        </GoToGeneratorButton>
      </TopPartContainer>
      <PostsList>
        {blogPosts.map((post) => (
          <BlogPostCard
            key={post.slug}
            title={post.title}
            excerpt={post.excerpt}
            date={post.date}
            slug={post.slug}
            content={post.content}
            metaDescription={post.metaDescription}
          />
        ))}
      </PostsList>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${BACKGROUND_COLOR};
  padding: 20px;
  min-height: 100vh;
`;

const TopPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

const Title = styled.h1`
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 40px;
`;

const GoToGeneratorButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 36px;

  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  gap: 8px;

  width: 100%;

  border-width: 2px;

  ${desktopMediaQuery} {
    max-width: 500px;
    font-size: 20px;
    padding: 12px;
  }
`;

const PostsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export default BlogPostsPage;
