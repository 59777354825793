import { MdAddToHomeScreen } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { ButtonWithIcon, OptionContainer } from './TopBarMenu';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const InstallAppButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const isIos = () => {
    if (/iPhone|iPad|iPod|Mac/i.test(navigator.userAgent)) {
      return true;
    }
    return false;
  };

  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };

  const iOsAlert = () =>
    alert(
      'To add the app to your iOS homescreen: Open the 📤Share menu and select "Add to Home Screen"',
    );

  const handleClick = () => {
    if (isIos()) {
      iOsAlert();
      return;
    }

    console.log({ deferredPrompt });
    if (deferredPrompt) {
      // Show the prompt
      deferredPrompt.prompt();

      // Wait for the user to respond
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted');
        }
        setDeferredPrompt(null);
      });
    } else {
      if (isIos()) {
        iOsAlert();
      } else if (isAndroid()) {
        alert(
          'Tap the menu icon (3 dots in upper right-hand corner) and choose "Add to home screen"',
        );
      } else {
        toast.error('Something went wrong, sorry!');
      }
    }
  };

  return (
    <OptionContainer onClick={handleClick}>
      <ButtonWithIcon>
        <Container>
          <MdAddToHomeScreen size={21} />
          Get the App
        </Container>
      </ButtonWithIcon>
    </OptionContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -2px;
`;

export default InstallAppButton;
