import { SharedImage } from '../../../types';

export const sortImages = (images: SharedImage[], sortBy: string) => {
  const sortedImages = [...images];
  switch (sortBy) {
    case 'Latest':
      return sortedImages.sort(
        (a, b) =>
          new Date(b.sharedAt).getTime() - new Date(a.sharedAt).getTime(),
      );
    case 'Best':
      return sortedImages.sort((a, b) => b.amountOfRemixes - a.amountOfRemixes);
    case 'Oldest':
      return sortedImages.sort(
        (a, b) =>
          new Date(a.sharedAt).getTime() - new Date(b.sharedAt).getTime(),
      );
    default:
      return sortedImages;
  }
};
