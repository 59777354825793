import { useEffect } from 'react';
import { getIsOnDesktop } from '../../styleHelpers';

const requestFullScreen = () => {
  const elem = document.documentElement;

  if (document.fullscreenElement) {
    console.log('Already in fullscreen mode');
    return; // Do nothing if already in fullscreen
  }

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  }
};

// Trigger fullscreen on user interaction
const handleUserInteraction = () => {
  requestFullScreen();
  // Remove the event listener after triggering fullscreen to prevent repeated calls
  console.log('remove event listeners');
  document.removeEventListener('click', handleUserInteraction);
  document.removeEventListener('keydown', handleUserInteraction);
  document.removeEventListener('touchstart', handleUserInteraction);
};

// Attach listeners for different types of interactions
const initializeFullScreenTrigger = () => {
  document.addEventListener('click', handleUserInteraction);
  document.addEventListener('keydown', handleUserInteraction); // In case of keyboard interactions
  document.addEventListener('touchstart', handleUserInteraction); // For mobile touch interactions
};

// Call this function when the page loads to start listening for interactions

const useInitializeFullScreenTrigger = () => {
  useEffect(() => {
    const isDesktop = getIsOnDesktop();
    if (isDesktop) return;

    const disable = true;
    if (disable) return;
    initializeFullScreenTrigger();
  }, []);
};

export default useInitializeFullScreenTrigger;
