class FirstTimeUserStorage {
  private storageKey: string;

  constructor() {
    this.storageKey = 'hasCheckedForFirstTimeUser';
  }

  hasChecked(): boolean {
    const value = localStorage.getItem(this.storageKey);
    return value === 'true';
  }

  setChecked(): void {
    localStorage.setItem(this.storageKey, 'true');
  }

  clearStorage(): void {
    localStorage.removeItem(this.storageKey);
  }
}

export default FirstTimeUserStorage;
