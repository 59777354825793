import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { useImagesContext } from "../../context/ImagesContextProvider";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { FakeImage } from "../../types";

interface Returns {
  status: number;
}
const headers = {
  "Content-type": "Application/json",
};

const useRemoveImageFromSaved = () => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const [{ token }] = useCookies(["token"]);

  const { savedImages, setSavedImages } = useImagesContext();
  const removeImageFromSaved = async (image: FakeImage): Promise<Returns> => {
    if (!loggedInUser || !token) {
      return { status: 401 };
    }

    const newSavedImages = savedImages.filter(
      (savedImage) => savedImage.trackId !== image.trackId
    );

    // remove trackId from loggedInUser.savedImageTrackIds
    setLoggedInUser({
      ...loggedInUser,
      // @ts-ignore we know it's not undefined here so can safely ignore
      savedImageTrackIds: loggedInUser.savedImageTrackIds.filter(
        (trackId) => trackId !== image.trackId
      ),
    });

    setSavedImages(newSavedImages);

    const functionName = "removeImageFromSaved";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const { status } = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ image, token }),
      headers,
    });

    return { status };
  };
  return removeImageFromSaved;
};

export default useRemoveImageFromSaved;
