import styled from "styled-components";
import { GenericSetState, EngineType, Size } from "../../../types";
import LockSeedCheckbox from "./FreezeSeedCheckbox";
import ShapeDropdown from "./ShapeDropdown";
import CFGInputBox from "./CFGInputBox";
import SeedInputBox from "./SeedInputBox";
import { desktopMediaQuery } from "../../../styleHelpers";
import EngineSelector from "../EngineSelector/EngineSelector";

interface P {
  seed: number;
  setSeed: GenericSetState<number>;
  size: Size;
  handleSetSize: (size: Size) => void;
  isSeedFrozen: boolean;
  setIsSeedFrozen: GenericSetState<boolean>;
  cfg: number;
  setCfg: GenericSetState<number>;
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
}

const AdvancedOptions = ({
  seed,
  setSeed,
  size,
  handleSetSize,
  isSeedFrozen,
  setIsSeedFrozen,
  cfg,
  setCfg,
  engine,
  setEngine,
}: P) => {
  return (
    <Container>
      <ShapeDropdown size={size} setSize={handleSetSize} />
      <EmptyDiv />
      <EngineSelector engine={engine} setEngine={setEngine} />
      <EmptyDiv />
      <SeedContainer>
        <SeedInputBox seed={seed} setSeed={setSeed} />
        <LockSeedCheckbox
          isSeedFrozen={isSeedFrozen}
          setIsSeedFrozen={setIsSeedFrozen}
        />
      </SeedContainer>
      <EmptyDiv />
      <EmptyDiv />
      <CFGInputBox cfg={cfg} setCfg={setCfg} />
      <EmptyDiv />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${desktopMediaQuery} {
    width: 25vw;
  }
`;

const SeedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-left: 14px;

  max-width: 90vw;
  max-width: 310px;
`;

const EmptyDiv = styled.div`
  height: 1rem;
`;

export default AdvancedOptions;
