import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants";
import { LibraryTabName } from "../../types";
import { LIBRARY_PATH } from "../../pathNames";
import { useNavigate } from "react-router-dom";

interface TopBarProps {
  activeTab: LibraryTabName;
  handleSetActiveTab: (tab: LibraryTabName) => void;
}
const LibraryTopBar = ({ activeTab, handleSetActiveTab }: TopBarProps) => {
  const navigate = useNavigate();
  const handleClick = (tab: LibraryTabName) => {
    navigate(`${LIBRARY_PATH}/${tab}`);
    handleSetActiveTab(tab);
  };

  return (
    <TopBarContainer>
      <TopBarTab
        active={activeTab === "history"}
        onClick={() => handleClick("history")}
      >
        History
      </TopBarTab>
      <TopBarTab
        active={activeTab === "saved"}
        onClick={() => handleClick("saved")}
      >
        Saved
      </TopBarTab>
      <TopBarTab
        active={activeTab === "folders"}
        onClick={() => handleClick("folders")}
      >
        Folders
      </TopBarTab>
    </TopBarContainer>
  );
};

const TopBarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
  margin-top: 16px;
`;

interface TopBarTabProps {
  active: boolean;
}
const TopBarTab = styled.div<TopBarTabProps>`
  padding: 8px 16px;
  color: ${({ active }) => active && PRIMARY_COLOR};
  border-bottom: ${({ active }) => active && `2px solid ${PRIMARY_COLOR}`};

  cursor: pointer;
`;

export default LibraryTopBar;
