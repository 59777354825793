import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import { BORDER_COLOR, PRIMARY_COLOR } from "../../../constants";
import { StyledButton } from "../../common/StyledButton";
import { desktopMediaQuery } from "../../../styleHelpers";

interface AI {
  handleClickAddNewFolder: () => void;
}

const AddButton = styled(StyledButton)`
  border-color: ${BORDER_COLOR};
  height: 40px;
  width: 275px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${desktopMediaQuery} {
    &:hover {
      border-width: 1px;
      margin-bottom: 0;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;
const AddNewFolderButton = ({ handleClickAddNewFolder }: AI) => {
  return (
    <AddButton onClick={handleClickAddNewFolder}>
      <AiOutlinePlus />
      Add a new folder
    </AddButton>
  );
};

export default AddNewFolderButton;
