import { createContext, useContext, useState } from 'react';
import { GenericSetState } from '../types';

interface IModalsContext {
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: GenericSetState<boolean>;
  authModalVariant: 'login' | 'register';
  setAuthModalVariant: GenericSetState<'login' | 'register'>;

  showHintToRegisterModal: boolean;
  setShowHintToRegisterModal: GenericSetState<boolean>;
  hasClosedHintToRegisterModal: boolean;
  setHasClosedHintToRegisterModal: GenericSetState<boolean>;
}

const defaultState: IModalsContext = {
  isAuthModalOpen: false,
  setIsAuthModalOpen: undefined as unknown as GenericSetState<boolean>,
  authModalVariant: 'login',
  setAuthModalVariant: undefined as unknown as GenericSetState<
    'login' | 'register'
  >,

  showHintToRegisterModal: false,
  setShowHintToRegisterModal: undefined as unknown as GenericSetState<boolean>,
  hasClosedHintToRegisterModal: false,
  setHasClosedHintToRegisterModal:
    undefined as unknown as GenericSetState<boolean>,
};

const ModalsContext = createContext<IModalsContext>(defaultState);

export const useModalsContext = () => useContext(ModalsContext);

// @ts-ignore children does actually exist, todo figure types?
const ModalsContextProvider = ({ children }) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [authModalVariant, setAuthModalVariant] = useState<
    'login' | 'register'
  >('login');

  const [showHintToRegisterModal, setShowHintToRegisterModal] = useState(false);
  const [hasClosedHintToRegisterModal, setHasClosedHintToRegisterModal] =
    useState(false);

  return (
    <ModalsContext.Provider
      value={{
        isAuthModalOpen,
        setIsAuthModalOpen,
        authModalVariant,
        setAuthModalVariant,

        showHintToRegisterModal,
        setShowHintToRegisterModal,
        hasClosedHintToRegisterModal,
        setHasClosedHintToRegisterModal
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsContextProvider;
