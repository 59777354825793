const Cookies = () => {
  const getCookie = (name: string): string | undefined => {
    const cookies = document.cookie;
    const token = cookies && cookies.split(`${name}=`)[1];
    return token;
  };

  const clearToken = () => {
    const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    const path = "path=/";
    document.cookie = `token=; ${expires}; ${path}`;
  };
  return {
    getCookie,
    clearToken,
  };
};

export default Cookies;
