import styled from "styled-components";
import { fadeIn } from "../ImageStuff/animations";

const BasicContainerWithFadeIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  width: 100%;
  animation: ${fadeIn} 1s ease-in-out;
`;

export default BasicContainerWithFadeIn;