import { createContext, useContext, useState } from 'react';
import {
  FakeImage,
  FolderInterface,
  GenericSetState,
  FeedChannel,
  SharedImage,
} from '../types';

interface IImagesContext {
  savedImages: FakeImage[];
  setSavedImages: GenericSetState<FakeImage[]>;

  hasFetchedSavedImages: boolean; // helper used to know if we should fetch saved images
  setHasFetchedSavedImages: GenericSetState<boolean>;

  sharedImages: SharedImage[];
  setSharedImages: GenericSetState<SharedImage[]>;

  selectedChannel: string | undefined;
  setSelectedChannel: GenericSetState<string | undefined>;

  selectedSortOption: string | undefined;
  setSelectedSortOption: GenericSetState<string | undefined>;

  // In feed
  selectedCategory: string | undefined;
  setSelectedCategory: GenericSetState<string | undefined>;

  // In feed
  selectedEngine: string | undefined;
  setSelectedEngine: GenericSetState<string | undefined>;

  // These are used as helpers to return the users back to previous place on SharedImagesGrid
  indexOfLastImage: number;
  setIndexOfLastImage: GenericSetState<number>;
  locationWhenLastImageClicked: string;
  setLocationWhenLastImageClicked: GenericSetState<string>;

  // Images that are shown in "show more" from the spot that user clicked the image open
  additionalImagesInShowMore: SharedImage[];
  setAdditionalImagesInShowMore: GenericSetState<SharedImage[]>;

  feedChannels: FeedChannel[];
  setFeedChannels: GenericSetState<FeedChannel[]>;

  failedTrackIds: string[];
  setFailedTrackIds: GenericSetState<string[]>;

  lastSharedDate: Date | null;
  setLastSharedDate: GenericSetState<Date | null>;

  editedImages: FakeImage[];
  setEditedImages: GenericSetState<FakeImage[]>;

  imageToBePosted: FakeImage;
  setImageToBePosted: GenericSetState<FakeImage>;
  albumToBePosted: FakeImage[];
  setAlbumToBePosted: GenericSetState<FakeImage[]>;
}

const defaultState = {
  savedImages: [],
  setSavedImages: undefined as unknown as GenericSetState<FakeImage[]>,

  hasFetchedSavedImages: false,
  setHasFetchedSavedImages: undefined as unknown as GenericSetState<boolean>,

  sharedImages: [],
  setSharedImages: undefined as unknown as GenericSetState<SharedImage[]>,

  selectedChannel: undefined,
  setSelectedChannel: undefined as unknown as GenericSetState<string | undefined>,

  selectedSortOption: undefined,
  setSelectedSortOption: undefined as unknown as GenericSetState<string | undefined>,

  selectedCategory: undefined,
  setSelectedCategory: undefined as unknown as GenericSetState<string | undefined>,
  selectedEngine: undefined,
  setSelectedEngine: undefined as unknown as GenericSetState<string | undefined>,

  indexOfLastImage: 0,
  setIndexOfLastImage: undefined as unknown as GenericSetState<number>,
  locationWhenLastImageClicked: '',
  setLocationWhenLastImageClicked:
    undefined as unknown as GenericSetState<string>,

  additionalImagesInShowMore: [],
  setAdditionalImagesInShowMore: undefined as unknown as GenericSetState<
    SharedImage[]
  >,

  feedChannels: [],
  setFeedChannels: undefined as unknown as GenericSetState<FeedChannel[]>,

  failedTrackIds: [],
  setFailedTrackIds: undefined as unknown as GenericSetState<string[]>,

  lastSharedDate: null,
  setLastSharedDate: undefined as unknown as GenericSetState<Date | null>,

  editedImages: [],
  setEditedImages: undefined as unknown as GenericSetState<FakeImage[]>,

  folders: [],
  setFolders: undefined as unknown as GenericSetState<FolderInterface[]>,

  imageToBePosted: {} as FakeImage,
  setImageToBePosted: undefined as unknown as GenericSetState<FakeImage>,
  albumToBePosted: {} as FakeImage[],
  setAlbumToBePosted: undefined as unknown as GenericSetState<FakeImage[]>,
};

const ImagesContext = createContext<IImagesContext>(defaultState);

export const useImagesContext = () => useContext<IImagesContext>(ImagesContext);

// @ts-ignore children does actually exist, todo figure types?
const ImagesContextProvider = ({ children }) => {
  const [savedImages, setSavedImages] = useState<FakeImage[]>([]);
  const [hasFetchedSavedImages, setHasFetchedSavedImages] =
    useState<boolean>(false);

  const [sharedImages, setSharedImages] = useState<SharedImage[]>([]);


  const [selectedChannel, setSelectedChannel] = useState<string | undefined>(
    undefined,
  );
  const [selectedSortOption, setSelectedSortOption] = useState<
    string | undefined
  >(undefined);


  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [selectedEngine, setSelectedEngine] = useState<string | undefined>(undefined);

  const [indexOfLastImage, setIndexOfLastImage] = useState<number>(0);
  const [locationWhenLastImageClicked, setLocationWhenLastImageClicked] =
    useState<string>('');

  const [additionalImagesInShowMore, setAdditionalImagesInShowMore] = useState<
    SharedImage[]
  >([]);

  const [feedChannels, setFeedChannels] = useState<FeedChannel[]>([]);

  const [failedTrackIds, setFailedTrackIds] = useState<string[]>([]);

  const [lastSharedDate, setLastSharedDate] = useState<Date | null>(null);

  const [editedImages, setEditedImages] = useState<FakeImage[]>([]);

  const [imageToBePosted, setImageToBePosted] = useState({} as FakeImage);
  const [albumToBePosted, setAlbumToBePosted] = useState({} as FakeImage[]); // Added in AddImagesModal

  return (
    <ImagesContext.Provider
      value={{
        savedImages,
        setSavedImages,

        hasFetchedSavedImages,
        setHasFetchedSavedImages,

        sharedImages,
        setSharedImages,

        selectedChannel,
        setSelectedChannel,

        selectedSortOption,
        setSelectedSortOption,

        selectedCategory,
        setSelectedCategory,

        selectedEngine,
        setSelectedEngine,

        indexOfLastImage,
        setIndexOfLastImage,
        locationWhenLastImageClicked,
        setLocationWhenLastImageClicked,

        additionalImagesInShowMore,
        setAdditionalImagesInShowMore,

        feedChannels,
        setFeedChannels,

        failedTrackIds,
        setFailedTrackIds,

        lastSharedDate,
        setLastSharedDate,

        editedImages,
        setEditedImages,

        imageToBePosted,
        setImageToBePosted,
        albumToBePosted,
        setAlbumToBePosted,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};

export default ImagesContextProvider;
