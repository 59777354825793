import {
  RingLoader,
  BounceLoader,
  ClipLoader,
  DotLoader,
  HashLoader,
  MoonLoader,
  RotateLoader,
  SquareLoader,
} from 'react-spinners';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';

interface P {
  loading: boolean;
  loaderToChoose: number;
  style?: React.CSSProperties;
}

const LoadingAnimation = ({ loading, loaderToChoose, style }: P) => {
  const size = 70;
  const { loggedInUser } = useLoggedInUserContext();

  const speedMultiplier =
    loggedInUser?.type === 'gold' || loggedInUser?.type === 'admin' ? 0.9 : 0.5;

  const renderLoader = () => {
    switch (loaderToChoose) {
      case 0:
        return (
          <RingLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 1:
        return (
          <BounceLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 2:
        return (
          <ClipLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 3:
        return (
          <DotLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 4:
        return (
          <HashLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 5:
        return (
          <MoonLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 6:
        return (
          <RotateLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      case 7:
        return (
          <SquareLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
      default:
        return (
          <RingLoader
            color={PRIMARY_COLOR}
            size={size}
            speedMultiplier={speedMultiplier}
          />
        );
    }
  };
  if (!loading) return null;
  return <Container style={style}>{renderLoader()}</Container>;
};

export default LoadingAnimation;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
