import React from 'react';
import styled from 'styled-components';
import { UserComment } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PATH } from '../../../pathNames';
import { desktopMediaQuery } from '../../../styleHelpers';
import { DEFAULT_PROFILE_IMAGE_URL } from '../../UserProfiles/UserAvatar';
import { ANON_NAME_TO_USE } from '../../../constants';

interface CommentsProps {
  comments: UserComment[];
}

const Comments = ({ comments }: CommentsProps) => {
  const renderTimestamp = (timestamp: string) => {
    const timestampDate = new Date(timestamp);

    const now = new Date();
    const diff = now.getTime() - timestampDate.getTime();
    const diffInMinutes = diff / 1000 / 60;
    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} minutes ago`;
    } else if (diffInMinutes < 60 * 24) {
      return `${Math.floor(diffInMinutes / 60)} hours ago`;
    } else {
      return `${Math.floor(diffInMinutes / 60 / 24)} days ago`;
    }
  };

  const navigate = useNavigate();
  const handleGoToProfile = (userId?: string) => {
    userId && navigate(`${PROFILE_PATH}/${userId}`);
  };
  return (
    <CommentsContainer>
      {comments && comments.length > 0 ? (
        comments.map((comment, index) => (
          <CommentContainer key={index}>
            <TopPartContainer
              onClick={() => handleGoToProfile(comment.user.id)}
            >
              <UserAvatar src={comment?.user?.profileImageUrl || DEFAULT_PROFILE_IMAGE_URL} />
              <UserName>{comment?.user?.displayName || ANON_NAME_TO_USE}</UserName>
              <Timestamp>
                {renderTimestamp(comment.timestamp as unknown as string)}
              </Timestamp>
            </TopPartContainer>

            <CommentText>{comment.text}</CommentText>
          </CommentContainer>
        ))
      ) : (
        <p>No comments, be the first one ❤️</p>
      )}
    </CommentsContainer>
  );
};

const CommentsContainer = styled.div`
  padding: 8px;
  margin: 0 auto;
  ${desktopMediaQuery} {
    font-size: 18px;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const TopPartContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  ${desktopMediaQuery} {
    width: 44px;
    height: 44px;
  }
  border-radius: 50%;
`;

const UserName = styled.div`
  font-weight: 600;
`;

const Timestamp = styled.div`
  color: grey;
  font-size: 12px;
  ${desktopMediaQuery} {
    font-size: 14px;
  }
`;

const CommentText = styled.div`
  margin-left: 40px;
  margin-top: -8px;
  font-size: 14px;
  ${desktopMediaQuery} {
    font-size: 18px;
    margin-left: 54px;
    margin-top: -12px;
  }
`;

export default Comments;
