import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../misc/helpers";

const useRenameFolder = () => {
  const [{ token }] = useCookies(["token"]);

  const renameFolder = async (folderId: string, newFolderName: string) => {
    if (!token || !folderId || !newFolderName) {
      console.log("Missing token, folder ID, or new folder name");
      return;
    }

    const functionName = "renameFolder";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body: JSON.stringify({ folderId, newFolderName, token }),
        headers,
      });

      if (response.status !== 200) {
        console.log(`Error renaming folder: ${folderId}`);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
    }
  };

  return renameFolder;
};

export default useRenameFolder;
