import { useCookies } from "react-cookie";
import { isPreviewing, isLocalhost } from "../../App";
import {
  BASE_URL_FOR_FUNCTIONS,
  PREVIEW_URL_FOR_FUNCTIONS as PREVIEW_URL_FOR_FUNCTIONS,
} from "../../constants";
import { useImagesContext } from "../../context/ImagesContextProvider";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { SharedImage } from "../../types";

const headers = {
  "Content-type": "Application/json",
};

const useRemoveImageFromShared = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const { setSharedImages } = useImagesContext();

  // TODO: this is actually "cookies" and inside is .token. The destructure is done in serverless function which is stupid.
  const [token] = useCookies(["token"]);

  const removeImageFromShared = async (
    image: SharedImage
  ): Promise<boolean> => {
    if (!loggedInUser) {
      return false;
    }

    //use alert to confrim if they wanna remove it
    const ok = window.confirm("Are you sure you want to remove this image?");
    if (!ok) {
      return false;
    }

    setSharedImages((sharedImages) =>
      sharedImages.filter(
        (sharedImage) => sharedImage.image.trackId !== image.image.trackId
      )
    );

    const functionName = "removeImageFromShared";
    let fetchUrl = isPreviewing
      ? `${PREVIEW_URL_FOR_FUNCTIONS}/${functionName}`
      : `${BASE_URL_FOR_FUNCTIONS}/${functionName}`;

    fetchUrl = isLocalhost ? `http://localhost:8080/${functionName}` : fetchUrl;

    const sharedImageId = image.id;

    const { status } = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ sharedImageId, token }),
      headers,
    });

    if (status !== 200) {
      return false
    }

    return true;
  };
  return removeImageFromShared;
};

export default useRemoveImageFromShared;
