import React from 'react';
import { SharedImage } from '../../types';
import styled from 'styled-components';
import { StyledButton } from '../../components/common/StyledButton';
import { MdSearch } from 'react-icons/md';
import HomeFeedCard from './HomeFeedCard';
import BasicContainerWithFadeIn from '../../components/common/BasicContainerWithFadeIn';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { FEED_PATH } from '../../pathNames';
import { useNavigate } from 'react-router-dom';
import { desktopMediaQuery } from '../../styleHelpers';

interface ImageFeedContentProps {
  imagesToShowIfBrowsingMore: SharedImage[];
  imagesToDisplay: SharedImage[];
  handleNavToFeedPage: () => void;
}

const ImageFeedContent: React.FC<ImageFeedContentProps> = ({
  imagesToShowIfBrowsingMore,
  imagesToDisplay,
  handleNavToFeedPage,
}) => {
  const navigate = useNavigate();
  const { setAdditionalImagesInShowMore } = useImagesContext();
  const HOW_MANY_TO_SHOW_AFTER_CLIKING = 100;

  const handleClick = (image: SharedImage, index: number) => {
    setAdditionalImagesInShowMore(
      imagesToShowIfBrowsingMore.slice(index + 1, index + HOW_MANY_TO_SHOW_AFTER_CLIKING),
    );

    const urlToNavigate = `${FEED_PATH}?id=${image.id}&imageUrl=${image.image.imageUrl}`;

    // FeedImagePage.tsx
    navigate(urlToNavigate);
  };

  return (
    <Container>
      <ImagesToDisplayContainer>
        {imagesToDisplay.map((image, index) => (
          <HomeFeedCard
            key={image.id}
            image={image}
            index={index}
            handleClick={handleClick}
          />
        ))}
      </ImagesToDisplayContainer>
      <ShowMoreImagesButton onClick={handleNavToFeedPage}>
        <MdSearch size={22} /> Explore more
      </ShowMoreImagesButton>
    </Container>
  );
};

const Container = styled(BasicContainerWithFadeIn)`
  display: flex;
  width: 100%;
  gap: 8px;

  margin-top: 32px;
`;

const ImagesToDisplayContainer = styled.div`
  ${desktopMediaQuery} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const ShowMoreImagesButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 4px;

  border-width: 2px;

  padding-left: 32px;
  padding-right: 32px;

  ${desktopMediaQuery} {
    font-size: 20px;
  }
`;

export default ImageFeedContent;
