import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../constants';
import { SharedImage } from '../../../types';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import Comments from './Comments';
import AddANewComment from './AddANewComment';
import { useState } from 'react';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
  image: SharedImage;
}

const AddImagesModal = ({ show, setShow, image }: I) => {
  const handleClose = () => {
    setShow(false);
  };

  const [commentsToRender, setCommentsToRender] = useState(image.comments);

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '800px',
          height: '86vh',
          margin: 'auto',
          marginTop: '1vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      Comments
      <CloseButtonInModal onClick={handleClose} />
      <CommentsContainer>
        <Comments comments={commentsToRender} />
      </CommentsContainer>
      <AddANewCommentContainer>
        <AddANewComment
          image={image}
          setCommentsToRender={setCommentsToRender}
        />
      </AddANewCommentContainer>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  height: 100%; // Ensure the modal takes full height
`;

const CommentsContainer = styled.div`
  flex-grow: 1; // Allow the comments section to grow and take available space
  overflow-y: auto; // Enable scrolling if comments overflow
  width: 100%;
`;

const AddANewCommentContainer = styled.div`
  width: 100%;
  margin-top: auto; // Push the AddANewComment to the bottom
`;

export default AddImagesModal;
