import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import useHandleClickOutside from '../../components/common/TopBar/useHandleClickOutside';
import {
  PRIMARY_TEXT_COLOR,
  LIGHT_GREY_COLOR,
  BACKGROUND_COLOR,
  BORDER_COLOR,
  LIGHTER_GREY_FOR_HOVER_EFFECT,
  PRIMARY_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';

interface CommonDropdownProps {
  options: string[];
  optionsToRender: string[];
  selectedOption: string;
  onOptionClick: (option: string) => void;
  buttonText?: string;
  customToggle?: () => void;
  customIsOpen?: boolean;
  showMenuOnLeft?: boolean;
}

const CommonDropdown: React.FC<CommonDropdownProps> = ({
  options,
  optionsToRender,
  selectedOption,
  onOptionClick,
  buttonText,
  customToggle,
  customIsOpen,
  showMenuOnLeft,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    if (customToggle) {
      customToggle();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: string) => {
    onOptionClick(option);
    setIsOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useHandleClickOutside([ref], () => setIsOpen(false));

  const handleOptionToShow = (option: string) => {
    const index = options.indexOf(option);
    return optionsToRender[index];
  };

  const showDropdown = customIsOpen !== undefined ? customIsOpen : isOpen;

  return (
    <Container ref={ref}>
      {!customToggle && (
        <Button onClick={handleToggle}>
          {buttonText} <IoIosArrowDown />
        </Button>
      )}
      {showDropdown && (
        <Dropdown showMenuOnLeft={showMenuOnLeft}>
          {options.map((option) => (
            <Option
              key={option}
              onClick={() => handleOptionClick(option)}
              isSelected={option === selectedOption}
            >
              {handleOptionToShow(option)}
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default CommonDropdown;

const Container = styled.div`
  position: relative;
  margin-top: 4px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;

  padding: 8px;
  padding-left: 16px;
  padding-right: 14px;
  gap: 2px;

  font-weight: 400;
  font-size: 16px;

  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${PRIMARY_COLOR};
    transition: border 0.2s;
  }
`;

const Dropdown = styled.div<{ showMenuOnLeft?: boolean }>`
  position: absolute;
  background-color: ${BACKGROUND_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  display: flex;
  flex-direction: column;
  width: ${({ showMenuOnLeft }) => (showMenuOnLeft ? '200px' : '100px')};
  margin-top: 5px;
  border: 0.5px solid ${BORDER_COLOR};
  border-radius: 2px;
  z-index: 10;

  ${({ showMenuOnLeft }) => (showMenuOnLeft ? 'right: 0;' : 'left: 0;')}

  max-height: 70vh;
  overflow-y: auto;
`;

const Option = styled.div<{ isSelected: boolean }>`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? LIGHTER_GREY_FOR_HOVER_EFFECT : 'transparent'};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  color: ${props => props.isSelected ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR};

  &:hover {
    background-color: ${LIGHTER_GREY_FOR_HOVER_EFFECT};
  }
`;
