class AlreadySeenImagesStorage {
  static getImageIds(): string[] {
    const alreadySeenImages = localStorage.getItem('alreadySeenImages');
    if (alreadySeenImages) {
      return JSON.parse(alreadySeenImages);
    }
    return [];
  }

  static addImageId(imageId: string): void {
    const alreadySeenImages = this.getImageIds();
    if (!alreadySeenImages.includes(imageId)) {
      alreadySeenImages.push(imageId);
      localStorage.setItem(
        'alreadySeenImages',
        JSON.stringify(alreadySeenImages),
      );
    }
  }

  static clearStorage(): void {
    localStorage.removeItem('alreadySeenImages');
  }
}

export default AlreadySeenImagesStorage;
