import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { SharedImage } from "../../types";

const useUpdateAmountOfRemixes = () => {
  const headers = {
    "Content-type": "Application/json",
  };
  const [{ token }] = useCookies(["token"]);

  const updateAmountOfRemixes = async (image: SharedImage): Promise<string> => {
    const functionName = "updateAmountOfRemixes";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ image, token }),
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const data = await response.json();

    return data.body;
  };
  return updateAmountOfRemixes;
};

export default useUpdateAmountOfRemixes;
