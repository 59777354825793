import { useCookies } from 'react-cookie';
import { customFetch } from '../customFetch';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { getRandomSeed } from '../../components/helpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { FakeImage } from '../../types';
import { scrollToTop } from '../misc/helpers';
import { v4 as uuidv4 } from 'uuid';

const useHandleSendFluxImageRequest = () => {
  const [{ token }] = useCookies(['token']);
  const {
    isSeedFrozen,
    setActiveImage,
    setImages,
    setEstimateGenerationReadyTimestamp,
    setGenerationStartedTimestamp,
    setLoading,
  } = useGeneralContext();
  const {
    userPrompt,
    negativePrompt,
    seedState: seed,
    setSeed,
    size,
    cfg,
    amountToGenerate,
    uploadedImageUrl,
  } = useImageGenerationSettingsContext();

  const getSeed = () => {
    if (isSeedFrozen) {
      if (seed > 4294967294) return getRandomSeed();
      if (seed === 0) return getRandomSeed();
      return seed as number;
    }

    const randomSeed = getRandomSeed();
    setSeed(randomSeed);
    return randomSeed;
  };

  const handleSendFluxImageRequest = async (): Promise<number> => {
    setLoading(true);
    const now = Date.now();
    setGenerationStartedTimestamp(now);
    const expectedWait = 20 * 1000;
    setEstimateGenerationReadyTimestamp(now + expectedWait);

    const functionName = 'generateXLHDImage';

    const imageRequestObject = {
      fullPrompt: userPrompt,
      negativePrompt: negativePrompt,
      seed: getSeed(),
      size,
      cfg,
      amountToGenerate,
      uploadedImageUrl,
    };

    const response = await customFetch(functionName, {
      imageRequestObject,
      token,
    });
    console.log(response);

    const { data } = response;

    if (!data.url) {
      console.error('Failed to generate image');
      setLoading(false);
      return 500;
    }

    const trackId = uuidv4();

    const imageToAdd: FakeImage = {
      imageUrl: data.url,
      trackId,
      guidanceScale: cfg,
      engine: {
        name: 'XXLHD',
        modelId: 'xxlhd',
        type: 'realistic',
        isOffline: false,
        isGoldOnly: false,
        isNew: false,
      },
      negativePrompt,
      prompt: userPrompt,
      seed: imageRequestObject.seed,
      height: Number(size.height),
      width: Number(size.width),
    };

    setImages((prevImages) => [...prevImages, imageToAdd]);
    setActiveImage(imageToAdd);

    scrollToTop();

    return 200;
  };

  return handleSendFluxImageRequest;
};

export default useHandleSendFluxImageRequest;
