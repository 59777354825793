import React from "react";

export const MultipleTabsDetectedComponent: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "80vw",
        height: "100vh",
        margin: "16px",
        fontSize: "24px",
      }}
    >
      Multiple tabs detected for image generation. Close extras to proceed.
    </div>
  );
};

import { useState, useEffect } from "react";

export const useMultipleTabsHandler = () => {
  const [showMultipleTabsError, setShowMultipleTabsError] = useState(false);
  const localStorage = window.localStorage;

  /**
   * Prevents users from having multiple tabs for CreatePhoto open
   */
  const handleMultipleTabs = () => {
    // if the landing page, return early
    if (window.location.pathname === "/") return;
    if (window.location.pathname !== "/app") return;
    localStorage.openPages = Date.now();
    window.addEventListener(
      "storage",
      function (e) {
        if (e.key === "openPages") {
          localStorage.pageAvailable = Date.now();
        }
        if (e.key === "pageAvailable") {
          setShowMultipleTabsError(true);
        }
      },
      false
    );
  };

  useEffect(() => {
    handleMultipleTabs();
  }, []);

  return showMultipleTabsError;
};
