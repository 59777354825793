import styled from "styled-components";
import LoadingAnimation from "../../components/common/LoadingStuff/LoadingAnimation";
import LoadingBar from "../../components/common/LoadingStuff/LoadingBar";
import { LIGHTER_GREY_COLOR } from "../../constants";

interface P {
  loading: boolean;
  loaderToChoose: number;
  progress: number;
  timeLeft: number;
  height: number;
}
const Loader = ({ loading, loaderToChoose, progress, timeLeft, height }: P) => (
  <LoadingContainer height={height}>
    <LoadingAnimation loading={loading} loaderToChoose={loaderToChoose} />
    <LoadingBar inInpaintingPage progress={progress} timeLeft={timeLeft} />
  </LoadingContainer>
);

const LoadingContainer = styled.div<{ height: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: ${(props) => props.height}px;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: ${LIGHTER_GREY_COLOR};
  pointer-events: none;
`;

export default Loader;
