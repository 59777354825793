import AlreadySeenImagesStorage from '../../localStorage/alreadySeenImagesStorage';
import { SharedImage } from '../../types';

export const filterImages = (
  images: SharedImage[],
  searchTerm: string,
): SharedImage[] => {
  if (searchTerm === '') {
    return images;
  }
  const searchWords = searchTerm.toLowerCase().split(/\s|,|\(|\)|:/);

  const filteredImages = images.filter((image) => {
    const prompt = image.image.prompt.replace(/\(|\)|,|:|\./g, ' ');
    const promptWords = prompt.toLowerCase().split(' ');

    return searchWords.every((word) => promptWords.includes(word));
  });

  return filteredImages;
};
/**
 * Filters an array of images based on the provided category name and engine name.
 *
 * @param {SharedImage[]} images - The array of images to filter.
 * @param {string | null} categoryName - The category name to filter by. If null or 'all', no category filter is applied.
 * @param {string | null} engineName - The engine name to filter by. If null, no engine filter is applied.
 * @returns {SharedImage[]} A filtered array of images. If no filters are applied, or if the category is 'all', returns the original array.
 */
export const filterImagesByButton = (
  images: SharedImage[],
  categoryName: string | undefined,
  engineName: string | undefined,
): SharedImage[] => {
  // Convert categoryName to lowercase if it exists
  const lowerCaseCategoryName = categoryName?.toLowerCase();

  // Return all images if no filters are applied or if category is 'all'
  if (
    (!lowerCaseCategoryName && !engineName) ||
    lowerCaseCategoryName === 'all'
  ) {
    return images;
  }

  // Filter by engine name if provided
  if (engineName) {
    console.log("filtering by engine now ")
    return images.filter((image) => image.image.engine.name === engineName);
  }

  // Filter by category if provided
  if (lowerCaseCategoryName) {
    return images.filter(
      (image) => image.image.engine.type === lowerCaseCategoryName,
    );
  }

  // If we somehow reach here, return all images
  return images;
};

/**
 * Filters an array of `SharedImage` objects to exclude images that have already been seen by the user.
 * This function is specifically designed for use with the 'hot' sorting parameter.
 *
 * @param sortFromParams The sorting parameter from the URL query. If not 'hot', the original array of images is returned without filtering.
 * @param images An array of `SharedImage` objects to be filtered.
 * @returns An array of `SharedImage` objects that excludes images already seen by the user, applicable only when `sortFromParams` is 'hot'.
 */
export const filterImagesByAlreadySeen = (
  images: SharedImage[],
  shouldFilterImages: boolean,
): SharedImage[] => {
  if (!shouldFilterImages) {
    return images;
  }

  const alreadySeenImages = AlreadySeenImagesStorage.getImageIds();

  return images.filter((image) => !alreadySeenImages.includes(image.id));
};
