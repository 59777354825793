import styled from "styled-components";
import { ICON_COLOR, PRIMARY_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

// TODO: refactor so takes in image (not onclick)
export const IconButtonContainer = styled.div`
  cursor: pointer;
  color: ${ICON_COLOR};
  ${desktopMediaQuery} {
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 4px;

  font-weight: 600;
`;

export const sharedIconStyle = `
  cursor: pointer;
  font-size: 24px;
  ${desktopMediaQuery} {
  &:hover {
    color: ${PRIMARY_COLOR};
  }}
`;
