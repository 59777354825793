import ProfileTopBar from './ProfileTopBar';
import SharedImagesGrid from '../../components/ImageStuff/ImageGrid/SharedImagesGrid';
import PageContainer from '../../components/common/PageContainer';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useEffect, useState } from 'react';
import useGetSharedImagesWithIds from '../../hooks/userHandling/useGetSharedImagesWithIds';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { SharedImage } from '../../types';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { sortImages } from '../../components/ImageStuff/ImageGrid/imageGridHelpers';
import { useUpdateSetSortyByInProfile } from '../../localStorage/sortSettingsInProfilesStorage';
import SortDropdown from '../../components/ImageStuff/ImageGrid/SortDropdown';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';

const ProfilePage = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const getSharedImagesWithIds = useGetSharedImagesWithIds();
  const { sharedImages, setSharedImages } = useImagesContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const update = async () => {
      if (
        !loggedInUser ||
        !loggedInUser.sharedImages.length ||
        // If we already have the shared images, don't fetch them again
        sharedImages.length >= loggedInUser.sharedImages.length
      ) {
        return;
      }

      setIsLoading(true);
      const fetchedSharedImages: SharedImage[] = await getSharedImagesWithIds(
        loggedInUser.sharedImages,
      );
      fetchedSharedImages && setSharedImages(fetchedSharedImages);
      setIsLoading(false);
    };
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const sortByOptions = ['Latest', 'Best', 'Oldest'];
  const [sortBy, setSortBy] = useState('Latest');

  useUpdateSetSortyByInProfile(setSortBy);

  const sortedImages = sortImages(sharedImages, sortBy);

  if (!loggedInUser) {
    return (
      <PageContainer>
        Your profile will be here after signing in ❤️
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProfileTopBar />
      {loggedInUser.sharedImages.length === 0 ? (
        <NoPostedImagesYet />
      ) : (
        <div>
          <SortDropdown
            sortBy={sortBy}
            setSortBy={setSortBy}
            options={sortByOptions}
          />
          {isLoading ? (
            <LoadingAnimation loading loaderToChoose={2} />
          ) : (
            <SharedImagesGrid sharedImages={sortedImages} />
          )}
        </div>
      )}
    </PageContainer>
  );
};

const NoPostedImagesYet = () => (
  <div
    style={{
      marginTop: 16,
      padding: 16,
      color: SECONDARY_TEXT_COLOR,
    }}
  >
    You haven't posted any images yet. Share your first one and join the
    community!
  </div>
);

export default ProfilePage;
