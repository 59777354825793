import styled from 'styled-components';
import { getImagesFromLocalStorage } from '../../localStorage/imageStorage';
import { FakeImage } from '../../types';
import { useEffect, useState } from 'react';

import NonSharedImageFeed from './NonSharedImageFeed';
import TextFilter from '../../components/common/TextFilter';
import { useHandleFiltering } from './useHandleFiltering';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import EngineFilterDropdown from '../FeedPage/EngineFilterDropdown';
import { Description } from './SharedComponentsInLibrary';

const HistoryTab = () => {
  const historyImages: FakeImage[] = getImagesFromLocalStorage();
  const handleFiltering = useHandleFiltering();

  const [isLoadingFeed, setIsLoadingFeed] = useState<boolean>(false);

  const [engineFilter, setEngineFilter] = useState<string>('All');

  const [filter, setFilter] = useState<string>('');
  const handleSetFilter = (filterInput: string) => {
    setFilter(filterInput);
    setIsLoadingFeed(true);
    setTimeout(() => {
      setIsLoadingFeed(false);
    }, 500);
  };

  const [itemsToShow, setItemsToShow] = useState<FakeImage[]>([]);
  useEffect(() => {
    const filteredImages = handleFiltering(historyImages, filter, engineFilter);

    setItemsToShow(filteredImages);
  }, [filter, engineFilter]);

  return (
    <div>
      <TextContainer>
        <Description>
          Browser cache stores your history. Images are deleted after 7 to 30 days;
          <b> save❤️  or share to preserve them.</b>
        </Description>
      </TextContainer>
      <EngineFilterDropdown
        value={engineFilter}
        setValue={setEngineFilter}
        inLibrary={true}
      />
      <TextFilter filter={filter} handleSetFilter={handleSetFilter} />
      {isLoadingFeed ? (
        <LoadingAnimation loading={isLoadingFeed} loaderToChoose={2} />
      ) : (
        <NonSharedImageFeed images={itemsToShow} inHistory />
      )}
    </div>
  );
};

const TextContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 16px;
`;

export default HistoryTab;
