// useEffect every time loading changes (in general context)
// every time loading is true, change title to "Generating..."

import { useEffect } from "react";
import { useGeneralContext } from "../../context/GeneralContextProvider";

// every time loading is false, change title back to "x"
const useHandleUpdatingTitle = () => {
  const { loading } = useGeneralContext();
  useEffect(() => {
    if (loading) {
      document.title = "Generating...";
    } else {
      document.title =
        "OnlyFakes - Uncensored AI Images | NSFW AI Generator";
    }
  }, [loading]);
};

export default useHandleUpdatingTitle;
