import { getBaseUrlForFetching } from "../../App";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { SharedImage } from "../../types";

const useReportImage = () => {
  const headers = {
    "Content-type": "Application/json",
  };
  const { loggedInUser } = useLoggedInUserContext();
  const reportImage = async (image: SharedImage): Promise<string> => {
    const functionName = "reportImage";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const loggedInUserCleanedUp = { ...loggedInUser };
    delete loggedInUserCleanedUp.savedImages;
    delete loggedInUserCleanedUp.sharedImages;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ image, loggedInUser: loggedInUserCleanedUp }),
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to report");
    }

    const data = await response.json();

    return data.body;
  };
  return reportImage;
};

export default useReportImage;
