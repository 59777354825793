import styled from 'styled-components';
import FeedImageCard from './FeedImageCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SharedImage } from '../../../types';
import { useState, useEffect } from 'react';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

interface Props {
  imagesToShow: SharedImage[];
}

const SeeMoreImages = ({ imagesToShow }: Props) => {
  const [items, setItems] = useState<SharedImage[]>([]);

  const SHOW_X_PER = 2;

  useEffect(() => {
    window.scrollTo(0, 0);
    setItems(imagesToShow.slice(0, SHOW_X_PER));
  }, [imagesToShow]);

  const fetchMore = () => {
    const nextItems = imagesToShow.slice(
      items.length,
      items.length + SHOW_X_PER,
    );

    setItems(items.concat(nextItems));
  };
  const hasMore = items.length < imagesToShow.length;
  const dataLength = items?.length;

  if (imagesToShow.length === 0) return null;
  if (items.length === 0) return <LoadingIndicator differentBgColor />;
  return (
    <Container id="seeMoreImages">
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMore}
        hasMore={hasMore}
        loader={<LoadingIndicator />}
        scrollThreshold={0.95}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        {items.map((image) => (
          <FeedImageCard
            key={image.id}
            imageId={image.id}
            imageUrl={image.image.imageUrl}
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

export default SeeMoreImages;

const Container = styled.div``;
