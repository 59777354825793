import { useGeneralContext } from "../../context/GeneralContextProvider";
import {
  getImagesFromLocalStorage,
  setImagesToLocalStorage,
} from "../../localStorage/imageStorage";
import { FakeImage } from "../../types";

const useHandleUpdateImages = () => {
  const { setImages, setActiveImage } = useGeneralContext();

  const handleUpdateImages = (image: FakeImage) => {
    const imagesInLocalStorage = getImagesFromLocalStorage();

    const updatedImages = imagesInLocalStorage.map((imageInLocalStorage) => {
      if (imageInLocalStorage.trackId === image.trackId) {
        return image;
      }
      return imageInLocalStorage;
    });
    setImagesToLocalStorage(updatedImages);

    setImages((images) => {
      const updatedImages = images.map((imageInState) => {
        if (imageInState.trackId === image.trackId) {
          return image;
        }
        return imageInState;
      });
      return updatedImages;
    });

    setActiveImage(image);
  };
  return handleUpdateImages;
};

export default useHandleUpdateImages;
