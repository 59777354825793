import { useEffect, useRef, useState } from 'react';
import useGetSharedImagesWithIds from '../../../hooks/userHandling/useGetSharedImagesWithIds';
import { FakeImage, SharedImage } from '../../../types';
import TopBarInImageDetail from './TopBarInImageDetail';
import ImageInFeedCard from './ImageInFeedCard';
import styled from 'styled-components';
import ImageActionsInFeed from './ImageActionsInFeed';
import { desktopMediaQuery } from '../../../styleHelpers';
import { fadeIn } from '../../../components/ImageStuff/animations';
import useUpdateAmountOfRemixes from '../../../hooks/feed/useUpdateAmountOfRemixes';
import useGetIsImageOwnedByLoggedInUser from '../../../hooks/feed/useGetIsImageOwnedByLoggedInUser';
import ImageTitle from './ImageTitle';
import ImageChannelTag from './ImageChannelTag';
import { SECONDARY_TEXT_COLOR } from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { toast } from 'react-toastify';
import useHandleMarkingImageAsSeen from '../../../hooks/feed/useHandleMarkingImageAsSeen';
import AlbumInFeedCard from './AlbumInFeedCard';
import BannerAd from '../../../components/Ads/BannerAd';

interface FeedImageDetailProps {
  imageId: string;
  imageUrl: string | null;
}

const FeedImageCard = ({ imageId, imageUrl }: FeedImageDetailProps) => {
  const { loggedInUser } = useLoggedInUserContext();
  const getSharedImagesWithIds = useGetSharedImagesWithIds();
  const updateAmountOfRemixes = useUpdateAmountOfRemixes();
  const [image, setImage] = useState<SharedImage | null>(null);
  const [isAlbum, setIsAlbum] = useState<boolean>(false);
  const [hideImage, setHideImage] = useState<boolean>(false);

  const isImageOwnedByLoggedInUser = useGetIsImageOwnedByLoggedInUser(image);

  useEffect(() => {
    const update = async () => {
      const sharedImage = await getSharedImagesWithIds([imageId]);
      if (sharedImage.length === 1) {
        let postedImage = sharedImage[0];
        const isAlbum = !!(postedImage.album && postedImage.album.length > 1);
        setIsAlbum(isAlbum);
        setImage(postedImage);
      }
    };
    update();
  }, [imageId]);

  // TODO: add a check for if user has liked to useEffect
  const [hasUserLiked, setHasUserLiked] = useState<boolean>(false);
  const handleUpvotingImage = () => {
    if (!loggedInUser) {
      toast.error('You must be logged in to upvote an image');
      return;
    }
    if (!image || isImageOwnedByLoggedInUser) return;
    if (hasUserLiked) {
      setHasUserLiked(false);
      return;
    }
    setHasUserLiked(true);
    updateAmountOfRemixes(image);
  };

  useEffect(() => {
    //random 5% chance to give user a like on image
    const random = Math.random();
    if (random < 0.05) {
      image && updateAmountOfRemixes(image);
    }
  }, []);

  useEffect(() => {
    if (!loggedInUser || !image) return;
    const trackIds = loggedInUser.savedImageTrackIds;
    const isSaved =
      trackIds?.some((trackId) => trackId === image.image.trackId) || false;
    setHasUserLiked(isSaved);
  }, [image, loggedInUser]);

  const containerRef = useRef<HTMLDivElement>(null);
  useHandleMarkingImageAsSeen({
    containerRef,
    shouldMarkAsSeen: true,
    imageId,
  });

  // hackish way to make sure can immediately see the image without waiting for the fetch
  const imgUrlToUse = imageUrl ? imageUrl : image?.image.imageUrl;

  const handleImageLoadingError = () => {
    setHideImage(true);
  };

  // hack to fix since old albums were broken wrt uploading to permanent image storage
  const handleAlbumLoadError = () => {
    setIsAlbum(false);
  };

  const [selectedImageInAlbum, setSelectedImageInAlbum] =
    useState<FakeImage | null>(null);

  const [showAd, setShowAd] = useState<boolean>(false);

  useEffect(() => {
    if (loggedInUser?.type === 'gold') return;
    let randomChanceToShowAd = 0.2;

    if (loggedInUser?.username === 'malossi') {
      randomChanceToShowAd = 0.99;
    }
    const random = Math.random();
    if (random < randomChanceToShowAd) {
      setShowAd(true);
    }
  }, [imageId]);

  if (!image && !imgUrlToUse) {
    return null;
  }

  return !hideImage ? (
    <Container ref={containerRef}>
      <TopBarInImageDetail image={image} />
      <ImageTitle title={image?.title} />

      <ImagesContainer>
        {isAlbum ? (
          <AlbumInFeedCard
            sharedImage={image as SharedImage} // can force it since null check is above, weird TS error
            handleUpvotingImage={handleUpvotingImage}
            handleImageLoadingError={handleAlbumLoadError}
            setSelectedImageInAlbum={setSelectedImageInAlbum}
          />
        ) : (
          <ImageInFeedCard
            imageUrl={imgUrlToUse}
            handleUpvotingImage={handleUpvotingImage}
            handleImageLoadingError={handleImageLoadingError}
            fakeImage={image?.image}
          />
        )}
      </ImagesContainer>

      <ImageActionsInFeed
        image={image}
        handleUpvotingImage={handleUpvotingImage}
        hasUserLiked={hasUserLiked}
        selectedImageInAlbum={selectedImageInAlbum}
      />
      {image?.channel && image.channel.name !== null && (
        <ContainerForTag>
          Posted in:
          <ImageChannelTag channelName={image?.channel.name} />
        </ContainerForTag>
      )}

      {showAd && <BannerAd key={imageId} />}
    </Container>
  ) : null;
};

export default FeedImageCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  width: 99vw;
  margin-left: 0;

  animation: ${fadeIn} 0.2s ease-in-out;

  ${desktopMediaQuery} {
    margin-left: 15vw;
    max-width: 80vw;
  }
`;

const ContainerForTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;

  gap: 16px;

  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 8px;

  color: ${SECONDARY_TEXT_COLOR};

  ${desktopMediaQuery} {
    margin-top: 16px;
    margin-left: 40vw;
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  ${desktopMediaQuery} {
    display: flex;
    justify-content: center;
    max-width: 40vw;
  }
`;
