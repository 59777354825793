import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';

interface P {
  bigger?: boolean;
}

const logoImageSrc = 'OnlyFakesLogo.webp';

const OnlyFakesLogo = ({ bigger }: P) => {
  if (bigger)
    return (
      <Container bigger={bigger}>
        <LogoImage src={logoImageSrc} alt="OnlyFakes Logo" />
      </Container>
    );
  return (
    <Container bigger={bigger}>
      <OnlyText bigger={bigger}>Only</OnlyText>
      <FakesText bigger={bigger}>Fakes</FakesText>
      <HeartContainer bigger={bigger}>❤️</HeartContainer>
    </Container>
  );
};

export default OnlyFakesLogo;

const LogoImage = styled.img`
  max-width: 50vw;
  ${desktopMediaQuery} {
    max-width: 300px;
  }
`;

const Container = styled.div<P>`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  margin-left: ${({ bigger }) => (bigger ? 16 : 0)}px;
`;

const fontSize = 20;
const biggerFontSize = 36;

const OnlyText = styled.div<P>`
  color: ${PRIMARY_TEXT_COLOR};
  font-size: ${({ bigger }) => (bigger ? biggerFontSize : fontSize)}px;
  font-family: 'Poppins', 'Open sans', sans-serif;
  font-weight: 300;
`;

const FakesText = styled.div<P>`
  color: ${PRIMARY_COLOR};
  font-size: ${({ bigger }) => (bigger ? biggerFontSize : fontSize)}px;
  font-family: Marguerite1;

  font-weight: 600;
`;

const HeartContainer = styled.div<P>`
  position: relative;
  bottom: ${({ bigger }) => (bigger ? 24 : 12)}px;
  margin-left: ${({ bigger }) => (bigger ? 8 : 4)}px;
  font-size: ${({ bigger }) => (bigger ? biggerFontSize / 3 : fontSize / 3)}px;
`;
