import React, { useEffect } from 'react';
import { SpeedModeType } from '../../../types';
import {
  ContainerForSelect,
  StyledSelectForBelowImage,
} from './SharedComponentsInBelowImage';
import userSettingsInStorage from '../../../localStorage/userSettingsInStorage';
import { FaTachometerAlt } from 'react-icons/fa';
import { getCreditsRequired } from '../../../components/helpers';
import { getWaitTimeForSpeedMode } from '../../../generationConstants';

interface Props {
  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;
  wider?: boolean;
}

const SpeedModeSelector: React.FC<Props> = ({ speedMode, setSpeedMode, wider }) => {
  useEffect(() => {
    const speedModeFromLocalStorage = userSettingsInStorage().speedMode.get();
    if (speedModeFromLocalStorage) {
      setSpeedMode(speedModeFromLocalStorage as SpeedModeType);
    }
  }, []);

  const handleChange = (e: any) => {
    const newSpeedMode = e.target.value;
    if (!newSpeedMode) {
      console.error('newSpeedMode is undefined');
      return;
    }
    setSpeedMode(newSpeedMode);
    userSettingsInStorage().speedMode.set(newSpeedMode);
  };

  const normalText = `Normal - ${getCreditsRequired('normal')} credit  ~${getWaitTimeForSpeedMode('normal')} sec`;
  const fastText = `Fast - ${getCreditsRequired('fast')} credits ~${getWaitTimeForSpeedMode('fast')} sec`;
  const adTurboText = `🆕 Turbo with ads - 0 credits ~${getWaitTimeForSpeedMode('adTurbo')} sec`
  const turboText = `Turbo - ${getCreditsRequired('turbo')} credits ~${getWaitTimeForSpeedMode('turbo')} sec`;

  return (
    <ContainerForSelect wider={wider}>
      <FaTachometerAlt size={20} />
      <StyledSelectForBelowImage value={speedMode} onChange={handleChange}>
        <option value={'normal'}>{normalText}</option>
        <option value={'fast'}>{fastText}</option>
        <option value={'adTurbo'}>{adTurboText}</option>
        <option value={'turbo'}>{turboText}</option>
      </StyledSelectForBelowImage>
    </ContainerForSelect>
  );
};

export default SpeedModeSelector;
