import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { GREEN_COLOR } from '../../../../constants';
import {
  desktopMediaQuery,
  imageHoverAnimationInFeed,
} from '../../../../styleHelpers';
import { SmoothlyLoadingImage } from '../../../common/SmoothlyLoadingImage';
import { FakeImage } from '../../../../types';
import { PLACEHOLDER_IMAGE_PATH } from '../../../ImageStuff/GeneratedImage';

interface Props {
  imagesToShow: FakeImage[];
  handleClickOnImage: (event: React.MouseEvent<HTMLImageElement>) => void;
  getIsImageSelected: (trackId: string) => boolean;
}

const ImagesGridInAddImagesModal: React.FC<Props> = ({
  imagesToShow,
  handleClickOnImage,
  getIsImageSelected,
}) => {
  if (!imagesToShow.length) {
    return <div>No images to show</div>;
  }
  return (
    <ScrollableGrid>
      {imagesToShow.map((image) => (
        <ImageWrapper key={image.trackId}>
          <Image
            onClick={handleClickOnImage}
            src={image.imageUrl || PLACEHOLDER_IMAGE_PATH}
            alt="A saved image"
            loading="lazy"
            id={image.trackId}
            loaded={true}
          />
          {getIsImageSelected(image.trackId) && <SelectedIcon />}
        </ImageWrapper>
      ))}
    </ScrollableGrid>
  );
};

const ScrollableGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
  padding: 2px;
  padding-right: 8px;
  padding-bottom: 64px;
  width: 100%;
`;

const MOBILE_IMG_SIZE = 'calc(30vw - 2px)';
const ImageWrapper = styled.div`
  position: relative;
  flex: 1 1 ${MOBILE_IMG_SIZE};
  max-width: ${MOBILE_IMG_SIZE};
  ${desktopMediaQuery} {
    flex: 1 1 175px;
    max-width: 175px;
  }
  margin: 1px;
`;

const Image = styled(SmoothlyLoadingImage)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  ${imageHoverAnimationInFeed}
`;

const SelectedIcon = styled(FaCheckCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${GREEN_COLOR};
  z-index: 10;
  font-size: 24px;
`;

export default ImagesGridInAddImagesModal;
