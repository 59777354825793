import styled from "styled-components";
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  LIGHT_GREY_COLOR,
} from "../../constants";

interface P {
  differentBgColor?: boolean;
}

const LoadingIndicator = ({ differentBgColor }: P) => {
  return (
    <Container>
      <div
        className="loading-spinner"
        style={{
          borderTopColor: differentBgColor
            ? BACKGROUND_COLOR
            : LIGHT_GREY_COLOR,
        }}
      />
    </Container>
  );
};

const SIZE_MULTIPLIER = 0.375;

const Container = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: ${50 * SIZE_MULTIPLIER}px;
    height: ${50 * SIZE_MULTIPLIER}px;
    border: ${10 * SIZE_MULTIPLIER}px solid ${BORDER_COLOR};
    border-top: ${10 * SIZE_MULTIPLIER}px solid ${BACKGROUND_COLOR};
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
`;

export default LoadingIndicator;
