import ReactModal from "react-modal";
import styled, { keyframes } from "styled-components";

export const fadeInWithBounce = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.99);
    filter: blur(12px); // Adding blur
  }
  60% {
    opacity: 0.8;
    transform: scale(1.02); // Slight bounce effect
    filter: blur(5px); // Removing blur
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0); // Removing blur
  }
`;

const slideUpFadeIn = keyframes`
  from {
    opacity: 0.9;
    transform: translateY(250px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ModalThatTransitionsFromBottom = styled(ReactModal)`
  animation: ${slideUpFadeIn} 0.3s ease-in;
`;

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

export const comeUpAnimation = keyframes`
from {
  transform: translateY(30%);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
`;
