import styled from "styled-components";
import StylishInput from "../../components/common/StyledInput";
import { InputContainer } from "../../components/common/InputContainer";
import { FlexBoxRow } from "../../components/common/FlexBoxRow";
import { ClearInputIcon } from "../../components/common/ClearInputIcon";

interface P {
  negativePrompt: string;
  setNegativePrompt: (negativePrompt: string) => void;
}

const NegativePromptInputInInpainting = ({
  negativePrompt,
  setNegativePrompt,
}: P) => {
  return (
    <OuterContainer>
      <SmallMargin>
        <Label>Negative prompt ❌</Label>
        <FlexBoxRow>
          {negativePrompt.length} / 750
          <ClearInputIcon onClick={() => setNegativePrompt("")} />
        </FlexBoxRow>
      </SmallMargin>
      <Container>
        <InputContainer>
          <StylishInput
            value={negativePrompt}
            setValue={setNegativePrompt}
            placeholder="What you don't wanna see"
          />
        </InputContainer>
      </Container>
    </OuterContainer>
  );
};

const SmallMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 18px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 6px;
`;

const Label = styled.div`
  font-weight: 600;
`;

export default NegativePromptInputInInpainting;
