import { useCookies } from 'react-cookie';
import { getBaseUrlForFetching } from '../../App';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import useUploadImage from '../images/useUploadImage';
import { useState } from 'react';

const openImageUploadDialog = (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;

      if (file && file.size > 5 * 1024 * 1024) {
        reject(new Error('File size exceeds 5MB'));
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (!reader.result) {
          reject(new Error('Failed to read file'));
          return;
        }
        const result = reader.result as string;
        resolve(result);
      };

      reader.onerror = (error: ProgressEvent<FileReader>) => {
        reject(error);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    };

    input.click();
  });
};

const useSetProfileImage = () => {
  const headers = {
    'Content-type': 'Application/json',
  };
  const { setLoggedInUser } = useLoggedInUserContext();
  const [loading, setLoading] = useState(false);
  const uploadImage = useUploadImage();

  const [cookies] = useCookies(['token']);
  const token = cookies.token;

  const setProfileImage = async (): Promise<boolean> => {
    const functionName = 'setProfileImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    if (!token) {
      return false;
    }

    setLoading(true);

    // Open image upload dialog and get base64 of uploaded image
    const uploadedImageBase64 = await openImageUploadDialog();

    if (!uploadedImageBase64) {
      return false;
    }

    // Pass base64 to uploadImage function and get imageUrl
    const { status, imageUrl } = await uploadImage(uploadedImageBase64);
    if (status !== 200 || !imageUrl) {
      return false;
    }

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({ imageUrl, token }),
      headers,
    });

    setLoading(false);

    if (response.status !== 200) {
      return false;
    }
    setLoggedInUser((prevState) => {
      if (prevState !== null) {
        return { ...prevState, profileImageUrl: imageUrl };
      } else {
        return null;
      }
    });

    return true;
  };
  return { setProfileImage, loading };
};

export default useSetProfileImage;
