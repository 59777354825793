import { RefObject, useEffect } from 'react';
import AlreadySeenImagesStorage from '../../localStorage/alreadySeenImagesStorage';
import { MS_IN_SECOND } from '../../constants';

type UseHandleMarkingImageAsSeenParams = {
  containerRef: RefObject<HTMLDivElement>;
  shouldMarkAsSeen?: boolean;
  imageId: string;
};

/**
 * Hook that handles marking an image as seen when it comes into view
 * 
 * It marks the Ids to localStorage if the image is seen for more than x seconds
 * @param containerRef Ref to the container of the image
 * @param shouldMarkAsSeen Whether the image should be marked as seen
 * @param image The image to mark as seen
 * @returns void
 */
const useHandleMarkingImageAsSeen = ({
  containerRef,
  shouldMarkAsSeen,
  imageId
}: UseHandleMarkingImageAsSeenParams) => {
  const VIEW_THRESHOLD = 0.7 * MS_IN_SECOND;
  useEffect(() => {
    if (!shouldMarkAsSeen) return;

    let viewStartTime = 0; // Initialize view start time

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Only set viewStartTime if it's 0 to avoid resetting it on rapid intersection changes
            if (viewStartTime === 0) {
              viewStartTime = new Date().getTime(); // Image comes into view
            }
          } else {
            if (viewStartTime !== 0) {
              // Ensure we have a start time before calculating duration
              const viewEndTime = new Date().getTime(); // Image goes out of view
              const viewedDuration = viewEndTime - viewStartTime;

              if (viewedDuration >= VIEW_THRESHOLD) {
                AlreadySeenImagesStorage.addImageId(imageId);
              }
              // Reset viewStartTime after handling the visibility change
              viewStartTime = 0;
            }
          }
        });
      },
      { threshold: 0.5 },
    );

    const currentContainer = containerRef.current;
    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, [containerRef, shouldMarkAsSeen, imageId, VIEW_THRESHOLD]); 
};

export default useHandleMarkingImageAsSeen;
