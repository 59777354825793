import styled from 'styled-components';
import OnlyFakesLogo from '../../components/common/TopBar/OnlyFakesLogo';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { comeUpAnimation } from '../../components/ImageStuff/animations';

interface P {
  inLandingPage?: boolean;
}
const TitleSectionInHomeGenerator = ({ inLandingPage }: P) => {
  const textToShow = inLandingPage
    ? 'Uncensored AI Generator'
    : 'NSFW Image Generator';
  return (
    <Container>
      <OnlyFakesLogo bigger />
      <Title>{textToShow}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 36px;

  animation: ${comeUpAnimation} 0.8s ease-out forwards;
  opacity: 0; // Start invisible
`;

const Title = styled.h1`
  margin-top: 2px;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  color: ${SECONDARY_TEXT_COLOR};
`;

export default TitleSectionInHomeGenerator;
