import { BASE_URL } from '../../constants';
import { FakeImage } from '../../types';

export function useHandleDownloadImage() {
  const handleDownloadImage = async (image: FakeImage) => {
    if (!image.imageUrl) return;

    try {
      const response = await fetch(image.imageUrl, {
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Origin: BASE_URL,
        },
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${image.trackId}.jpeg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download image', error);
      window.open(image.imageUrl, '_blank');
    }
  };
  return handleDownloadImage;
}
