import { BiUpvote } from 'react-icons/bi';
import styled from 'styled-components';
import { EditButtonBase } from '../../components/UserProfiles/EditButtonBase';
import { ANON_NAME_TO_USE } from '../../constants';
import useSetDisplayName from '../../hooks/userHandling/useSetDisplayName';
import { desktopMediaQuery } from '../../styleHelpers';
import { SharedImage } from '../../types';
import LoadingIndicator from '../../components/common/LoadingIndicator';

interface Props {
  displayName: string | undefined;
  isGold?: boolean;
  isPublicProfile?: boolean;
}

export const DisplayName = ({
  displayName,
  isGold,
  isPublicProfile,
}: Props) => {
  const { setDisplayName, loading } = useSetDisplayName();

  const displayNameToShow = displayName ? displayName : ANON_NAME_TO_USE;

  const showPrivateProfileThings = !isPublicProfile;

  if (loading) return <LoadingIndicator differentBgColor />;
  return (
    <OuterContainerForDisplayName>
      {showPrivateProfileThings && <b>Display name: </b>}

      <ContainerForDisplayName>
        {displayNameToShow}
        {isGold && <span>⭐</span>}

        {showPrivateProfileThings && (
          <EditButtonBase onClick={setDisplayName} />
        )}
      </ContainerForDisplayName>
    </OuterContainerForDisplayName>
  );
};

const OuterContainerForDisplayName = styled.div`
  margin-top: 8px;
  white-space: nowrap;
`;

const ContainerForDisplayName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const UsernamePart = ({ username }: { username: string }) => (
  <UsernameContainer>
    <div>
      <b>Username:</b>
    </div>
    <div>{username}</div>
  </UsernameContainer>
);

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SharedImagesProps {
  sharedImages: SharedImage[];
}

export const AmountOfSharedImages = ({ sharedImages }: SharedImagesProps) => {
  const amountOfSharedImages = sharedImages.length;
  return (
    <InfoContainer>
      <div>{amountOfSharedImages}</div>
      <div>Posts</div>
    </InfoContainer>
  );
};

export const AmountOfKarma = ({ sharedImages }: SharedImagesProps) => {
  const totalKarma = sharedImages.reduce(
    (acc, curr) => acc + curr.amountOfRemixes,
    0,
  );

  return (
    <InfoContainer>
      <KarmaContainer>
        <BiUpvote size={18} /> {totalKarma}
      </KarmaContainer>
      <div>Karma</div>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${desktopMediaQuery} {
    flex-direction: row;
    gap: 4px;
    margin-left: 16px;
  }
  align-items: center;
`;

const KarmaContainer = styled(InfoContainer)`
  flex-direction: row;
  gap: 2px;
`;
