import { AiOutlineClose } from "react-icons/ai";
import {
  BACKGROUND_COLOR,
  HOVER_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../../../constants";
import styled from "styled-components";
import { EngineType, GenericSetState } from "../../../types";
import EngineList from "./EngineList";
import TransitioningModal from "../../../components/Modals/TransitioningModal";
import { desktopMediaQuery } from "../../../styleHelpers";

interface P {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  engine: EngineType;
  setEngine: GenericSetState<EngineType>;
}
const EngineModal = ({ showModal, setShowModal, engine, setEngine }: P) => {
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 300,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          marginTop: "4px",
          padding: "16px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShowModal(false)}
    >
      <ModalContent>
        <CloseButtonOnModal onClick={() => setShowModal(false)} />
        <TitleText>Select engine</TitleText>
        <ModalText>
          The engine (aka. model in SD) "powers" the AI to generate the image.
        </ModalText>
        <EngineList
          engine={engine}
          setEngine={setEngine}
          setShowModal={setShowModal}
        />
      </ModalContent>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 85vw;
  max-width: 800px;

  height: 93vh;
  overflow-y: scroll;
  z-index: 200;

  margin: auto;

  // hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  // hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const ModalText = styled.div`
  font-size: 14px;
  color: ${SECONDARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 8px;
`;

const CloseButtonOnModal = styled(AiOutlineClose)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 36px;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  color: ${HOVER_COLOR};
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
  margin-top: 32px;

  color: ${PRIMARY_TEXT_COLOR};
`;

export default EngineModal;
