import { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import { InputContainer } from './InputContainer';
import StylishInput from './StyledInput';
import { FaX } from 'react-icons/fa6';
import { waitSeconds } from '../helpers';

interface P {
  filter: string;
  handleSetFilter: (filter: string) => void;
  placeholderText?: string;
  autoUpdateAfterNoInput?: boolean;
}

const TextFilter: React.FC<P> = ({
  filter,
  handleSetFilter,
  placeholderText = 'Filter by prompt...',
  autoUpdateAfterNoInput,
}) => {
  const [value, setValue] = useState(filter);
  const setFilter = () => {
    handleSetFilter(value);
  };

  const AUTO_UPDATE_FILTER_AFTER_NO_INPUT = 1000;
  useEffect(() => {
    if (!autoUpdateAfterNoInput) return;
    const handler = setTimeout(() => {
      setFilter();
    }, AUTO_UPDATE_FILTER_AFTER_NO_INPUT); 

    return () => clearTimeout(handler); 
  }, [value]); // Watch for changes in value

  const clearFilter = async () => {
    setValue('');
    await waitSeconds(0.1)
    handleSetFilter('');
  }

  return (
    <Container
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          setFilter();
        }
      }}
    >
      <InputContainer withIcon>
        {value !== '' && <EmptyButton onClick={clearFilter} />}
        <StylishInput
          value={value}
          setValue={setValue}
          placeholder={placeholderText}
          withIcon
        />
        <StyledFilterButton onClick={setFilter} size={30} />
      </InputContainer>
    </Container>
  );
};

export default TextFilter;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const EmptyButton = styled(FaX)`
  font-size: 12px;
  margin-left: 12px;
  margin-right: -4px;
  z-index: 2;
  cursor: pointer;
`;

const StyledFilterButton = styled(MdSearch)`
  cursor: pointer;
  z-index: 2;
`;
