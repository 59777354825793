import styled from 'styled-components';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useState } from 'react';
import { SmoothlyLoadingImage } from '../../../components/common/SmoothlyLoadingImage';
import { FakeImage } from '../../../types';
import { FaPaintBrush, FaMagic } from 'react-icons/fa';
import { FaUpload, FaRetweet } from 'react-icons/fa6';
import { MdHd } from 'react-icons/md';

interface ImageInFeedDetailProps {
  imageUrl?: string;
  handleUpvotingImage: () => void;
  handleImageLoadingError: () => void;
  inHomePage?: boolean;
  fakeImage?: FakeImage;
}

const ImageInFeedCard = ({
  imageUrl,
  handleUpvotingImage,
  handleImageLoadingError,
  inHomePage,
  fakeImage: image,
}: ImageInFeedDetailProps) => {
  const [loaded, setLoaded] = useState(false);

  // If adding stuff here remember to add also to NonSharedFeedImage.tsx
  const isInpainted = image?.isInpainted;
  const isVariation = !!image?.isCreatingVariations;
  const isMagicMode = image?.selectedTags && image?.selectedTags?.length > 0;
  const isImg2Img = !!image?.uploadedImageUrl && !isVariation && !isMagicMode;
  const isEnhanced = image?.isEnhanced;

  const prompt = image?.prompt;
  const alt = `Image made with AI. Prompt: ${prompt}`;

  const showBadge = isInpainted || isImg2Img || isVariation || isMagicMode || isEnhanced;
  return (
    <ImageContainer onDoubleClick={handleUpvotingImage} inHomePage={inHomePage}>
      <Image
        src={imageUrl}
        loading="lazy"
        onError={handleImageLoadingError}
        onLoad={() => setLoaded(true)}
        loaded={loaded}
        inHomePage={inHomePage}
        alt={alt}
      />
      {showBadge && (
        <ImageBadgeContainer>
          {isInpainted && <FaPaintBrush />}
          {isImg2Img && <FaUpload />}
          {isVariation && <FaRetweet />}
          {isMagicMode && <FaMagic />}
          {isEnhanced && <MdHd />}
        </ImageBadgeContainer>
      )}
    </ImageContainer>
  );
};

export default ImageInFeedCard;

const ImageContainer = styled.div<{ inHomePage?: boolean }>`
  position: relative;
  width: 98%;
  border-radius: 16px;
  margin-left: 4px;

  ${desktopMediaQuery} {
    max-width: 784px;
    max-height: 648px;
  }
`;

const Image = styled(SmoothlyLoadingImage)<{ inHomePage?: boolean }>`
  width: 100%;
  object-fit: contain;
  border-radius: 16px;
  max-height: 88vh;

  ${desktopMediaQuery} {
    ${({ inHomePage }) =>
      inHomePage &&
      `
      object-fit: cover;
      object-position: top;
    `}
    max-width: 648px;
    max-height: ${({ inHomePage }) => (inHomePage ? '608px' : '648px')};
  }
`;

const ImageBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px;
  border-radius: 0 10px 0 0;
  cursor: default;

  position: absolute;
  bottom: 4px;
  left: 2px;
  font-size: 14px;
  ${desktopMediaQuery} {
    bottom: 0px;
    left: 10vh;
    font-size: 16px;
  }
`;
