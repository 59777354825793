import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOR } from '../../../../constants';
import { CloseButtonInModal } from '../../../common/CloseButtonInModal';
import TransitioningModal from '../../TransitioningModal';
import { useImagesContext } from '../../../../context/ImagesContextProvider';
import { getImagesFromLocalStorage } from '../../../../localStorage/imageStorage';
import { FakeImage, SavedImage } from '../../../../types';
import ImagesGridInAddImagesModal from './ImagesGridInAddImagesModal';
import ImagesToShowButtons from './ImagesToShowButtons';
import { toast } from 'react-toastify';
import { useLoggedInUserContext } from '../../../../context/LoggedInUserContextProvider';
import useGetSavedImagesWithToken from '../../../../hooks/userHandling/useGetSavedImagesWithToken';
import StrongPrimaryButton from '../../../common/StrongPrimaryButton';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
}

const AddImagesModal = ({ show, setShow }: I) => {
  const handleClose = () => {
    setShow(false);
  };

  const { loggedInUser } = useLoggedInUserContext();
  const { savedImages, setSavedImages, setAlbumToBePosted, imageToBePosted } =
    useImagesContext();
  const historyImages: FakeImage[] = getImagesFromLocalStorage();

  const [selectedOption, setSelectedOption] = useState<'Saved' | 'History'>(
    'History',
  );

  const howManyToShow = 100;

  const [imagesToShow, setImagesToShow] = useState<FakeImage[]>([]);
  useEffect(() => {
    setSelectedImages([imageToBePosted.trackId]);
  }, [imageToBePosted]);

  useEffect(() => {
    if (selectedOption === 'History') {
      setImagesToShow(historyImages.slice(0, howManyToShow));
      return;
    }
    const savedImagesWithoutImgsFromFeed = savedImages.filter(
      (image) => !image.sharedImageRefId,
    );

    setImagesToShow(savedImagesWithoutImgsFromFeed.slice(0, howManyToShow));
  }, [selectedOption]);

  const getSavedImagesWithToken = useGetSavedImagesWithToken();
  useEffect(() => {
    const updateSavedImages = async () => {
      const fetchedSavedImages: SavedImage[] = await getSavedImagesWithToken();
      fetchedSavedImages.length !== 0 && setSavedImages(fetchedSavedImages);
    };
    if (savedImages.length === 0 && loggedInUser) {
      updateSavedImages();
    }
  }, []);

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const handleClickOnImage = (e: React.MouseEvent<HTMLImageElement>) => {
    const clickedTrackId = e.currentTarget.id;
    console.log({ clickedTrackId });
    // can't unselect the image that is being posted
    if (clickedTrackId === imageToBePosted.trackId) {
      return;
    }
    if (selectedImages.length > 19) {
      toast.error('You can only select 20 images');
      return;
    }
    setSelectedImages((prev) => {
      if (prev.includes(clickedTrackId)) {
        return prev.filter((id) => id !== clickedTrackId);
      }
      return [...prev, clickedTrackId];
    });
  };

  const getIsImageSelected = (imageTrackId: string) => {
    return selectedImages.includes(imageTrackId);
  };

  // eww but idgaf
  const handleAddImages = () => {
    const imgsToAdd: FakeImage[] = [];

    const combinedFakeImages = [...historyImages, ...savedImages];

    selectedImages.forEach((trackId) => {
      const image = combinedFakeImages.find((img) => img.trackId === trackId);
      if (image) {
        imgsToAdd.push(image);
      }
    });

    setAlbumToBePosted(imgsToAdd);
    setShow(false);
  };

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '800px',
          height: '90vh',
          margin: 'auto',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <CloseButtonInModal onClick={handleClose} />
      <ImagesToShowButtons
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      <ImagesGridInAddImagesModal
        imagesToShow={imagesToShow}
        handleClickOnImage={handleClickOnImage}
        getIsImageSelected={getIsImageSelected}
      />

      {selectedImages.length > 0 && (
        <ShowAddImagesButton onClick={handleAddImages}>
          Use Selected Images ({selectedImages.length})
        </ShowAddImagesButton>
      )}
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
`;

const ShowAddImagesButton = styled(StrongPrimaryButton)`
  position: absolute;
  bottom: 5.5vh;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  z-index: 10;
`;

export default AddImagesModal;
