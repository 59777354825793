import styled from "styled-components";
import { DESKTOP_WIDTH, MAX_WIDTH } from "../../constants";
import { fadeIn } from "../ImageStuff/animations";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${MAX_WIDTH}px;
  @media (min-width: ${DESKTOP_WIDTH}px) {
    max-width: 80vw;
    margin-left: 15vw;
  }

  padding-bottom: 64px;
  padding-left: 8px;
  padding-right: 8px;

  animation: ${fadeIn} 0.5s ease-in-out;
`;

export default PageContainer;
