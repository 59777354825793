import styled from "styled-components";
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

const StyledSelect = styled.select`
  width: 200px;
  background-color: ${BACKGROUND_COLOR};
  height: 40px;
  font-size: 14px;

  font-weight: 400;
  color: ${PRIMARY_TEXT_COLOR};

  border-radius: 8px;
  border: 1.5px solid ${BORDER_COLOR};
  outline: none;
  padding: 8px;
  margin-top: -7px;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

export default StyledSelect;
