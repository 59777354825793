import { StyleInterface } from '../types';

export function getLastUsedStyleFromLocalStorage() {
  const lastUsedStyle = localStorage.getItem('lastUsedStyle');
  if (lastUsedStyle) {
    return JSON.parse(lastUsedStyle);
  }
  return null;
}

export const setLastUsedStyleToLocalStorage = (style: StyleInterface) => {
  localStorage.setItem('lastUsedStyle', JSON.stringify(style));
};

export function getRecentlyUsedStylesFromLocalStorage(): StyleInterface[] {
  const recentlyUsedStyles = JSON.parse(
    localStorage.getItem('recentlyUsedStyles') || '[]',
  );
  return recentlyUsedStyles;
}

export function setRecentlyUsedStylesToLocalStorage(
  style: StyleInterface,
): void {
  const recentlyUsedStyles: StyleInterface[] =
    getRecentlyUsedStylesFromLocalStorage();
  const newRecentlyUsedStyles = [
    style,
    ...recentlyUsedStyles.filter((s: StyleInterface) => s.id !== style.id),
  ];
  localStorage.setItem(
    'recentlyUsedStyles',
    JSON.stringify(newRecentlyUsedStyles),
  );
}
