import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { StyledButton } from '../../components/common/StyledButton';
import { BORDER_COLOR, GOLD_COLOR, PRIMARY_COLOR } from '../../constants';
import { waitSeconds } from '../../components/helpers';
import { FaMagic } from 'react-icons/fa';
import { HOME_PATH } from '../../pathNames';
import { useNavigate } from 'react-router-dom';
import { desktopMediaQuery } from '../../styleHelpers';
import { HashLoader } from 'react-spinners';
import { TitleTextWithGradient } from './CommonComponents';

const TryItOutSection = () => {
  const imageUrls = [
    'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/09eeab18-7be5-4797-b9a3-285eb5c74cc0.jpeg',
    'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/5a6ca350-4c56-438e-85fe-7a2d4b0fa91c.jpeg',
    'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/d4ddb711-acb6-49a6-9a6e-66dfd4e8c8cb.jpeg',
  ];
  const [selectedItemForImage, setSelectedItemForImage] = useState(0);
  const [imagesToShow, setImagesToShow] = useState([imageUrls[0]]);

  const handleChangeForCarousel = (selectedIndex: number) => {
    setSelectedItemForImage(selectedIndex);
  };

  const textsToShow = [
    'Busty blonde',
    'Busty brunette',
    'Busty brunette, nude',
  ];
  const [selectedText, setSelectedText] = useState(textsToShow[0]);
  const [hasClickedGenerate, setHasClickedGenerate] = useState(false);

  const showCreateMoreButton = hasClickedGenerate;

  const [showFakeGenerateButton, setShowFakeGenerateButton] = useState(false);

  const divRef = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useScrollAnimationTrigger(setStartAnimation, divRef);

  const triggerGenerateButtonAnimation = async () => {
    const generateButton = document.getElementById('generateButton');
    if (generateButton) {
      generateButton.style.border = `1.1px solid ${GOLD_COLOR}`;
      await waitSeconds(0.7);
      generateButton.style.border = `1px solid ${PRIMARY_COLOR}`;
    }
  };

  useEffect(() => {
    const handleSettingImagesToShow = async () => {
      if (!startAnimation) return;
      setSelectedText(textsToShow[1]);
      await waitSeconds(0.9);
      setImagesToShow(imageUrls.slice(0, 2));
      setSelectedItemForImage(1);
      await waitSeconds(0.8);
      setSelectedText(textsToShow[2]);
      setShowFakeGenerateButton(true);
      await waitSeconds(0.5);
      triggerGenerateButtonAnimation();
    };
    handleSettingImagesToShow();
  }, [startAnimation]);

  const handleClickFakeGenerate = async () => {
    setImagesToShow(imageUrls);
    setSelectedItemForImage(2);
    setShowFakeGenerateButton(false);
    setHasClickedGenerate(true);
  };

  const navigate = useNavigate();
  const handleClickCreateMore = () => {
    navigate(HOME_PATH);
  };

  const showEmptyPlaceholderDiv =
    !showFakeGenerateButton && !showCreateMoreButton;

  return (
    <Container>
      <TitleTextWithGradient>Use without signing up</TitleTextWithGradient>
      <FakeCarousel
        imagesToShow={imagesToShow}
        selectedItem={selectedItemForImage}
        handleChange={handleChangeForCarousel}
      />
      <FakeTextContainer selectedText={selectedText} />
      {showEmptyPlaceholderDiv && <EmptyDivToTakeSpace />}

      {showFakeGenerateButton && (
        <GenerateButton
          inLandingPage
          onClick={handleClickFakeGenerate}
          id="generateButton"
        >
          ✨ Generate this
        </GenerateButton>
      )}

      {showCreateMoreButton && (
        <CreateMoreButton onClick={handleClickCreateMore}>
          <FaMagic /> Generate More
        </CreateMoreButton>
      )}
      <EmptyDivToTriggerAnimation ref={divRef} />
    </Container>
  );
};

const EmptyDivToTriggerAnimation = styled.div``;

const EmptyDivToTakeSpace = styled.div`
  height: 57px;
`;

const useScrollAnimationTrigger = (
  setStartAnimation: (value: boolean) => void,
  divRef: React.RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    const handleScroll = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setStartAnimation(true);
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        },
      );

      if (divRef.current) {
        observer.observe(divRef.current);
      }

      // Remove scroll listener once we start observing
      window.removeEventListener('scroll', handleScroll);
    };

    // Add scroll listener to start observation after first scroll
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

interface FakeCarouselProps {
  imagesToShow: string[];
  selectedItem: number;
  handleChange: (selectedIndex: number) => void;
}
const FakeCarousel = ({
  imagesToShow,
  selectedItem,
  handleChange,
}: FakeCarouselProps) => {
  return (
    <CarouselContainer>
      <BorderedDiv>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          selectedItem={selectedItem}
          emulateTouch
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          onChange={handleChange}
          useKeyboardArrows={true}
        >
          {imagesToShow.map((url) => (
            <ImageToShow src={url} key={url} alt="AI Image" />
          ))}
        </Carousel>
        <IconBackground>
          <HashLoader color={PRIMARY_COLOR} size={80} speedMultiplier={1} />
        </IconBackground>
      </BorderedDiv>
    </CarouselContainer>
  );
};

const IconBackground = styled.div`
  position: absolute;
  color: ${BORDER_COLOR};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const CarouselContainer = styled.div`
  width: 75%;
  max-width: 500px;

  opacity: 0;
  transform: translateY(10px);
  animation: ${fadeInWithBounce} 0.7s ease-in-out forwards 0.7s;
`;

const BorderedDiv = styled.div`
  display: flex;
  align-items: center;
  border: 1px dotted ${BORDER_COLOR};
  border-radius: 8px;
  min-height: 350px;
  ${desktopMediaQuery} {
    min-height: 500px;
  }
`;

const ImageToShow = styled.img`
  object-fit: contain;
  opacity: 0;
  transform: scale(0.98);
  animation: ${fadeInWithBounce} 0.3s ease-in-out forwards 0.6s;
  border-radius: 8px;

  min-height: 200px;
`;

const FakeTextContainer = ({ selectedText }: { selectedText: string }) => {
  const [textToRender, setTextToRender] = useState('');

  const SPEED = 30;
  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      setTextToRender(selectedText.slice(0, i));
      i++;
      if (i > selectedText.length) {
        clearInterval(interval);
      }
    }, SPEED);

    return () => clearInterval(interval);
  }, [selectedText]);
  return (
    <TextContainer>
      <TextItself>{textToRender}</TextItself>
    </TextContainer>
  );
};

const TextContainer = styled.div`
  margin-bottom: 8px;
  margin-top: 16px;

  border: 1.5px solid ${BORDER_COLOR};
  border-radius: 8px;
  padding: 8px;
  padding-left: 12px;
  width: 60vw;
  max-width: 350px;
  font-size: 14px;
  height: 20px;
`;

const TextItself = styled.div``;

const GenerateButton = styled(StyledButton)`
  padding-left: 36px;
  padding-right: 36px;
  transition: border 1s ease-in-out;

  opacity: 0;
  animation: ${fadeInWithBounce} 0.7s ease-in-out forwards 0.7s;

  &:hover {
    margin-bottom: 0px;
    margin-left: 0;
    color: ${PRIMARY_COLOR};
  }
`;

const CreateMoreButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-color: ${GOLD_COLOR};
`;

export default TryItOutSection;
