import React from 'react';
import styled from 'styled-components';
import {
  PRIMARY_TEXT_COLOR,
  PRIMARY_COLOR,
  LIGHT_GREY_FOR_TAG,
} from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../../pathNames';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useImagesContext } from '../../../context/ImagesContextProvider';

interface ImageChannelProps {
  channelName: string | undefined;
  isChannelSelected?: boolean;
}

const ImageChannelTag: React.FC<ImageChannelProps> = ({
  channelName,
  isChannelSelected,
}) => {
  const navigate = useNavigate();
  const { setSelectedChannel } = useImagesContext();

  const handleClick = () => {
    setSelectedChannel(channelName);
    navigate(FEED_PATH);
  };

  if (!channelName) return null;
  return (
    <Tag onClick={handleClick} isChannelSelected={isChannelSelected}>
      # {channelName}
    </Tag>
  );
};

const Tag = styled.div<{ isChannelSelected?: boolean }>`
  text-align: center;
  height: 20px;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;

  background-color: ${LIGHT_GREY_FOR_TAG};
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;

  letter-spacing: 0.5px;

  cursor: pointer;
  transition: border-color 0.3s;
  white-space: nowrap;

  transition: border 0.1s;
  border: ${({ isChannelSelected }) =>
    isChannelSelected ? `1px solid ${PRIMARY_COLOR}` : 'none'};

  ${desktopMediaQuery} {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export default ImageChannelTag;
