import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants";
import { MAX_WIDTH } from "../../constants";

const StyledSlider = styled.input`
  width: 100%;

  // on desktop
  @media (min-width: ${MAX_WIDTH}px) {
    width: 256px;
  }

  -webkit-appearance: none;
  height: 25px;
  border-radius: 5px;
  background: transparent;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  border: 1px solid ${PRIMARY_COLOR};

  margin-top: -2px;
`;

export default StyledSlider;
