import styled from 'styled-components';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import FolderCard from './FolderCard';
import { DESKTOP_WIDTH } from '../../../constants';
import useHandleUpdateFolderPreviewImages from './useHandleUpdateFolderPreviewImages';

const FoldersTab = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const folders = loggedInUser?.folders;
  const foldersOrderReversed = folders && folders.slice().reverse();

  useHandleUpdateFolderPreviewImages(folders);

  if (!loggedInUser) return <div>Log in to save images</div>;
  if (!foldersOrderReversed) return <div>No folders...</div>;

  return (
    <Container>
      {foldersOrderReversed.map((folder) => (
        <FolderCard folder={folder} key={folder.id} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  margin-top: 16px;
  margin-bottom: 32px;

  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr); // 2 columns for mobile

  @media (min-width: ${DESKTOP_WIDTH}px) {
    grid-template-columns: repeat(4, 1fr); // 4 columns for desktop
    margin-left: 32px;
  }
`;

export default FoldersTab;
