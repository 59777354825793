import React from 'react';
import styled from 'styled-components';
import { SmallStyledButton } from '../../../common/StyledButton';
import { BORDER_COLOR, PRIMARY_COLOR } from '../../../../constants';
import { GenericSetState } from '../../../../types';

interface ImagesToShowButtonsProps {
  selectedOption: string;
  setSelectedOption: GenericSetState<'History' | 'Saved'>;
}

const ImagesToShowButtons: React.FC<ImagesToShowButtonsProps> = ({
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Container>
      <Button
        selected={selectedOption === 'History'}
        onClick={() => setSelectedOption('History')}
      >
        History
      </Button>
      <Button
        selected={selectedOption === 'Saved'}
        onClick={() => setSelectedOption('Saved')}
      >
        Saved
      </Button>
    </Container>
  );
};

const Button = styled(SmallStyledButton)<{ selected: boolean }>`
  border: 1px solid
    ${({ selected }) => (selected ? PRIMARY_COLOR : BORDER_COLOR)};
`;

const Container = styled.div`
  display: flex;
  gap: 12px;
  margin: 10px 0;
`;

export default ImagesToShowButtons;
