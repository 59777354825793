import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LIGHTER_GREY_COLOR, PRIMARY_COLOR } from '../../../constants';
import TimeLeft from './TimeLeft';
import AdLoader from './AdLoader';

interface LoadingProps {
  progress: number;
  timeLeft: number;
  inInpaintingPage?: boolean;
}

const LoadingBar: React.FC<LoadingProps> = ({
  progress,
  timeLeft,
  inInpaintingPage,
}) => {
  const showAd = false;
  return (
    <Container inInpaintingPage={inInpaintingPage}>
      <ProgressBar>
        <ProgressIndicator style={{ width: `${progress}%` }} />
      </ProgressBar>

      {showAd && <AdLoader />}
      <TimeLeft milliseconds={timeLeft} />
    </Container>
  );
};

export default LoadingBar;

const Container = styled.div<{ inInpaintingPage?: boolean }>`
  position: absolute;
  top: ${(props) => (props.inInpaintingPage ? '54%' : '94%')};
  width: 100%;
  height: 24px;
  margin-top: 14px;
`;

const progressBarAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 50px 50px; }
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${LIGHTER_GREY_COLOR};
  border-radius: 8px;
`;

const ProgressIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${PRIMARY_COLOR};
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
  padding-left: 8px;
  border-radius: 8px;
  animation: ${progressBarAnimation} 2s linear infinite;
`;
