import styled from 'styled-components';

import { useState } from 'react';
import {
  BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
  PRIMARY_COLOR,
  BORDER_COLOR,
} from '../../constants';
import { CloseButtonInModal } from '../common/CloseButtonInModal';
import { StyledButton } from '../common/StyledButton';
import TransitioningModal from './TransitioningModal';
import { MdAutorenew } from 'react-icons/md';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const HelpPopup = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '30px',
            width: '78%',
            maxHeight: '90vh',
            maxWidth: '350px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '40px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: PRIMARY_TEXT_COLOR,
          }}
          onClick={handleClose}
        />

        {showMoreInfo ? (
          <MoreInfo />
        ) : (
          <ShorterContent setShowMoreModal={setShowMoreInfo} />
        )}

        <OKButton onClick={handleClose}>OK ✅</OKButton>
      </Modal>
    </>
  );
};

const OKButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

const ShowMoreButton = styled(StyledButton)`
  border-color: ${BORDER_COLOR};
  margin-top: 16px;
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;

  // align at beginning of line
  text-align: left;
`;

const List = styled(ModalText)`
  margin-top: 8px;
`;

const TitleText = styled(ModalText)`
  font-size: 20px;
  text-align: center;
`;

const ShorterContent = ({
  setShowMoreModal,
}: {
  setShowMoreModal: (show: boolean) => void;
}) => (
  <>
    <TitleText>
      Create variations
      <MdAutorenew color={PRIMARY_COLOR} size={16} />
    </TitleText>

    <List>
      <p>
        ✨ Used to make small changes to the image <br />
        👥 Great for making many images of the same person <br />
        🎨 Try tweaking creativity and prompts for more variety <br />
      </p>
    </List>

    <ShowMoreButton onClick={() => setShowMoreModal(true)}>
      Read more 👀
    </ShowMoreButton>
  </>
);

const MoreInfo = () => (
  <ScrollableDiv>
    <TitleText>🔄 Create variations</TitleText>

    <ModalText>
      This new feature makes it easier to generate very similar images.
    </ModalText>

    <ModalText>
      One of the main use-cases is to generate more images of the same person 👥
      👥 Note: doesn't work 100% of the time!
    </ModalText>
    <ModalText>
      To give the images more variation, you can also try tweaking the prompts.
    </ModalText>

    <br />
    <List>
      <b>Tips:</b>
      <p>
        ✨ I suggest first trying to just hit generate without changing any
        settings
      </p>
      <p>🔧 After that I suggest playing around with creativity strength</p>
      <p>🎨 Then start modifying the prompts</p>
      <p>
        🤷‍♂️ Sometimes the AI gets 'stuck' to the pose, so try increasing strength
        creativity to get past this
      </p>
      <p>
        💪 If making bigger changes to prompt it might also be a good idea to
        increase creativity
      </p>
    </List>
    <br />

    <ModalText>
      Let’s grow together! Your feedback is crucial as we evolve. Reach out via
      Discord, Reddit, or Email.
    </ModalText>
  </ScrollableDiv>
);

const ScrollableDiv = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding-right: 16px;
  margin-top: 16px;
`;

export default HelpPopup;
