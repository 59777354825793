import SaveToast from "../../components/common/SaveToast";
import { toast } from "react-toastify";
import { FaHeart, FaRegHeart } from 'react-icons/fa6';
import styled from "styled-components";
import { FakeImage } from "../../types";
import { sharedIconStyle } from "./IconButtonStuff";
import { ICON_COLOR, PRIMARY_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";
import SaveToFolderModal from "../Modals/SaveToFolderModal/SaveToFolderModal";
import { useSaveImageLogic } from "../../hooks/images/useSaveImageLogic";
import { fadeIn } from "../ImageStuff/animations";

interface P {
  image: FakeImage;

  handleSaveIsClicked?: () => void; // this is just a hack to make sure it scrolls back to correct place in feed when saved
  smaller?: boolean;
  inLibrary?: boolean;
  inModal?: boolean;
  sharedImageRefId?: string; // if the saved image is shared, this is the id of the shared image. Used to later link to the shared image
}

export default function SaveButton(props: P) {
  const triggerToast = () => {
    const handleSaveToFolder = () => {
      setShowSaveToFolderModal(true);
    };

    toast(
      <SaveToast
        imageSrc={props.image.imageUrl}
        onSaveToFolder={handleSaveToFolder}
      />,
      {
        className: "SaveToast",
        autoClose: 4 * 1000,
        position: "bottom-center",
      }
    );
  };

  const {
    isSaved,
    showSaveToFolderModal,
    setShowSaveToFolderModal,
    handleSaveImage,
  } = useSaveImageLogic({ ...props, triggerToast });

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleSaveImage();
  }

  return (
    <>
      <Container onClick={handleOnClick} inLibrary={props.inLibrary}>
        {isSaved ? (
          <SaveIconActive smaller={props.smaller} inFeed={props.inModal} />
        ) : (
          <SaveIconInactive smaller={props.smaller} inFeed={props.inModal} />
        )}
      </Container>
      <SaveToFolderModal
        show={showSaveToFolderModal}
        setShow={setShowSaveToFolderModal}
        image={props.image}
      />
    </>
  );
}

const Container = styled.div<{ inLibrary?: boolean }>`
  margin-left: auto;
  margin-top: ${({ inLibrary }) => (inLibrary ? "9px" : "7px")};
  margin-left: ${({ inLibrary }) => (inLibrary ? "4px" : "auto")};
`;

const biggerFontSize = "28px";
const smallerFontSize = "24px";
const marginBottom = "3px";
const SaveIconInactive = styled(FaRegHeart)<{
  smaller?: boolean;
  inFeed?: boolean;
}>`
  ${sharedIconStyle}
  animation: ${fadeIn} 0.1s ease-in-out;
  color: ${ICON_COLOR};
  font-size: ${({ smaller }) => (smaller ? smallerFontSize : biggerFontSize)};
  margin-bottom: ${({ smaller }) => (smaller ? marginBottom : "0px")};
  ${desktopMediaQuery} {
    &:hover {
      // no hover effect if inFeed
      color: ${({ inFeed }) => (inFeed ? ICON_COLOR : PRIMARY_COLOR)};
    }
  }
`;

const SaveIconActive = styled(FaHeart)<{
  smaller?: boolean;
  inFeed?: boolean;
}>`
  ${sharedIconStyle}

  color: ${PRIMARY_COLOR};
  animation: ${fadeIn} 0.1s ease-in-out;
  font-size: ${({ smaller }) => (smaller ? smallerFontSize : biggerFontSize)};
  margin-bottom: ${({ smaller }) => (smaller ? marginBottom : "0px")};
  ${desktopMediaQuery} {
    &:hover {
      // no hover effect if inFeed
      color: ${({ inFeed }) => (inFeed ? ICON_COLOR : PRIMARY_COLOR)};
    }
  }
`;
