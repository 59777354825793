import { useEffect } from 'react';

type SortSetting = string;

export const setSortSettingsInProfilesStorage = (
  newSortSetting: SortSetting,
) => {
  localStorage.setItem('sortSetting', newSortSetting);
};

export const getSortSettingsInProfilesStorage = (): SortSetting => {
  const sortSetting = localStorage.getItem('sortSetting');
  if (!sortSetting) return 'Latest';
  return sortSetting as SortSetting;
};

export const useUpdateSetSortyByInProfile = (
  setSortBy: (value: string) => void,
) => {
  useEffect(() => {
    const sortOptionInLocalStorage = getSortSettingsInProfilesStorage();
    setSortBy(sortOptionInLocalStorage);
  }, []);
};

export type SortSettingInFeed =  'hot' | 'new' | 'top';

// Currently not used, should be removed?
export const setSortSettingInFeed = (newSortSetting: SortSettingInFeed) => {
  localStorage.setItem('sortSettingInFeed', newSortSetting);
};

export const getSortSettingInFeed = (): SortSettingInFeed => {
  const sortSetting = localStorage.getItem('sortSettingInFeed');
  if (!sortSetting) return 'hot';
  return sortSetting as SortSettingInFeed;
};
