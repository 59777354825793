import styled from "styled-components";
import {
  BORDER_COLOR,
  LIGHTER_BG_FOR_TOAST,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../constants";

type SaveToastProps = {
  imageSrc: string;
  onSaveToFolder: () => void;
};

const SaveToast: React.FC<SaveToastProps> = ({ imageSrc, onSaveToFolder }) => (
  <ToastContent>
    <ToastImage src={imageSrc} alt="Saved Image" />
    <ToastText>Saved</ToastText>
    <ToastButton onClick={onSaveToFolder}>Save to Folder</ToastButton>
  </ToastContent>
);

const ToastContent = styled.div`
  display: flex;
  align-items: center;
  background: ${LIGHTER_BG_FOR_TOAST};
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.9);

  border-radius: 8px;
  padding: 8px;

  width: 100%;

  z-index: 100;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    max-width: 95%;
    margin-left: auto;
  }

  //width les than 350
  @media (max-width: 350px) {
    max-width: 90%;

    margin-left: 0;
  }
`;

const ToastImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
  border: 1px solid ${BORDER_COLOR};
`;

const ToastText = styled.span`
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 16px;
  font-weight: 600;

  white-space: nowrap;

  margin-right: 56px;
`;

const ToastButton = styled.button`
  background: none;
  border: none;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition: opacity 0.3s;
  white-space: nowrap;

  &:hover {
    opacity: 0.7;
  }

  //if on mobile
  @media (max-width: 768px) {
    margin-left: auto;
    padding-right: 16px;
  }
`;

export default SaveToast;
