import { useEffect, RefObject } from "react";

/**
 * Custom hook that handles click events outside of specified elements.
 *
 * @param refArray - An array of RefObjects representing the elements to be checked for click outside.
 * @param action - A callback function to be executed when a click outside is detected.
 */
const useHandleClickOutside = (
  refArray: Array<RefObject<HTMLElement>>,
  action: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutside = refArray.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );
      if (isOutside) {
        action();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refArray, action]);
};

export default useHandleClickOutside;
