import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants";
import {
  Section,
  ExampleImage,
  SmallMargin,
  TitleTextWithGradient,
} from "./CommonComponents";
import {
  AiOutlineEdit,
  AiOutlinePlayCircle,
  AiOutlineQuestionCircle,
  AiOutlineRead,
  AiOutlineSetting,
} from "react-icons/ai";

const bigIconSize = "50px";

const Instructions = () => {
  return (
    <Section as="article">
      <TitleTextWithGradient>Quick guide </TitleTextWithGradient>
      <ExampleImage src={"example4.jpg"} />
      <InstructionsText>
        <AiOutlineSetting color={PRIMARY_COLOR} size={bigIconSize} />
        <h2>Step 1</h2>
        Select a style 🧑‍🎨 OR select (remix) an image to use as a base ️
        <SmallMargin />
        <AiOutlineEdit color={PRIMARY_COLOR} size={bigIconSize} />
        <h2>Step 2</h2>
        Write what you want to generate in the prompt field 💬
        <SmallMargin />
        <AiOutlinePlayCircle color={PRIMARY_COLOR} size={bigIconSize} />
        <h2>Step 3</h2>
        Click Generate and wait ⌛
        <SmallMargin />
        <AiOutlineRead color={PRIMARY_COLOR} size={bigIconSize} />
        <h2>Step 4</h2>
        Browse the Feed for AI Nudes while waiting 🔍
        <SmallMargin />
        <AiOutlineQuestionCircle color={PRIMARY_COLOR} size={bigIconSize} />
        <h2>Extra</h2>
        Check the advanced options for more control and click the{" "}
        <AiOutlineQuestionCircle color={PRIMARY_COLOR} /> icons for more info
      </InstructionsText>
    </Section>
  );
};

export default Instructions;

const InstructionsText = styled.div`
  max-width: 420px;

  margin-top: 36px;
  margin-bottom: 36px;
  h2 {
    margin-top: 8px;
  }
`;
