import { FakeImage } from '../../types';
import { getEngineTypeWithModelId } from '../../utils/engineHelpers';
import { ENGINE_CATEGORIES } from '../FeedPage/EngineFilterDropdown';

export function useHandleFiltering() {
  const handleFiltering = (
    images: FakeImage[],
    searchTerm: string,
    engineFilter: string,
  ): FakeImage[] => {
    const filterImagesBySearchTerm = (images: FakeImage[]) => {
      if (searchTerm === '') return images;

      const searchWords = searchTerm.toLowerCase().split(/\s|,|\(|\)|:/);

      const filteredImages = images.filter((image) => {
        const prompt = image.prompt.replace(/\(|\)|,|:|\./g, ' ');
        const promptWords = prompt.toLowerCase().split(' ');

        return searchWords.every((word) => promptWords.includes(word));
      });
      return filteredImages;
    };

    const filteredImages = filterImagesBySearchTerm(images);

    if (ENGINE_CATEGORIES.includes(engineFilter)) {
      if (engineFilter === 'All') {
        return filteredImages;
      }
      return filteredImages.filter((image) => {
        const engType = image.engine.type
          ? image.engine.type
          : getEngineTypeWithModelId(image.engine.modelId);
        return engType === engineFilter.toLowerCase();
      });
    }

    return filteredImages.filter(
      (image) => image.engine.modelId === engineFilter,
    );
  };
  return handleFiltering;
}
