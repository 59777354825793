import { useEffect, useState } from "react";
import { FolderInterface } from "../../../types";
import { getBaseUrlForFetching } from "../../../App";
import { useCookies } from "react-cookie";

/**
 * Finds all folders that don't have a preview image url that's in my own S3 and updates them
 * See the serverless function updateFolderPreviewImages for more info
 */
const useHandleUpdateFolderPreviewImages = (folders?: FolderInterface[]) => {
  const [{ token }] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);
  const updateFolderPreviewImages = async (
    foldersToUpdate: FolderInterface[]
  ) => {
    if (loading || !token) return;

    setLoading(true);
    const folderIds = foldersToUpdate.map((folder) => folder.id);

    const functionName = "updateFolderPreviewImages";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ folderIds, token }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!folders) {
      return;
    }
    const filteredFolders = folders.filter(
      (folder) => !folder.previewImageUrl.includes("onlyfakes-images-sdapi") //<-- my own S3
    );

    if (filteredFolders.length === 0) {
      return;
    }

    updateFolderPreviewImages(filteredFolders);
  }, [folders]);
};

export default useHandleUpdateFolderPreviewImages;
