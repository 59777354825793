import React from 'react';
import styled from 'styled-components';
import UploadReferenceImage from '../AdvancedOptions/UploadReferenceImage';

interface Props {}

const CreateVariationsSection: React.FC<Props> = () => {
  return (
    <Container>
      <Text>Creating variations of this image:</Text>
      <UploadReferenceImage />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 90%;
`;

const Text = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 12px;
`;

export default CreateVariationsSection;
