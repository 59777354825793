import { useState } from "react";
import { BiCoin } from "react-icons/bi";
import styled from "styled-components";
import { GOLD_COLOR } from "../../../../constants";
import LoadingIndicator from "../../../common/LoadingIndicator";
import { StyledButton } from "../../../common/StyledButton";
import useClaimReferralCredits from "../../../../hooks/userHandling/useClaimReferralCredits";
import { toast } from "react-toastify";
import { useLoggedInUserContext } from "../../../../context/LoggedInUserContextProvider";
import { addCreditsToUserState } from "./FreeCreditsModal";

interface Props {
  unclaimedReferrals?: number;
}
const UnclaimedReferralsSection = ({ unclaimedReferrals }: Props) => {
  const { setLoggedInUser } = useLoggedInUserContext();

  if (
    unclaimedReferrals === null ||
    unclaimedReferrals === undefined ||
    unclaimedReferrals === 0
  )
    return null;

  const setUnclaimedToZero = () => {
    setLoggedInUser((user) => {
      if (!user) return user;
      return {
        ...user,
        unclaimedReferrals: 0,
      };
    });
  };

  const amountToClaim = unclaimedReferrals * 50;

  const claimReferralCredits = useClaimReferralCredits();

  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    const result = await claimReferralCredits();
    if (result.status !== 200) {
      toast.error("Something went wrong 😢");
    } else {
      addCreditsToUserState(amountToClaim, setLoggedInUser);
      toast.success("Referral credits claimed! 🎉");
      setUnclaimedToZero();
    }
    setLoading(false);
  };

  return (
    <ButtonContainer onClick={handleClick} disabled={loading}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          Claim referral credits: {amountToClaim}
          <BiCoin size={18} color={GOLD_COLOR} />
        </>
      )}
    </ButtonContainer>
  );
};

const ButtonContainer = styled(StyledButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 14px;

  border-color: ${GOLD_COLOR};
`;

export default UnclaimedReferralsSection;
