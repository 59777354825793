import { BiUpvote } from 'react-icons/bi';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { imageHoverAnimationInFeed } from '../../../styleHelpers';
import { SharedImage } from '../../../types';
import { SmoothlyLoadingImage } from '../../common/SmoothlyLoadingImage';
import { PLACEHOLDER_IMAGE_PATH } from '../GeneratedImage';
import useHandleMarkingImageAsSeen from '../../../hooks/feed/useHandleMarkingImageAsSeen';
import AlreadySeenImagesStorage from '../../../localStorage/alreadySeenImagesStorage';
import { FaImages } from 'react-icons/fa6';

interface ImageTemplateNewProps {
  image: SharedImage;
  index: number;
  handleClickImageOpen: (image: SharedImage, index: number) => void;
  shouldMarkAsSeen?: boolean;
}

const ImageInGrid = ({
  image,
  index,
  handleClickImageOpen,
  shouldMarkAsSeen,
}: ImageTemplateNewProps) => {
  const imageUrl = image?.image?.imageUrl;
  const isAlbum = image.album && image.album.length > 1;
  const [loaded, setLoaded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useHandleMarkingImageAsSeen({
    containerRef,
    shouldMarkAsSeen,
    imageId: image.id,
  });

  const handleImageError = () => {
    const target = containerRef.current;
    if (!target) return;
    target.style.display = 'none'; // hide the image
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    AlreadySeenImagesStorage.addImageId(image.id);
    e.preventDefault();
    handleClickImageOpen(image, index);
  };

  const channelName = image.channel?.name;
  const altTextToRender = channelName ? `Image from a user in channel ${channelName}` : 'A feed image';

  if (!imageUrl) return null;

  return (
    <Container onClick={handleClick} ref={containerRef}>
      <Image
        key={image.id}
        src={imageUrl || PLACEHOLDER_IMAGE_PATH}
        alt={altTextToRender}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        id={image.id}
        loaded={loaded}
        onError={handleImageError}
      />
      <AmountOfUpvotesBadge>
        <BiUpvote size={12} />
        {image.amountOfRemixes}
      </AmountOfUpvotesBadge>

      {isAlbum && (
        <IsAlbumBadge>
          <FaImages />
        </IsAlbumBadge>
      )}
    </Container>
  );
};

export default ImageInGrid;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 260px;
  max-height: 340px;
`;

const AmountOfUpvotesBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px;
  font-size: 12px;
  border-radius: 0 10px 0 0;
  cursor: default;
`;

const IsAlbumBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 10px 0 0 0;
  cursor: default;
`;

const Image = styled(SmoothlyLoadingImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${imageHoverAnimationInFeed}
`;
