import styled from "styled-components";
import LabelWithTooltip from "../../../components/common/LabelWithTooltip";
import { StyledInputSmall } from "../../../components/common/StyledInputSmall";

interface P {
  cfg: number;
  setCfg: (cfg: number) => void;
}

const TOOLTIP_TEXT =
  "Classifier-Free Guidance tells the AI how much prompt instructions are followed. Usually not necessary to change";

const CFGInputBox = ({ cfg, setCfg }: P) => {
  const value = cfg === 0 ? "" : cfg.toString();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const numValue = newValue === "" ? 0 : parseFloat(newValue);

    if (!isNaN(numValue) && numValue <= 15) {
      setCfg(numValue);
    }
  };

  return (
    <BiggerContainer>
      <LabelWithTooltip labelText="CFG" tooltipText={TOOLTIP_TEXT} />
      <SmallerContainer>
        <StyledInputSmall
          type="number"
          max={15}
          step={0.5}
          value={value}
          onChange={handleChange}
        />
      </SmallerContainer>
    </BiggerContainer>
  );
};

const BiggerContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 17px;

  max-width: 364px;

  margin-bottom: 16px;
`;

const SmallerContainer = styled.div`
  width: 50%;
  margin-left: 34px;

  margin-top: -10px;
`;

export default CFGInputBox;
