import { getBaseUrlForFetching } from '../App';
import { headers } from './misc/helpers';

/**
 * Returns a function that fetches data from the server.
 *
 * Also does await response.json() and returns that.
 * @param functionName - The name to the serverless function. No leading slash.
 */
export async function customFetch(
  functionName: string,
  payload?: any,
  method: string = 'POST',
) {
  const fullUrl = `${getBaseUrlForFetching()}/${functionName}`;
  const response = await fetch(fullUrl, {
    method,
    headers,
    body: payload ? JSON.stringify(payload) : null,
  });
  if (response.status !== 200) {
    console.error(`Failed to fetch ${functionName}`);
    return;
  }
  return await response.json();
}
