import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "styled-components";
import { desktopStyleToAdd } from "../../styleHelpers";
import GeneratedInpaint from "./GeneratedInpaint";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import { useState, useEffect } from "react";
import { useImagesContext } from "../../context/ImagesContextProvider";
import { MAX_WIDTH } from "../../constants";
import ButtonsBelowInpaintedImage from "./ButtonsBelowInpaintedImage";

const GeneratedInpaintsContainer = () => {
  const { activeImage } = useGeneralContext();
  const { editedImages } = useImagesContext();
  const activeImageHeight = activeImage?.height;

  const [selectedItem, setSelectedItem] = useState<number>(0);

  const [carouselHeight, setCarouselHeight] = useState(
    activeImageHeight ?? 512
  );

  useEffect(() => {
    if (activeImageHeight) {
      setCarouselHeight(activeImageHeight);
    }
  }, [activeImage, activeImageHeight, editedImages]);

  const handleChange = (index: number) => {
    setSelectedItem(index);
  };

  return (
    <Container>
      <ImageCarousel
        height={carouselHeight}
        showIndicators={editedImages.length < 5}
        showThumbs={false}
        showStatus={false}
        selectedItem={editedImages.length - 1}
        emulateTouch
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        useKeyboardArrows={true}
        onChange={handleChange}
      >
        {editedImages.map((image) => (
          <GeneratedInpaint image={image} key={image.trackId} />
        ))}
      </ImageCarousel>
      <ButtonsBelowInpaintedImage selectedItem={selectedItem} />
    </Container>
  );
};

export default GeneratedInpaintsContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageCarousel = styled(Carousel)<{ height: number }>`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  max-height: ${(props) => props.height}px;
  padding-left: 10px;
  margin-bottom: 8px;
  ${desktopStyleToAdd}

  display: flex;
  justify-content: center;
`;
