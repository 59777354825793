import styled from 'styled-components';
import { desktopMediaQuery } from '../../styleHelpers';
import { ExampleImage } from './CommonComponents';

const SEOSection = () => {
  return (
    <ResponsiveContainer>
      <CardContainer>
        <CardTitle>Only Fakes app - free NSFW AI Adult Image Generator</CardTitle>
        <CardText>
          Experience a free, NSFW AI <b>nude image generator</b> that allows
          users to generate, edit, and share NSFW AI images. Join Only Fakes in creating unrestricted AI Images with the huge community! Just
          have fun with our Free AI Image Generator, no restrictions, no sign-up.
        </CardText>
      </CardContainer>
      <ExampleImage
        src={
          'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/e4171d10-d03f-474e-9096-173174ac1104.jpeg'
        }
      />
    </ResponsiveContainer>
  );
};

export default SEOSection;

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    max-width: 80vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70vw;
  max-width: 450px;
  padding: 24px;

  margin-top: 64px;
  margin-bottom: 64px;

  background-color: #271a3d;
  border-radius: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
`;

const CardTitle = styled.h1`
  font-size: 24px;
  margin-top: 0;
`;

const CardText = styled.div`
  li {
    list-style-type: none;
    margin-bottom: 8px;
  }
`;
