import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { DESKTOP_WIDTH } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

export const CloseButtonInFeedModal = styled(AiOutlineClose)`
  z-index: 100;
  position: absolute;
  top: 3vh;
  right: 16px;
  font-size: 36px;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  color: white;
  border-radius: 5%;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    top: 0;
    right: 0;
    font-size: 60px;
    padding: 16;
  }
`;
