import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CreditIndicatorInTopBar from './CreditIndicatorInTopBar';
import TopBarMenu from './TopBarMenu';
import { BsPersonFill } from 'react-icons/bs';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { getTimeUntilNextFreeCredits } from './helpers';
import { SmallStyledButton } from '../StyledButton';
import { desktopMediaQuery } from '../../../styleHelpers';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { useModalsContext } from '../../../context/ModalsContextProvider';
import { BiMenu } from 'react-icons/bi';

const TopRightCornerPartOfTopBar: React.FC = ({}) => {
  const { showTopRightMenu, setShowTopRightMenu } = useGeneralContext();
  const buttonRef = useRef(null);
  const { setAuthModalVariant, setIsAuthModalOpen } = useModalsContext();

  const closePopup = () => {
    setShowTopRightMenu(false);
  };

  const defaultX = window.outerWidth ?? 1500;
  const [popupX, setPopupX] = useState(defaultX * 0.9);
  const togglePopup = () => {
    // get  mouse coordinates of the click to position the popup
    const { clientX } = window.event as MouseEvent;
    setPopupX(clientX);
    setShowTopRightMenu(!showTopRightMenu);
  };

  const { loggedInUser } = useLoggedInUserContext();

  useEffect(() => {
    if (!loggedInUser) return;
    if (!loggedInUser.verifiedEmail) return;
    if (loggedInUser.type === 'gold') return;
    const timeUntil = getTimeUntilNextFreeCredits(loggedInUser);
    const isAllowedToClaim = timeUntil === '00:00:00';
    // if is allowed to claim, setpopup true
    if (isAllowedToClaim) {
      setShowTopRightMenu(true);
    }
  }, [loggedInUser, loggedInUser?.verifiedEmail]);

  const handleSignUp = () => {
    setAuthModalVariant('register');
    setIsAuthModalOpen(true);
  };

  return (
    <Container>
      {!loggedInUser && (
        <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
      )}
      <IconContainer ref={buttonRef} onClick={togglePopup}>
        <CreditIndicatorInTopBar />
        <BsPersonFill size={24} />
        <BiMenu size={26} />
      </IconContainer>

      <TopBarMenu
        buttonRef={buttonRef}
        popupX={popupX}
        showPopup={showTopRightMenu}
        closePopup={closePopup}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SignUpButton = styled(SmallStyledButton)`
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: 8px;
  ${desktopMediaQuery} {
    margin-right: 16px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  gap: 4px;
`;

export default TopRightCornerPartOfTopBar;
