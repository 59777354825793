import { useCookies } from 'react-cookie';
import { generateTrackId, getCreditsRequired } from '../../components/helpers';
import { MS_IN_SECOND } from '../../constants';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { getWaitTimeForSpeedMode } from '../../generationConstants';
import { FakeImage, SpeedModeType } from '../../types';
import { headers, scrollToTop } from '../misc/helpers';
import { getBaseUrlForFetching } from '../../App';
import useHandleDeductCredits from './useHandleDeductCredits';
import { toast } from 'react-toastify';
import { handlePopUnderAd } from './helpers';

interface Returns {
  status: number;
  imageUrl?: string;
}

const useInpaintImage = () => {
  const { setEditedImages } = useImagesContext();
  const {
    setGenerationStartedTimestamp,
    setEstimateGenerationReadyTimestamp,
    setLoading,
  } = useGeneralContext();
  const handleDeductCredits = useHandleDeductCredits();

  const [, setFetchToken] = useCookies(['fetchToken']);
  const inpaintImage = async (
    maskImageUrl: string,
    activeImage: FakeImage,
    prompt: string,
    negativePrompt: string,
    imageStrength: number,
    seed: number,
    speedMode: SpeedModeType,
  ): Promise<Returns> => {
    if (activeImage.isEnhanced) {
      toast.error("You can't inpaint an enhanced image (yet)");
      return { status: 500 };
    }

    const functionName = 'inpaintImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const imageToInpaintUrl = activeImage.imageUrl;
    //const modelId = activeImage.engine.modelId;
    const modelId = 'realistic_vision_v3_inpainting';
    const { width, height } = activeImage;

    const trackId = generateTrackId();

    const imageToAdd: FakeImage = {
      ...activeImage,
      trackId,
      generating: true,
      isInpainted: true,
      imageUrl: 'generating',
    };
    setEditedImages((prev) => [...prev, imageToAdd]);

    const expectedWait = getWaitTimeForSpeedMode(speedMode) * MS_IN_SECOND;
    const now = Date.now();
    setGenerationStartedTimestamp(now);
    setEstimateGenerationReadyTimestamp(now + expectedWait);

    const creditsRequired = getCreditsRequired(speedMode);
    const handleDeductCreditsResult = handleDeductCredits(creditsRequired);
    if (handleDeductCreditsResult !== 'ok') {
      toast.error("You don't have enough credits to use this speed mode");
      setLoading(false);
      return { status: 500 };
    }

    scrollToTop();

    const resultOfAdStuff = handlePopUnderAd(speedMode);
    if (!resultOfAdStuff) {
      return { status: 500 };
    }

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({
        maskImageUrl,
        imageToInpaintUrl,
        width,
        height,
        prompt,
        negativePrompt,
        modelId,
        imageStrength,
        trackId,
        seed,
        speedMode,
      }),
      headers,
      credentials: 'include',
    });

    const { status } = response;

    if (status === 200) {
      const data = await response.json();
      if (data.fetchToken) {
        const maxAge = 7776000;
        setFetchToken('fetchToken', data.fetchToken, {
          path: '/',
          secure: true,
          sameSite: 'strict',
          maxAge,
        });
      }
      return { status };
    } else {
      return { status };
    }
  };
  return inpaintImage;
};

export default useInpaintImage;
