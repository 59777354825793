import { useImagesContext } from '../../context/ImagesContextProvider';
import { FeedChannel } from '../../types';
import { customFetch } from '../customFetch';

const useFeedChannels = () => {
  const { feedChannels, setFeedChannels } = useImagesContext();

  /**
   * Fetches the feed channels from the server
   * Uses the cache if the feed channels are already fetched
   * @param forceFetch - If true, the feed channels will be fetched from the server even if they are already in the cache
   * @returns The feed channels
   */
  const fetchFeedChannels = async (
    forceFetch?: boolean,
  ): Promise<FeedChannel[]> => {
    // This returns the cached feed channels if they exist
    if (feedChannels.length !== 0 && !forceFetch) {
      return feedChannels;
    }

    const functionName = 'getFeedChannels';
    const channels = (await customFetch(functionName)) as FeedChannel[];

    if (!channels) {
      console.error('Failed to fetch feed channels');
      return [];
    }

    const sortedByPopularity = channels.sort(
      (a, b) => b.imageCount - a.imageCount,
    );

    setFeedChannels(sortedByPopularity);

    return channels;
  };

  // updates the feed channels in the cache
  const updateFeedChannelsInCache = () => {
    fetchFeedChannels(true);
  };

  return { fetchFeedChannels, updateFeedChannelsInCache };
};

export default useFeedChannels;
