import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { StyledButton } from '../../components/common/StyledButton';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaArrowRight } from 'react-icons/fa6';

const NewCTAButton = () => {
  const navigate = useNavigate();

  const handleTakeToApp = () => {
    navigate('/app');
  };

  return (
    <Container>
      <GetStartedButton onClick={handleTakeToApp}>
        Enter The Site <FaArrowRight />
      </GetStartedButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 85%;
  ${desktopMediaQuery} {
    width: 90%;
  }
`;

const GetStartedButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  gap: 8px;

  width: 100%;

  border-width: 2px;

  ${desktopMediaQuery} {
    max-width: 500px;
    font-size: 20px;
    padding: 12px;
  }
`;

export default NewCTAButton;
