import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { SharedImage } from '../../types';

const useGetIsImageOwnedByLoggedInUser = (image: SharedImage | null) => {
  const { loggedInUser } = useLoggedInUserContext();

  if (!loggedInUser || !image) return false;

  const userIdFromImage = image?.userId;
  if (userIdFromImage) {
    return userIdFromImage === loggedInUser.id;
  }

  const usernameFromImage = image?.user?.username;
  if (usernameFromImage) {
    return usernameFromImage === loggedInUser.username;
  }

  return false;
};

export default useGetIsImageOwnedByLoggedInUser;
