import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { DESKTOP_WIDTH } from "../../constants";
import { isSafari } from "../../styleHelpers";

interface P {
  value: string;
  setValue: (value: string) => void;
  autofocus?: boolean;
  placeholder: string;
  negPrompBox?: boolean;
  id?: string;
  withIcon?: boolean;
}

const StylishInput = ({
  value,
  setValue,
  autofocus,
  placeholder,
  negPrompBox,
  id,
  withIcon,
}: P) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (negPrompBox && textareaRef) {
      textareaRef.current?.scrollTo(0, 30);
    }
  }, [negPrompBox]);

  const getHeight = (): string => {
    // Base height and character count at which height starts to increase
    const baseHeight = 21; // Minimum height
    const minHeightIncrement = 10; // Height increment for the first threshold
    const heightIncrement = 15; // Additional height increment for each threshold
    const baseIncrementThreshold = 35; // Character count at which height starts to increase
    const incrementStep = 38; // Character count step for each height increment

    if (value.length <= baseIncrementThreshold) {
      return `${baseHeight}px`;
    } else {
      // Calculate extra height based on character count beyond the base increment threshold
      const extraHeight =
        minHeightIncrement +
        Math.ceil((value.length - baseIncrementThreshold) / incrementStep) *
          heightIncrement;
      return `${baseHeight + extraHeight}px`;
    }
  };

  return (
    <InputBox
      autoCapitalize="none"
      id={id}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        // if on mobile, allow enter to create new line
        if (window.innerWidth < DESKTOP_WIDTH) {
          return;
        }
        if (e.key === "Enter" && e.shiftKey) {
          return;
        }
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      autoFocus={autofocus}
      placeholder={placeholder}
      style={{ height: getHeight() }}
      minLength={3}
      maxLength={750}
      ref={textareaRef}
      withIcon={withIcon}
    />
  );
};

const InputBox = styled.textarea<{ withIcon?: boolean }>`
  border: none;
  border-radius: 8px;
  padding: 10px;
  padding-bottom: 10px;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  resize: none;

  font-size: ${() => (isSafari ? "16px" : "14px")};

  width: 90%;
`;

export default StylishInput;
