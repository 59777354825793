import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import {
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import { BlogPost } from '../../types';

const BlogPostCard: React.FC<BlogPost> = ({ title, excerpt, date, slug }) => {
  const navigate = useNavigate();
  const navToSlug = () => {
    navigate(`/blog/${slug}`);
  }
  return (
    <PostContainer onClick={navToSlug}>
      <PostTitle>{title}</PostTitle>
      <PostExcerpt>{excerpt}</PostExcerpt>
      <PostDate>{new Date(date).toLocaleDateString()}</PostDate>
      <div>
        <ReadMoreLink to={slug}>Read More</ReadMoreLink>
      </div>
    </PostContainer>
  );
};

const PostContainer = styled.div`
  border: 1px solid ${PRIMARY_COLOR};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  width: 600px;
  max-width: 80vw;

  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-2px);
  }
`;

const PostTitle = styled.h2`
  color: ${PRIMARY_TEXT_COLOR};
  margin-bottom: 10px;
`;

const PostExcerpt = styled.p`
  color: ${SECONDARY_TEXT_COLOR};
  margin-bottom: 15px;
`;

const PostDate = styled.span`
  color: ${SECONDARY_TEXT_COLOR};
  font-size: 0.9em;
`;

const ReadMoreLink = styled(Link)`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export default BlogPostCard;
