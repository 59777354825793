import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  LIGHTER_GREY_FOR_HOVER_EFFECT,
  PRIMARY_COLOR,
} from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import { AiOutlineClose } from 'react-icons/ai';
import userSettingsInStorage from '../../../localStorage/userSettingsInStorage';

interface IComboBox {
  options: string[];
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  onFocusCallback: () => void;
}

const replaceAllSpecialCharactersExceptDash = (input: string) => {
  return input.replace(/[^a-zA-Z0-9-]/g, '');
}

const ChannelsComboBox = ({
  options,
  value,
  setValue,
  placeholder,
  onFocusCallback,
}: IComboBox) => {
  const [inputValue, setInputValue] = useState(value);
  const [showOptions, setShowOptions] = useState(false);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const lowerCasedName = newName.toLowerCase();
    const replacedSpaces = lowerCasedName.replace(/ /g, '-');
    const allowedCharacters = replaceAllSpecialCharactersExceptDash(replacedSpaces);
    setInputValue(allowedCharacters);
    setShowOptions(true);
    setValue(allowedCharacters);
  };

  const handleOptionClick = (option: string) => {
    setInputValue(option);
    setValue(option);
    setShowOptions(false);
    userSettingsInStorage().lastPostedChannel.set(option);
  };

  const handleOnFocus = () => {
    onFocusCallback();
    setShowOptions(true);
  };

  useEffect(() => {
    const lastPostedChannel = userSettingsInStorage().lastPostedChannel.get();
    if (lastPostedChannel) {
      setInputValue(lastPostedChannel);
      setValue(lastPostedChannel);
    }
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearInput = () => {
    setInputValue('');
    inputRef?.current?.focus();
  };

  const hasText = inputValue.length > 0;

  const isExactMatch = options.includes(inputValue);

  return (
    <Container>
      {hasText && '#'}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={() => setTimeout(() => setShowOptions(false), 200)}
        placeholder={placeholder}
        ref={inputRef}
        minLength={3}
        maxLength={15}

        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (filteredOptions.length > 0) {
              handleOptionClick(filteredOptions[0]);
            } else {
              handleOptionClick(inputValue);
            }
          }
        }}
      />
      {showOptions && (
        <ul>
          {!isExactMatch && inputValue.length > 0 && (
            <li onClick={() => handleOptionClick(inputValue)}>
              Create new: # {inputValue}
            </li>
          )}
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {'# '}
              {option}
            </li>
          ))}
        </ul>
      )}
      {hasText && <StyledXButton onClick={handleClearInput} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border: 1.5px solid ${BORDER_COLOR};
  width: 100%;
  padding: 8px;
  border-radius: 8px;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }

  input {
    outline: none;
    border: none;
    width: 99%;
  }

  ul {
    position: absolute;
    top: 100%;
    left: -10%;
    width: 90%;
    background-color: ${BACKGROUND_COLOR};
    border: none;
    z-index: 1;
    min-height: 200px;

    li {
      cursor: pointer;
      list-style-type: none;
      border-bottom: 0.5px dotted ${BORDER_COLOR};
      margin-bottom: 12px;

      &:hover {
        background-color: ${LIGHTER_GREY_FOR_HOVER_EFFECT};
      }
    }
  }
`;

const StyledXButton = styled(AiOutlineClose)`
  align-self: center;
  cursor: pointer;
  z-index: 2;
`;

export default ChannelsComboBox;
