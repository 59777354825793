import { useState } from "react";
import styled from "styled-components";
import {
  BACKGROUND_COLOR,
  DESKTOP_WIDTH,
  PRIMARY_COLOR,
} from "../../constants";
import { StyledButton } from "../common/StyledButton";
import Terms from "../../pages/Terms/Terms";
import PrivacyPolicy from "../../pages/Terms/PrivacyPolicy";
import TransitioningModal from "./TransitioningModal";
import { desktopMediaQuery } from "../../styleHelpers";

interface I {
  showModal: boolean;
  handleAcceptAndClose: () => void;
}

const TermsModal = ({ showModal, handleAcceptAndClose }: I) => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const handleAccept = () => {
    handleAcceptAndClose();
    setShowTerms(false);
    setShowPolicy(false);
  };

  const isShowingPolicyOrTerms = showPolicy || showTerms;
  return (
    <Modal
      isOpen={showModal}
      slower
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: "16px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => alert("You must accept the terms and conditions")}
      isShowingPolicyOrTerms={isShowingPolicyOrTerms}
    >
      {showPolicy ? (
        <PrivacyPolicy setShowPolicy={setShowPolicy} />
      ) : showTerms ? (
        <Terms />
      ) : (
        <ModalContent>
          <TitleText>Accept Terms and Conditions</TitleText>
          <ModalText>
            This website contains adult content that has been generated by AI
            and is intended for mature audiences only.
          </ModalText>
          <ModalText>
            By accessing the site, you confirm that you are 18 years or older
            and agree to our
            <ClickableLink onClick={() => setShowTerms(true)}>
              {" "}
              Terms and Conditions.
            </ClickableLink>
          </ModalText>
          <ModalText>
            Our
            <ClickableLink onClick={() => setShowPolicy(true)}>
              {" "}
              Privacy Policy{" "}
            </ClickableLink>
            outlines our data collection and usage practices, and we use cookies
            for basic analytics and spam prevention. Any similarities between
            the content on this website and real people are purely coincidental.
          </ModalText>
          <ModalText>🚫 No Lolicon 🚫</ModalText>
          <ModalText>🚫 No Real People 🚫</ModalText>
        </ModalContent>
      )}
      <AcceptButton onClick={handleAccept}>I accept</AcceptButton>
    </Modal>
  );
};

const ClickableLink = styled.span`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  cursor: pointer;
  ${desktopMediaQuery} {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Modal = styled(TransitioningModal)<{ isShowingPolicyOrTerms: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 16px;

  width: 85vw;
  height: 73vh;
  @media (max-width: 430px) {
    font-size: 14px;
    margin-top: 5vh;
  }
  z-index: 200;
  margin: auto;
  padding: 16px;
  padding-top: 8px;
  @media (min-width: ${DESKTOP_WIDTH}px) {
    width: 600px;
    padding: 20px;
    max-width: 600px;
    max-height: ${(props) =>
      props.isShowingPolicyOrTerms ? "900px" : "450px"};
    margin-top: 20px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const ModalText = styled.div`
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)``;

export default TermsModal;
