import { getBaseUrlForFetching } from '../../App';
import { LoginDetails } from '../../types';
import { headers } from '../misc/helpers';
import useSetUserCookie from './useSetUserCookie';

type ResultOfLogin = {
  status: number;
  token?: string;
};

const useHandleLogin = () => {
  const setUserCookie = useSetUserCookie();
  const handleLogin = async (
    loginDetails: LoginDetails,
  ): Promise<ResultOfLogin> => {
    const functionName = 'login';
    const baseUrl = getBaseUrlForFetching();
    const registerUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(registerUrl, {
      method: 'POST',
      body: JSON.stringify(loginDetails),
      headers,
    });

    if (response.status === 401) {
      return { status: 401 };
    }

    const data = await response.json();

    if (data.error) {
      console.log(data.error);
      return { status: 500 };
    }

    const token = data?.token;
    if (token) {
      setUserCookie(token);
    } else {
      return { status: 500 };
    }
    return { status: 200, token };
  };
  return handleLogin;
};

export default useHandleLogin;
