import styled from 'styled-components';
import AmountToGenerateSelector from './ButtonsBelowImage/AmountToGenerateSelector';
import GenerateButton from './ButtonsBelowImage/GenerateButton';
import SpeedModeSelector from './ButtonsBelowImage/SpeedModeSelector';
import { EngineType, SpeedModeType } from '../../types';
import { fadeIn } from '../../components/ImageStuff/animations';
import { desktopMediaQuery } from '../../styleHelpers';

interface P {
  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;
  amountToGenerate: number;
  setAmountToGenerate: (amountToGenerate: number) => void;
  handleSendPrompt: () => void;
  engine: EngineType;
}

const MainButtons = ({
  speedMode,
  setSpeedMode,
  amountToGenerate,
  setAmountToGenerate,
  handleSendPrompt,
  engine,
}: P) => (
  <MainButtonsContainer>
    <DropDownsContainer>
      <SpeedModeSelector speedMode={speedMode} setSpeedMode={setSpeedMode} wider />
      <AmountToGenerateSelector
        amountToGenerate={amountToGenerate}
        setAmountToGenerate={setAmountToGenerate}
        // TODO: refactor both to be wide everywhere
        wider
      />
    </DropDownsContainer>
    <GenerateButtonContainer>
      <GenerateButton
        handleSendPrompt={handleSendPrompt}
        speedMode={speedMode}
        amountToGenerate={amountToGenerate}
        engine={engine}
      />
    </GenerateButtonContainer>
  </MainButtonsContainer>
);

const MainButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  padding-left: 16px;
  margin-bottom: 24px;
  animation: ${fadeIn} 3s ease-in-out;
`;

const DropDownsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 2; /* 67% of the width */
`;

const GenerateButtonContainer = styled.div`
  flex: 1; 
  ${desktopMediaQuery} {
    flex: 2;
  }
  display: flex;
  align-items: center; 
`;

export default MainButtons;
