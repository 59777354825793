import styled from 'styled-components';
import LabelWithTooltip from '../../../components/common/LabelWithTooltip';
import StyledSelect from '../../../components/common/StyledSelect';

interface P {
  size: { width: string; height: string };
  setSize: (size: { width: string; height: string }) => void;
}

const TOOLTIP_TEXT = 'Some types of images work better with different sizes';

const ShapeDropdown = ({ size, setSize }: P) => {
  const handleChange = (e: any) => {
    if (e.target.value === 'Square') setSize({ width: '512', height: '512' });
    if (e.target.value === 'Portrait') setSize({ width: '512', height: '640' });
    if (e.target.value === 'Tall portrait')
      setSize({ width: '512', height: '768' });
    if (e.target.value === 'Landscape')
      setSize({ width: '640', height: '512' });
    if (e.target.value === 'Wide landscape')
      setSize({ width: '768', height: '512' });
  };

  const sizeList = [
    'Square',
    'Portrait',
    'Tall portrait',
    'Landscape',
    'Wide landscape',
  ];

  const getValueForSelect = () => {
    if (size.width === '512' && size.height === '512') return 'Square';
    if (size.width === '512' && size.height === '640') return 'Portrait';
    if (size.width === '512' && size.height === '768') return 'Tall portrait';
    if (size.width === '640' && size.height === '512') return 'Landscape';
    if (size.width === '768' && size.height === '512') return 'Wide landscape';
    return 'Portrait';
  };

  return (
    <Container>
      <LabelWithTooltip labelText="Shape" tooltipText={TOOLTIP_TEXT} />
      <StyledSelect
        value={getValueForSelect()}
        onChange={(e) => handleChange(e)}
      >
        {sizeList.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
    </Container>
  );
};

// TODO: same as modeldropdown so refactor at some point
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
  align-self: flex-start;
  margin-top: 30px;
  margin-bottom: 24px;
  margin-left: 16px;
`;

export default ShapeDropdown;
