import styled from "styled-components";
import { useImagesContext } from "../../context/ImagesContextProvider";
import { useEffect, useState } from "react";
import DownloadIcon from "../../components/common/DownloadIcon";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import { BORDER_COLOR, LIGHT_GREY_COLOR } from "../../constants";
import SaveButton from "../../components/common/SaveButton";
import { desktopMediaQuery } from "../../styleHelpers";
import { StyledButton } from "../../components/common/StyledButton";

const moveDown = () => {
  window.scrollTo({ top: 1000, behavior: "smooth" });
};

const ButtonsBelowInpaintedImage = ({
  selectedItem,
}: {
  selectedItem: number;
}) => {
  const { editedImages } = useImagesContext();
  const { setActiveImage, loading } = useGeneralContext();
  const [selectedImage, setSelectedImage] = useState(
    editedImages[selectedItem]
  );

  const handleUseThisAsInpaint = () => {
    if (loading) {
      return;
    }
    setActiveImage(selectedImage);
    moveDown();
  };

  useEffect(() => {
    setSelectedImage(editedImages[selectedItem]);
  }, [editedImages, selectedItem]);

  return (
    <OuterContainer>
      <Container>
        <UseThisAsInpaint onClick={handleUseThisAsInpaint} />
        <SaveButtonContainer>
          <DownloadIconContainer>
            <DownloadIcon image={selectedImage} />
          </DownloadIconContainer>
          <SaveButton image={selectedImage} inLibrary />
        </SaveButtonContainer>
      </Container>
      <SeparationLine />
    </OuterContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  justify-items: center;
  align-items: center;

  gap: 16px;

  margin-bottom: 24px;

  margin-left: 8px;
`;
const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const DownloadIconContainer = styled.div`
  padding-top: 4px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  margin-left: auto;

  margin-top: 7px;
`;

const SeparationLine = styled.div`
  align-self: center;
  width: 100%;
  height: 2px;
  background-color: ${LIGHT_GREY_COLOR};
`;

interface P {
  onClick: () => void;
}

const InpaintContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;

  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${BORDER_COLOR};
    }
  }

  margin-right: 8px;
`;

const UseThisAsInpaint = ({ onClick }: P) => (
  <InpaintContainer onClick={onClick}>
    <StyledButton>Use as new base</StyledButton>
  </InpaintContainer>
);

export default ButtonsBelowInpaintedImage;
