import styled from 'styled-components';
import { useEffect } from 'react';
import { FaArrowCircleRight, FaArrowCircleUp } from 'react-icons/fa';
import HomePageGeneratorInput from './HomePageGeneratorInput';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import useHandleSendPrompt from '../../hooks/images/useHandleSendPrompt';
import { getDefaultStyleViaId } from '../../utils/imageStylesHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import {
  BORDER_COLOR,
  EVEN_LIGHTER_GREY_COLOR,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
  TALL_PORTRAIT_SIZE,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { cleanUpPrompt, waitSeconds } from '../../components/helpers';
import { scrollToTop } from '../../hooks/misc/helpers';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../pathNames';
import { homePageGenerateStarted, trackLandingGenerateClicked } from '../../utils/analytics';

interface I {
  inLandingPage?: boolean;
}
const InputSectionInQuickGenerator: React.FC<I> = ({ inLandingPage }) => {
  const navigate = useNavigate();
  const { images, loading, isFirstTimeUser } = useGeneralContext();
  const { setSize, userPrompt, setUserPrompt, speedMode, setSpeedMode } =
    useImageGenerationSettingsContext();
  const isSeedFrozen = false;
  const cfg = 7;
  const amountToGenerate = 1;

  useEffect(() => {
    if (images.length > 0) return;
    setSize(TALL_PORTRAIT_SIZE);
    const speedToUse = isFirstTimeUser ? 'turbo' : 'adTurbo';
    setSpeedMode(speedToUse);
  }, [isFirstTimeUser]);
  console.log(speedMode, isFirstTimeUser);

  const handleSendPromptHook = useHandleSendPrompt({
    isSeedFrozen,
    cfg,
    amountToGenerate,
  });

  const handleGenerate = async () => {
    const styleForHook = getDefaultStyleViaId('1.2');
    const cleanedUpPrompt = cleanUpPrompt(userPrompt);
    setUserPrompt(cleanedUpPrompt);

    if (inLandingPage) {
      navigate(HOME_PATH);
      trackLandingGenerateClicked();
      return;
    }

    homePageGenerateStarted();

    handleSendPromptHook(
      cleanedUpPrompt,
      '',
      styleForHook,
      undefined,
      undefined,
      speedMode,
      true,
    );
    await waitSeconds(0.5);
    scrollToTop();
  };

  const placeholder = 'Describe image to create'; //getIsOnDesktop() ? 'Describe your image here...\nExample: beautiful blonde with huge boobs wearing a tank top' : 'Describe your image here\nExample: beautiful blonde';

  const isEnterDisabled = userPrompt.length === 0;

  return (
    <TextAreaWrapper>
      <HomePageGeneratorInput
        placeholder={placeholder}
        value={userPrompt}
        setValue={setUserPrompt}
        // if on desktop then enter will trigger send
        callbackOnEnter={handleGenerate}
      />
      {loading ? (
        <LoadingIndicator differentBgColor />
      ) : (
        <GenerateIcon
          onClick={isEnterDisabled ? () => {} : handleGenerate}
          isDisabled={isEnterDisabled}
          inLandingPage={inLandingPage}
        />
      )}
    </TextAreaWrapper>
  );
};

export default InputSectionInQuickGenerator;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 2px;
  padding-right: 8px;

  border: 1.5px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;

  transition: border-color 0.2s;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${EVEN_LIGHTER_GREY_COLOR};
    }
  }
`;

interface GenerateIconProps {
  isDisabled: boolean;
  inLandingPage?: boolean;
  onClick: () => void;
}
const GenerateIcon = ({
  isDisabled,
  inLandingPage,
  onClick,
}: GenerateIconProps) => {
  if (inLandingPage) {
    return (
      <GenerateIconStyledLandingPage
        isDisabled={isDisabled}
        onClick={onClick}
      />
    );
  }
  return <GenerateIconStyled isDisabled={isDisabled} onClick={onClick} />;
};

const generateIconSharedStyles = `
  font-size: 26px;
  cursor: pointer;
  transition: color 0.3s;


  ${desktopMediaQuery} {
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const GenerateIconStyled = styled(FaArrowCircleUp)<{ isDisabled: boolean }>`
  ${generateIconSharedStyles}
  color: ${({ isDisabled }) =>
    isDisabled ? BORDER_COLOR : EVEN_LIGHTER_GREY_COLOR};
`;

const GenerateIconStyledLandingPage = styled(FaArrowCircleRight)<{
  isDisabled: boolean;
}>`
  ${generateIconSharedStyles}
  color: ${({ isDisabled }) =>
    isDisabled ? BORDER_COLOR : EVEN_LIGHTER_GREY_COLOR};
`;
