/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Redirecting: React.FC = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const size = query.get('size');
  const userId = query.get('userId');


  const smallAINPackagePaymentUrl =
    'https://api.ccbill.com/wap-frontflex/flexforms/e647a9eb-67af-4988-8655-1d2c2b30e09f';
  const mediumPackage =
    'https://api.ccbill.com/wap-frontflex/flexforms/3605eba0-2965-48b5-941e-9462858793e0';
  const largePackage =
    'https://api.ccbill.com/wap-frontflex/flexforms/8fb8d305-7936-4717-a5b9-3b717590c28d';

  useEffect(() => {
    const handleRedirect = () => {
      if (size === 'small') {
        window.location.href = `${smallAINPackagePaymentUrl}?user-id=${userId}`;
        return;
      }

      if (size === 'medium') {
        window.location.href = `${mediumPackage}?user-id=${userId}`;
        return;
      }

      if (size === 'large') {
        window.location.href = `${largePackage}?user-id=${userId}`;
        return;
      }
    };

    const timer = setTimeout(() => {
      handleRedirect();
    }, 2500);
  });

  return (
    <Container>
      <h1>Redirecting...</h1>
      <Subtitle>
        After payment, credits will appear in your account within a minute or
        two.
      </Subtitle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Subtitle = styled.p`
  font-size: 24px;
  color: grey;
  margin-left: 8px;
  margin-right: 8px;
`;

export default Redirecting;
