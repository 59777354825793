import React from 'react';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import { getAmountOfCredits } from './helpers';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import GoldenCoinIcon from '../GoldenCoinIcon';

const CreditIndicatorInTopBar: React.FC = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const amountOfCredits = getAmountOfCredits(loggedInUser);
  const isInfinite =
    typeof amountOfCredits === 'string' && amountOfCredits === '∞';

  return (
    <CreditsDisplay>
      <GoldenCoinIcon />
      <Credits isInfinite={isInfinite}>{amountOfCredits}</Credits>
    </CreditsDisplay>
  );
};

const CreditsDisplay = styled.div`
  display: flex;
  align-items: center;

  margin-right: 8px;
  margin-top: 1px;

  ${desktopMediaQuery} {
    font-size: 22px;
  }
  font-weight: 400;
`;

const Credits = styled.span<{ isInfinite?: boolean }>`
  margin-left: 4px;
  font-size: ${({ isInfinite }) => (isInfinite ? '24px' : '16px')};
  ${desktopMediaQuery} {
    font-size: ${({ isInfinite }) => (isInfinite ? '28px' : '18px')};
  }

  color: ${PRIMARY_TEXT_COLOR};
`;

export default CreditIndicatorInTopBar;
