import React from 'react';
import { SortSettingInFeed } from '../../localStorage/sortSettingsInProfilesStorage';
import CommonDropdown from '../../components/common/CommonDropdown';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import { useImagesContext } from '../../context/ImagesContextProvider';

interface SortDropdownProps {}

const options = ['hot', 'new', 'top'];
const optionsToRender = ['Hot 🔥', 'New 🆕', 'Top 🚀'];

const SortDropdownInFeed: React.FC<SortDropdownProps> = ({}) => {
  const { selectedSortOption, setSelectedSortOption } = useImagesContext();

  const handleOptionClick = (option: SortSettingInFeed) => {
    setSelectedSortOption(option as string);
    userSettingsInStorage().lastUsedSortOptioninFeed.set(option);
  };

  const selectedSortOptionFirstLetterCapitalized = selectedSortOption
    ? selectedSortOption[0].toUpperCase() + selectedSortOption.slice(1)
    : 'Hot';

  return (
    <CommonDropdown
      options={options}
      optionsToRender={optionsToRender}
      selectedOption={selectedSortOption || 'hot'}
      onOptionClick={(option) => handleOptionClick(option as SortSettingInFeed)}
      buttonText={selectedSortOptionFirstLetterCapitalized}
    />
  );
};

export default SortDropdownInFeed;
