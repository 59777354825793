import styled from "styled-components";
import { FakeImage } from "../../types";
import { IconButtonContainer, sharedIconStyle } from "./IconButtonStuff";
import { useHandleDownloadImage } from "../../hooks/images/useHandleDownloadImage";
import { AiOutlineSave } from "react-icons/ai";

interface P {
  image: FakeImage;
}

export default function DownloadIcon({ image }: P) {
  const handleDownloadImage = useHandleDownloadImage();
  const handleDownload = () => {
    handleDownloadImage(image);
  };

  return (
    <IconButtonContainer onClick={handleDownload}>
      <DownloadReactIcon size={26} />
    </IconButtonContainer>
  );
}

const DownloadReactIcon = styled(AiOutlineSave)`
  ${sharedIconStyle}
  margin-left: -6px; // TODO FIX
  margin-top: 2px;
`;
