import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SmallSelectableStyledButton } from '../../components/common/StyledButton';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';

interface CategoryFilterButtonsProps {
  categories: string[];
  selectedCategory: string | undefined;
  setSelectedCategory: (category: string | undefined) => void;
}

const CategoryFilterButtons: React.FC<CategoryFilterButtonsProps> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const storage = userSettingsInStorage().lastUsedCategoryFilterInHome;

  const handleClick = (category: string) => {
    storage.set(category);
    setSelectedCategory(category);
  };

  useEffect(() => {
    //check from local storage if there is a last used category
    const lastUsedCategory = storage.get();
    if (lastUsedCategory) {
      setSelectedCategory(lastUsedCategory);
      return;
    }

    if (!selectedCategory) {
      setSelectedCategory('All');
    }
  }, []);

  const renderCategoryName = (category: string) => {
    if (category === 'Anime') return 'Hentai';
    return category;
  };
  return (
    <Container>
      {categories.map((category) => (
        <SmallSelectableStyledButton
          key={category}
          isSelected={category === selectedCategory}
          onClick={() => handleClick(category)}
        >
          {renderCategoryName(category)}
        </SmallSelectableStyledButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  gap: 8px;
  z-index: 2;
`;

export default CategoryFilterButtons;
