import styled from 'styled-components';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { isSafari } from '../../styleHelpers';

export const HiddenPromptText = styled.div<{ postPrompt?: boolean }>`
  display: flex;
  position: relative;

  color: ${SECONDARY_TEXT_COLOR};
  // italic
  font-style: italic;
  font-size: ${() => (isSafari ? '16px' : '14px')};
  width: 95%;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: ${(props) => (props.postPrompt ? '0px' : '4px')};

  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;
