import React from 'react';
import TransitioningModal from '../../../components/Modals/TransitioningModal';
import { BACKGROUND_COLOR, FEED_BASE_URL } from '../../../constants';
import { CloseButtonInModal } from '../../../components/common/CloseButtonInModal';
import styled from 'styled-components';
import { FaDownload, FaLink, FaRegFlag, FaTrash } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import useReportImage from '../../../hooks/feed/useReportImage';
import { useHandleDownloadImage } from '../../../hooks/images/useHandleDownloadImage';
import useGetIsImageOwnedByLoggedInUser from '../../../hooks/feed/useGetIsImageOwnedByLoggedInUser';
import { SharedImage } from '../../../types';
import useRemoveImageFromShared from '../../../hooks/images/useRemoveImageFromShared';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  image: SharedImage | null;
}

const ShowMoreMenuModal = ({ show, setShow, image }: Props) => {
  const { loggedInUser } = useLoggedInUserContext();
  const reportImage = useReportImage();
  const handleDownloadImage = useHandleDownloadImage();
  const handleRemoveFromShared = useRemoveImageFromShared();
  const isImageOwnedByLoggedInUser = useGetIsImageOwnedByLoggedInUser(image);

  const handleReport = () => {
    const ok = window.confirm('Are you sure you want to report this image?');
    if (!ok) return;
    if (!image) return;
    reportImage(image);

    alert(
      'Image reported, thank you! If the report is valid, the image will be removed and creator banned',
    );

    setShow(false);
  };

  const handleDownload = () => {
    image && handleDownloadImage(image.image);
    setShow(false);
  };

  const handleCopyLink = () => {
    if (!image) return;
    const userIdOfTheSharer = loggedInUser?.id ?? '';
    const urlToSharedImage = loggedInUser
      ? `${FEED_BASE_URL}?id=${image.id}&r-id=${userIdOfTheSharer}`
      : `${FEED_BASE_URL}?id=${image.id}`;
    navigator.clipboard.writeText(urlToSharedImage);
    toast.success('Copied! Share this link to earn free credits! 🥳');
    setShow(false);
  };

  const handleRemove = async () => {
    if (!isImageOwnedByLoggedInUser || !image) return;
    const ok = await handleRemoveFromShared(image);
    setShow(false);
    if (!ok) return;
    toast.success('Image removed!');
  };

  if (!show || !image) {
    return null;
  }
  return (
    <TransitioningModal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 200,
          height: '100%',
          width: '100%',
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '92%',

          position: 'absolute',
          bottom: '0',
          border: 'none',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShow(false)}
    >
      <CloseButtonInModal onClick={() => setShow(false)} />
      <ContainerForMenuItems>
        {isImageOwnedByLoggedInUser ? (
          <p onClick={handleRemove}>
            <FaTrash color="red" />
            Remove
          </p>
        ) : (
          <p onClick={handleReport}>
            <FaRegFlag color="red" />
            Report
          </p>
        )}
        <p onClick={handleDownload}>
          <FaDownload />
          Download
        </p>
        <p onClick={handleCopyLink}>
          <FaLink />
          Copy link
        </p>
      </ContainerForMenuItems>
    </TransitioningModal>
  );
};

const ContainerForMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 12px;
    cursor: pointer;
    gap: 16px;
  }
`;

export default ShowMoreMenuModal;
