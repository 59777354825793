import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../components/common/StyledButton';
import { FEED_PATH } from '../../pathNames';
import { useNavigate } from 'react-router-dom';
import { TitleTextWithGradient } from './CommonComponents';
import { MdOutlineImageSearch } from 'react-icons/md';
import { desktopMediaQuery } from '../../styleHelpers';
import {
  DiscordLogo,
  RedditLogo,
} from '../../components/common/TopBar/SocialLogos';
import { FeedChannel } from '../../types';
import useGetPostedImagesForLanding, { LandingImage } from '../../hooks/feed/useGetPostedImagesForLanding';
import CommunityImage from './CommunityImage';
import Placeholders from './Placeholders';

const MyComponent: React.FC = () => {
  const navigate = useNavigate();
  const getPostedImagesForLanding = useGetPostedImagesForLanding();

  const [allImages, setAllImages] = useState<LandingImage[]>([]);
  const [imagesToShow, setImagesToShow] = useState<LandingImage[]>([]);

  const handleClick = () => {
    navigate(FEED_PATH);
  };

  useEffect(() => {
    const initImages = async () => {
      const sort = 'top';
      const channelName = 'all';
      const channelList: FeedChannel[] = [];
      const images = await getPostedImagesForLanding({
        sort,
        channelName,
        channelList,
      });
      const shuffledImages = images.sort(() => Math.random() - 0.5);
      setAllImages(shuffledImages);
      setImagesToShow(shuffledImages.slice(0, 4));
    };
    initImages();
  }, []);

  const SECONDS_UNTIL_NEW_IMAGE = 1.5;
  useEffect(() => {
    if (imagesToShow.length < allImages.length) {
      const interval = setInterval(() => {
        setImagesToShow((prevUrls) => {
          const nextImg = allImages[prevUrls.length];
          if (nextImg) {
            return [nextImg, ...prevUrls];
          }
          return prevUrls;
        });
      }, SECONDS_UNTIL_NEW_IMAGE * 1000);

      return () => clearInterval(interval);
    }
  }, [allImages]);

  return (
    <Container>
      <TitleTextWithGradient>
        Explore images by the community
      </TitleTextWithGradient>
      <ImagesContainer>
        {imagesToShow.length === 0 && <Placeholders />}
        {imagesToShow.map((image) => (
          <CommunityImage key={image.id} image={image} />
        ))}
      </ImagesContainer>

      <ButtonContainer>
        <Button onClick={handleClick}>
          Browse images <MdOutlineImageSearch size={24} />
        </Button>
      </ButtonContainer>

      <SocialsContainer>
        <DiscordLogo bigger />
        <RedditLogo bigger />
      </SocialsContainer>
    </Container>
  );
};

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${desktopMediaQuery} {
    font-size: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 99vw;
`;

const ImagesContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 120px);
  overflow-x: auto;
  // dont show scrollbar
  scrollbar-width: none;
  height: 220px;
  width: 90vw;

  ${desktopMediaQuery} {
    width: 55vw;
    height: 400px;
    max-width: 700px;
    // center
    align-self: center;
  }
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const Button = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding-left: 32px;
  padding-right: 32px;
  border-width: 2px;

  ${desktopMediaQuery} {
    font-size: 20px;
  }
`;

export default MyComponent;
