import React from 'react';
import styled from 'styled-components';
import TransitioningModal from '../../../components/Modals/TransitioningModal';

interface MoreInfoNotificationModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const MoreInfoNotificationModal: React.FC<MoreInfoNotificationModalProps> = ({
  show,
  setShow,
}) => {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <TransitioningModal isOpen={show} onRequestClose={handleClose}>
      <ModalContent>
        <h2>More Information</h2>
        <p>This is a simple modal to show more information.</p>
        <button onClick={() => setShow(false)}>Close</button>
      </ModalContent>
    </TransitioningModal>
  );
};

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    margin-top: 0;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #0056b3;
    }
  }
`;

export default MoreInfoNotificationModal;
