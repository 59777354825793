import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, GOLD_COLOR, PRIMARY_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 16px;

  max-width: 95vw;
`;

const StyledExampleImg = styled.img<{ small?: boolean }>`
  height: ${({ small }) => (small ? '350px' : '420px')};
  width: 280px;

  border-radius: 36px;
  margin-bottom: 16px;
`;

export const ExampleImage = ({
  src,
  small,
}: {
  src: string;
  small?: boolean;
}) => {
  return (
    <StyledExampleImg
      src={src}
      alt="Image generated with the AI Adult Image Generator"
      loading="lazy"
      small={small}
    />
  );
};

export const SmallMargin = styled.div`
  height: 8px;

  margin-top: 36px;
  margin-bottom: 36px;
`;

export const TitleTextWithGradient = styled.h2`
  color: ${PRIMARY_TEXT_COLOR};

  padding-left: 16px;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    ${GOLD_COLOR} 20%,
    ${PRIMARY_COLOR} 80%
  );

  margin-top: 12px;

  max-width: 94vw;

  ${desktopMediaQuery} {
    font-size: 36px;
  }
`;

export const PageTitleH1 = styled.h1`
  color: ${PRIMARY_TEXT_COLOR};

  text-align: center;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    ${GOLD_COLOR} 20%,
    ${PRIMARY_COLOR} 80%
  );

  margin-top: 4px;

  font-size: 28px;
`;

export const TextContainerInButton = styled.div<{ toLeft?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    ${({ toLeft }) => (toLeft ? 'to left' : 'to right')},
    ${PRIMARY_TEXT_COLOR} 30%,
    ${PRIMARY_COLOR} 70%
  );
`;
