import styled from "styled-components";
import TransitioningModal from "../../../components/Modals/TransitioningModal";
import { BACKGROUND_COLOR } from "../../../constants";
import { AiOutlineClose } from "react-icons/ai";
import { desktopMediaQuery } from "../../../styleHelpers";
import { StyledButton } from "../../../components/common/StyledButton";
import useDeleteFolder from "../../../hooks/folders/useDeleteFolder";
import useRenameFolder from "../../../hooks/folders/useRenameFolder";
import { toast } from "react-toastify";

interface Props {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  folderId: string;
}

const EditFolderModal = ({ showModal, setShowModal, folderId }: Props) => {
  const renameFolder = useRenameFolder();
  const deleteFolder = useDeleteFolder();

  const handleRename = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const newFolderName = prompt("Enter new folder name");
    if (newFolderName) {
      await renameFolder(folderId, newFolderName);
      toast.success("Folder renamed successfully, refresh to see changes");
      setShowModal(false);
    }
  };

  const handleDelete = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const areYouSure = confirm("Are you sure you want to delete this folder?");
    if (areYouSure) {
      await deleteFolder(folderId);
      toast.success("Folder deleted successfully, refresh to see changes");
      setShowModal(false);
    }
  };

  const onRequestClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowModal(false);
  };
  return (
    <TransitioningModal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 300,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: "36px",
          paddingRight: "48px",
          width: "100%",
          maxWidth: "180px",
          maxHeight: "200px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={onRequestClose}
    >
      <ModalContent>
        <CloseButtonInModal onClick={onRequestClose} />
        <StyledButton onClick={handleRename}>Rename Folder</StyledButton>
        <StyledButton onClick={handleDelete}>Delete Folder</StyledButton>
      </ModalContent>
    </TransitioningModal>
  );
};

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const CloseButtonInModal = styled(AiOutlineClose)`
  position: absolute;
  top: -16px;
  right: -24px;
  font-size: 32px;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  color: white;
`;

export default EditFolderModal;
