import { useCookies } from 'react-cookie';
import { customFetch } from '../customFetch';

const useSendComment = () => {
  const [{ token }] = useCookies(['token']);
  /**
   * Sends a comment to the server
   * @param imageId - The ID of the image to comment on
   * @param comment - The comment text
   * @returns A promise that resolves when the comment is sent
   */
  const sendComment = async (
    imageId: string,
    comment: string,
  ): Promise<number> => {
    const functionName = 'sendComment';
    const response = await customFetch(functionName, { imageId, comment, token });
    console.log(response)

    if (!response) {
      console.error('Failed to send comment');
      return 500;
    }

    return 200;
  };

  return sendComment;
};

export default useSendComment;
