import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useHandleClickOutside from '../../common/TopBar/useHandleClickOutside';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  LIGHTER_GREY_FOR_HOVER_EFFECT,
  LIGHT_GREY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { IoIosArrowDown } from 'react-icons/io';
import { setSortSettingsInProfilesStorage } from '../../../localStorage/sortSettingsInProfilesStorage';
import { useImagesContext } from '../../../context/ImagesContextProvider';

interface SortDropdownProps {
  sortBy: string;
  setSortBy: (value: string) => void;
  options: string[];
}

const SortDropdown: React.FC<SortDropdownProps> = ({
  sortBy,
  setSortBy,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setLocationWhenLastImageClicked } = useImagesContext();

  const handleToggle = () => setIsOpen(!isOpen);

  // TODO: setSOrtSettingsInProfilesStorage is for profile stuff, need to refactor when addinf this elsewhere
  const handleOptionClick = (option: string) => {
    setLocationWhenLastImageClicked(option); // hack to block autoscroll
    setSortSettingsInProfilesStorage(option);
    setSortBy(option);
    setIsOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useHandleClickOutside([ref], () => setIsOpen(false));

  return (
    <Container ref={ref}>
      <Button onClick={handleToggle}>
        {sortBy} <IoIosArrowDown />
      </Button>
      {isOpen && (
        <Dropdown>
          {options.map((option) => (
            <Option key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default SortDropdown;

const Container = styled.div`
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;

  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  gap: 2px;

  font-weight: 400;
  font-size: 16px;

  border: 1px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;

  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: ${BACKGROUND_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-top: 5px;

  border: 0.5px solid ${BORDER_COLOR};
  border-radius: 2px;

  z-index: 10;
`;

const Option = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${LIGHTER_GREY_FOR_HOVER_EFFECT};
  }
`;
