/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import {
  GOLD_COLOR,
  PICARUS_URL,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { GenericSetState, LoggedInUser } from '../../../types';
import { StyledButton } from '../../common/StyledButton';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { useEffect } from 'react';
import {
  BenefitListItem,
  ContainerPaymentModal,
  LineInBuyModal,
  Modal,
  ModalText,
  paymentModalContentStyles,
  paymentModalOverlayStyles,
} from './PaymentCommonComponents';
import { FaShoppingCart } from 'react-icons/fa';
import { useModalsContext } from '../../../context/ModalsContextProvider';
import { PAYMENTS_DOWN } from './BuyCreditsModal';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const GetSubscriptionModal = ({ showModal, setShowModal }: I) => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const { setIsAuthModalOpen } = useModalsContext();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleGetGold = () => {
    if (PAYMENTS_DOWN) {
      alert('Payments are currently disabled. Please try again later');
      return;
    }
    if (!loggedInUser) {
      alert('You must be logged in to buy Gold');
      setIsAuthModalOpen(true);
      setShowModal(false);
      return;
    }

    const userId = loggedInUser.id;
    const paymentType = 'gold-subscription';
    alert(
      'You will be redirected to our secure payments page. Please return to this page after payment',
    );
    const urlToRedirect = `${PICARUS_URL}/redirect?userId=${userId}&paymentType=${paymentType}`;
    window.location.href = urlToRedirect;
  };

  useEffect(() => {
    if (showModal && loggedInUser?.type !== 'gold') {
    }
  }, [showModal]);

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: paymentModalOverlayStyles,
        content: paymentModalContentStyles,
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerPaymentModal inSubscriptionModal>
        <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
        <TitleText>⭐ Subscription ⭐</TitleText>
        {loggedInUser?.type === 'gold' ? (
          <ManageExistingSub
            loggedInUser={loggedInUser}
            setLoggedInUser={setLoggedInUser}
            handleClose={handleClose}
          />
        ) : (
          <GetGoldContent handleGetGold={handleGetGold} />
        )}
      </ContainerPaymentModal>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetGoldContent = ({ handleGetGold }: { handleGetGold: () => void }) => (
  <GetGoldContentContainer>
    <ModalText style={{ color: 'red' }}>
      Buying subscriptions is temporarily offline
    </ModalText>
    <ModalText>
      <BenefitListItem>Unlimited credits/turbo</BenefitListItem>
      <BenefitListItem>Save more images</BenefitListItem>
      <BenefitListItem>Custom Tags</BenefitListItem>
      <BenefitListItem>...and more!</BenefitListItem>
      <LineInBuyModal />
      <PopularText>OnlyFakes Gold Subscription</PopularText>
      <PricingText />
      <ExtraInfo />
    </ModalText>
    <GetGoldButton onClick={handleGetGold}>
      <FaShoppingCart color={GOLD_COLOR} />
      Start Subscription
    </GetGoldButton>
  </GetGoldContentContainer>
);
/*
  <GetGoldContentContainer>
    <ModalText>
      Buying subscriptions is temporarily offline. Buy credits to enjoy fast
      image generation
    </ModalText>
  </GetGoldContentContainer>

*/

const PopularText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;

  margin-left: 12px;
`;

const GetGoldContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PricingText = () => (
  <div
    style={{
      flexDirection: 'column',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        flexDirection: 'row', // Changed from 'row' to 'column' to stack the elements vertically
      }}
    >
      <p
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
        }}
      >
        $14.9
      </p>
      <p>/ month</p>
    </div>
  </div>
);

const ExtraInfo = () => (
  <div
    style={{
      fontSize: '14px',
      paddingLeft: 32,
      paddingRight: 32,
      marginTop: -16,
      marginBottom: -16,
    }}
  >
    <i>(+ taxes)</i>
    <p>
      <i>
        Note: pricing may vary by region, reflecting local currency differences
      </i>
    </p>
  </div>
);

const ManageExistingSub = ({
  loggedInUser,
}: {
  loggedInUser: LoggedInUser;
  setLoggedInUser: GenericSetState<LoggedInUser | null>;
  handleClose: () => void;
}) => {
  const getSubscriptionIsValidUntil = () => {
    if (!loggedInUser.lastPaymentDate) return null;
    const lastPaymentDate = new Date(loggedInUser.lastPaymentDate);
    const date = new Date(
      lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1),
    );
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const subscriptionIsValidUntil = getSubscriptionIsValidUntil();

  return (
    <>
      <ModalText>
        <p>You are a Gold user! 🎉</p>
        <div
          style={{
            fontSize: '16px',
          }}
        >
          Your current payment covers you until{' '}
          <b>{subscriptionIsValidUntil}</b>
          <br />
          <br />
          <>
            Your subscription has been automatically cancelled due to changes in
            our payment processor. Please wait for the new subscription service
            to become available.
            <br />
            <br />
            In case of issues, please contact us at support@onlyfakes.app
          </>
        </div>
      </ModalText>
    </>
  );
};

export const GetGoldButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-color: ${GOLD_COLOR};
`;
const TitleText = styled(ModalText)`
  font-size: 22px;
  margin-bottom: 2px;
`;

export default GetSubscriptionModal;
