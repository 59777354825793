export const checkPromptForBlocking = (fullPrompt: string) => {
  const badWords = [
    "1 yo",
    "2 yo",
    "3 yo",
    "4 yo",
    "5 yo",
    "6 yo",
    "7 yo",
    "8 yo",
    "9 yo",
    "1yo",
    "2yo",
    "3yo",
    "4yo",
    "5yo",
    "6yo",
    "7yo",
    "8yo",
    "9yo",
    "1 years",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "6 years",
    "7 years",
    "8 years",
    "9 years",
    "1 year",
    "2 year",
    "3 year",
    "4 year",
    "5 year",
    "6 year",
    "7 year",
    "8 year",
    "9 year",
    "Ginny Weasley",
    "high school",
    "highschool",
    "teen",
    "infant",
    "almost 18",
    "bairn",
    "Tyke",
    "Newborn",
    "neonate",
    "babe face",
    "babe-face",
    "babeface",
    "barely legal",
    "freshman",
    "brother and sister",
    "twin sisters",
    "niña",
    "niño",
    " kid ",
    " shit ",
    " rape ",
    "weapon",
    "gun",
    "ak-47",
    "diapers",
    "unconscious",
    "pissing",
    "golden shower",
    "sex with horse",
    "sex with animal",
    " scat ",
    "mutilation",
    "incest",
    "pubescent",
    "diminutive",
    "very small girl",
    "very small 18yo",
    "baby",
    "Ellie Williams",
    "sissy",
    "juvenile",
    "Juvenile",
    "little boy",
    "Dora the explorer",
    "adolescent",
    "juvenile",
    "little nude girl",
    "very youthful",
    "youth",
    "youthful",
    "immature",
    "youngest",
    "younger",
    "young",
    "teenage",
    "(younger)",
    "((younger))",
    "shortest girl",
    "((((girl:1.5))))",
    "petite 18yo girls:1.8",
    "18yo tiny girl",
    "tiny girl",
    "petite girl",
    "petit girl",
    "small girl",
    "miniature girl",
    "midget",
    "Marnie",
    "Misty",
    "loli",
    "Lolly",
    "barely 18",
    "15 yo",
    "16 yo",
    "16 years",
    "17 years",
    "16 years old",
    "17 years old",
    "16 year old",
    "17 year old",
    "16-year-old",
    "17-year-old",
    "16-years-old",
    "17-years-old",
    "smol",
    "Age 15",
    "Age 16",
    "Age 17",
    "16yo",
    "child",
    "underage",
    "teen",
    "teens",
    "kid",
    "very young",
    "schooler",
    "baby",
    "jailbait",
    "little sister",
    "little sis",
    "lil sis",
    "lil sister",
    "little daughter",
    "little daughters",
    "very youthful",
    "Little girl",
    "baby face",
    "babyface",
    "small girl",
    "scarlett.johansson",
    "Hermione",
    "Ellie miller",
    "Ellie from the last of us",
    "dwarf",
    ":girl:",
    "👧",
    ":boy:",
    "👦",
    "👶",
    "kindergarten",
    "Moana from Moana",
    "Toga Himiko",
    "Wednesday Addams",
    "Tsuyu Asui",
    "Azusa Nakano",
    "Ellie Williams",
    "youthful",
  ];
  const okPrompts = [
    "burgundy",
    "skyscraper",
    "babydoll",
    "smoldering",
    "nakid",
    "age 1000",
    "age 100",
    "1000 yo",
    "1000 years old",
    "lollipop",
    "lolipop",
    "lolita",
    "skid",
    "kidnap",
    "kidney",
    "kidnaped",
    "upskirted",
    "Babyblue",
    "kidult",
    "skiddy",
    "skidoo",
    "kidvid",
    "skidder",
    "kidology",
    "kidron",
    "kidling",
    "1 8 yo",
    "canteen",
    "kidnapped",
    "baby blue",
    "babysitter",
    "eighteen",
    "nineteen",
    "between",
    "18yo",
    "19yo",
    "20yo",
    "21yo",
    "22yo",
    "23yo",
    "24yo",
    "25yo",
    "26yo",
    "27yo",
    "28yo",
    "29yo",
    "32yo",
    "33yo",
    "34yo",
    "35yo",
    "36yo",
    "37yo",
    "38yo",
    "39yo",
    "40yo",
    "41yo",
    "42yo",
    "43yo",
    "44yo",
    "45yo",
    "46yo",
    "47yo",
    "48yo",
    "49yo",
    "50yo",
    "51yo",
    "52yo",
    "53yo",
    "54yo",
    "55yo",
    "56yo",
    "57yo",
    "58yo",
    "59yo",
    "60yo",
    "61yo",
    "62yo",
    "63yo",
    "64yo",
    "65yo",
    "66yo",
    "67yo",
    "68yo",
    "69yo",
    "70yo",
    "71yo",
    "72yo",
    "73yo",
    "74yo",
    "75yo",
    "76yo",
    "77yo",
    "78yo",
    "79yo",
    "80yo",
    "81yo",
    "82yo",
    "83yo",
    "84yo",
    "85yo",
    "86yo",
    "87yo",
    "88yo",
    "89yo",
    "90yo",
    "91yo",
    "92yo",
    "93yo",
    "94yo",
    "95yo",
    "96yo",
    "97yo",
    "98yo",
    "99yo",
    "18-year-old",
    "19-year-old",
    "20-year-old",
    "21-year-old",
    "22-year-old",
    "23-year-old",
    "24-year-old",
    "25-year-old",
    "26-year-old",
    "27-year-old",
    "28-year-old",
    "29-year-old",
    "30-year-old",
    "31-year-old",
    "32-year-old",
    "33-year-old",
    "34-year-old",
    "35-year-old",
    "36-year-old",
    "37-year-old",
    "38-year-old",
    "39-year-old",
    "40-year-old",
    "41-year-old",
    "42-year-old",
    "43-year-old",
    "44-year-old",
    "45-year-old",
    "46-year-old",
    "47-year-old",
    "48-year-old",
    "49-year-old",
    "50-year-old",
    "51-year-old",
    "52-year-old",
    "53-year-old",
    "54-year-old",
    "55-year-old",
    "56-year-old",
    "57-year-old",
    "58-year-old",
    "59-year-old",
    "60-year-old",
    "61-year-old",
    "62-year-old",
    "63-year-old",
    "64-year-old",
    "65-year-old",
    "66-year-old",
    "67-year-old",
    "68-year-old",
    "69-year-old",
    "70-year-old",
    "71-year-old",
    "72-year-old",
    "73-year-old",
    "74-year-old",
    "75-year-old",
    "76-year-old",
    "77-year-old",
    "78-year-old",
    "79-year-old",
    "80-year-old",
    "81-year-old",
    "82-year-old",
    "83-year-old",
    "84-year-old",
    "86-year-old",
    "87-year-old",
    "88-year-old",
    "89-year-old",
    "90-year-old",
    "91-year-old",
    "92-year-old",
    "93-year-old",
    "95-year-old",
    "96-year-old",
    "97-year-old",
    "98-year-old",
    "99-year-old",
    "90-year-old",
    "age 18",
    "age 19",
    "age 20",
    "age 21",
    "age 22",
    "age 23",
    "age 24",
    "age 25",
    "age 26",
    "age 27",
    "age 28",
    "age 29",
    "age 30",
    "age 31",
    "age 32",
    "age 33",
    "age 34",
    "age 35",
    "age 36",
    "age 37",
    "age 38",
    "age 39",
    "age 40",
    "age 41",
    "age 42",
    "age 43",
    "age 44",
    "age 45",
    "age 46",
    "age 47",
    "age 48",
    "age 49",
    "age 50",
    "age 51",
    "age 52",
    "age 53",
    "age 54",
    "age 55",
    "age 56",
    "age 57",
    "age 58",
    "age 59",
    "age 60",
    "age 61",
    "age 62",
    "age 63",
    "age 64",
    "age 65",
    "age 66",
    "age 67",
    "age 68",
    "age 69",
    "age 70",
    "age 71",
    "age 72",
    "age 73",
    "age 74",
    "age 75",
    "age 76",
    "age 77",
    "age 78",
    "age 79",
    "age 80",
    "age 81",
    "age 82",
    "age 83",
    "age 84",
    "age 85",
    "age 86",
    "age 87",
    "age 88",
    "age 89",
    "age 90",
    "age 91",
    "age 92",
    "age 93",
    "age 94",
    "age 95",
    "age 96",
    "age 97",
    "age 98",
    "age 99",
    "18 yo",
    "19 yo",
    "20 yo",
    "21 yo",
    "22 yo",
    "23 yo",
    "24 yo",
    "25 yo",
    "26 yo",
    "27 yo",
    "28 yo",
    "29 yo",
    "32 yo",
    "33 yo",
    "34 yo",
    "35 yo",
    "36 yo",
    "37 yo",
    "38 yo",
    "39 yo",
    "40 yo",
    "41 yo",
    "42 yo",
    "43 yo",
    "44 yo",
    "45 yo",
    "46 yo",
    "47 yo",
    "48 yo",
    "49 yo",
    "50 yo",
    "51 yo",
    "52 yo",
    "53 yo",
    "54 yo",
    "55 yo",
    "56 yo",
    "57 yo",
    "58 yo",
    "59 yo",
    "60 yo",
    "61 yo",
    "62 yo",
    "63 yo",
    "64 yo",
    "65 yo",
    "66 yo",
    "67 yo",
    "68 yo",
    "69 yo",
    "70 yo",
    "71 yo",
    "72 yo",
    "73 yo",
    "74 yo",
    "75 yo",
    "76 yo",
    "77 yo",
    "78 yo",
    "79 yo",
    "80 yo",
    "81 yo",
    "82 yo",
    "83 yo",
    "84 yo",
    "85 yo",
    "86 yo",
    "87 yo",
    "88 yo",
    "89 yo",
    "90 yo",
    "91 yo",
    "92 yo",
    "93 yo",
    "94 yo",
    "95 yo",
    "96 yo",
    "97 yo",
    "98 yo",
    "99 yo",
    "18 year",
    "19 year",
    "20 year",
    "21 year",
    "22 year",
    "23 year",
    "24 year",
    "25 year",
    "26 year",
    "27 year",
    "28 year",
    "29 year",
    "30 year",
    "31 year",
    "32 year",
    "33 year",
    "34 year",
    "35 year",
    "36 year",
    "37 year",
    "38 year",
    "39 year",
    "40 year",
    "41 year",
    "42 year",
    "43 year",
    "44 year",
    "45 year",
    "46 year",
    "47 year",
    "48 year",
    "49 year",
    "50 year",
    "51 year",
    "52 year",
    "53 year",
    "54 year",
    "55 year",
    "56 year",
    "57 year",
    "58 year",
    "59 year",
    "60 year",
    "61 year",
    "62 year",
    "63 year",
    "64 year",
    "65 year",
    "66 year",
    "67 year",
    "68 year",
    "69 year",
    "70 year",
    "71 year",
    "72 year",
    "73 year",
    "74 year",
    "75 year",
    "76 year",
    "77 year",
    "78 year",
    "79 year",
    "80 year",
    "81 year",
    "82 year",
    "83 year",
    "84 year",
    "85 year",
    "86 year",
    "87 year",
    "88 year",
    "89 year",
    "90 year",
    "91 year",
    "92 year",
    "93 year",
    "94 year",
    "95 year",
    "96 year",
    "97 year",
    "98 year",
    "99 year",
    "18 years",
    "19 years",
    "20 years",
    "21 years",
    "22 years",
    "23 years",
    "24 years",
    "25 years",
    "26 years",
    "27 years",
    "28 years",
    "29 years",
    "30 years",
    "31 years",
    "32 years",
    "33 years",
    "34 years",
    "35 years",
    "36 years",
    "37 years",
    "38 years",
    "39 years",
    "40 years",
    "41 years",
    "42 years",
    "43 years",
    "44 years",
    "45 years",
    "46 years",
    "47 years",
    "48 years",
    "49 years",
    "50 years",
    "51 years",
    "52 years",
    "53 years",
    "54 years",
    "55 years",
    "56 years",
    "57 years",
    "58 years",
    "59 years",
    "60 years",
    "61 years",
    "62 years",
    "63 years",
    "64 years",
    "65 years",
    "66 years",
    "67 years",
    "68 years",
    "69 years",
    "70 years",
    "71 years",
    "72 years",
    "73 years",
    "74 years",
    "75 years",
    "76 years",
    "77 years",
    "78 years",
    "79 years",
    "80 years",
    "81 years",
    "82 years",
    "83 years",
    "84 years",
    "85 years",
    "86 years",
    "87 years",
    "88 years",
    "89 years",
    "90 years",
    "91 years",
    "92 years",
    "93 years",
    "94 years",
    "95 years",
    "96 years",
    "97 years",
    "98 years",
    "99 years",
  ];
  const isOk = !!fullPrompt.match(new RegExp(okPrompts.join("|"), "i"));
  if (isOk) return false;
  const regex = new RegExp(badWords.join("|"), "i");
  return regex.test(fullPrompt);
};

export const checkNegPromptForBlocking = (negativePrompt: string) => {
  const badWords = ["18 years old", "18 yo", "18yo"];
  const regex = new RegExp(badWords.join("|"), "i");
  return regex.test(negativePrompt);
};

/*
 *  Check combination of words to check if it should be blocked. If more than 3 are found in the prompt, block it
 */
export const checkCombinationsForBlocking = (fullPrompt: string) => {
  const wordsThatCombinedAreBad = [
    "(petite: 1.6)",
    "18 years old",
    "skinny",
    "small frame",
    "(short:1.5)",
    "shy",
    "little",
    "tiny nude body",
  ];

  // if more than 3 from wordsThatCombinedAreBad are in the prompt, block it
  const regex = new RegExp(wordsThatCombinedAreBad.join("|"), "i");
  const matches = fullPrompt.match(regex);
  return matches?.length && matches.length > 3;
};

export const checkPromptForCelebs = (fullPrompt: string) => {
  const badWords = [
    "Millie Bobby Brown",
    "Lucy Pinder",
    "Arya Stark",
    "jenna ortega",
    "sophie turner",
    "Sadie Sink",
  ];

  const regex = new RegExp(badWords.join("|"), "i");
  return regex.test(fullPrompt);
};

// This is used for blocking sharing celebs (and nowadays for even creating them...)
export const checkPromptForAllCelebs = (fullPrompt: string) => {
  const badWords = [
    "Sophie Rain",
    "Lisa Edelstein",
    "Kiara Advani",
    "taylorswift",
    "Sarah Wayne callies",
    "Shay-Mitchell",
    "Shay Mitchell",
    "sarah hyland",
    "kate upton",
    "soleil moon frye",
    "Siva Karthikeyan",
    "dhanush",
    "Surya",
    "madison ivy",
    "Queen Elisabeth",
    "helene fischer",
    "greta thunberg",
    "Angela Merkel",
    "Giorgia Meloni",
    "Kaley Cuoco",
    "palina rojinski",
    "Vladimir Putin",
    "Lindsay Lohan",
    "Pamela Anderson",
    "Brad Pitt",
    "Angelina Jolie",
    "Kim Kardashian",
    "Kanye West",
    "Leonardo DiCaprio",
    "Madonna",
    "Justin Bieber",
    "Taylor Swift",
    "Oprah Winfrey",
    "Johnny Depp",
    "Britney Spears",
    "Miley Cyrus",
    "Beyoncé Knowles",
    "Tom Cruise",
    "Scarlett Johansson",
    "Robert Downey Jr.",
    "Jennifer Aniston",
    "Lady Gaga",
    "Rihanna",
    "Katy Perry",
    "Selena Gomez",
    "Ariana Grande",
    "Jennifer Lopez",
    "Sandra Bullock",
    "Julia Roberts",
    "Emma Watson",
    "Anne Hathaway",
    "Reese Witherspoon",
    "Nicole Kidman",
    "Jennifer Lawrence",
    "Angelina Jolie",
    "Megan Fox",
    "Natalie Portman",
    "Emma Stone",
    "Keira Knightley",
    "Scarlett Johansson",
    "Cameron Diaz",
    "Adele",
    "Demi Lovato",
    "Gwyneth Paltrow",
    "Halle Berry",
    "Helena Bonham Carter",
    "Hilary Duff",
    "Iggy Azalea",
    "Jada Pinkett Smith",
    "Jessica Alba",
    "Jessica Simpson",
    "Julianne Moore",
    "Kate Hudson",
    "Kate Winslet",
    "Katherine Heigl",
    "Kesha",
    "Khloe Kardashian",
    "Kourtney Kardashian",
    "Kristen Stewart",
    "Kylie Jenner",
    "Lady Gaga",
    "Lana Del Rey",
    "Laura Dern",
    "Lea Michele",
    "Lena Dunham",
    "Lily Allen",
    "Lindsay Lohan",
    "Lisa Kudrow",
    "Lucy Liu",
    "Madonna",
    "Mariah Carey",
    "Mary-Kate and Ashley Olsen",
    "Megan Fox",
    "Mila Kunis",
    "Miley Cyrus",
    "Miranda Kerr",
    "Naomi Watts",
    "Natalie Portman",
    "Nicki Minaj",
    "Nicole Richie",
    "Oprah Winfrey",
    "Paris Hilton",
    "Penelope Cruz",
    "Priyanka Chopra",
    "Queen Latifah",
    "Rachel McAdams",
    "Reese Witherspoon",
    "Rihanna",
    "Rosario Dawson",
    "Salma Hayek",
    "Alicia Keys",
    "Amy Adams",
    "Anna Kendrick",
    "Aubrey Plaza",
    "Bella Hadid",
    "Beyoncé",
    "Blake Lively",
    "Brie Larson",
    "Cardi B",
    "Cate Blanchett",
    "Charlize Theron",
    "Chloe Grace Moretz",
    "Dakota Fanning",
    "Dakota Johnson",
    "Drew Barrymore",
    "Elizabeth Banks",
    "Elizabeth Olsen",
    "Ellen DeGeneres",
    "Ellen Page",
    "Emily Blunt",
    "Emma Roberts",
    "Eva Longoria",
    "Eva Mendes",
    "Gal Gadot",
    "Gigi Hadid",
    "Gwen Stefani",
    "Hailee Steinfeld",
    "Halle Berry",
    "Heidi Klum",
    "Isla Fisher",
    "Janelle Monáe",
    "Jennifer Garner",
    "Jessica Chastain",
    "Jodie Foster",
    "Julia Louis-Dreyfus",
    "Julianne Hough",
    "Kate Beckinsale",
    "Kate Middleton",
    "Kendall Jenner",
    "Kerry Washington",
    "Kristen Bell",
    "Kristen Wiig",
    "Lady Gaga",
    "Laura Linney",
    "Lena Headey",
    "Lily Collins",
    "Lupita Nyong'o",
    "Margot Robbie",
    "Maria Sharapova",
    "Melissa McCarthy",
    "Mrunal Thakur",
    "Ellie",
    "AMANDA SEYFRIED",
    "Melania Trump",
    "Katniss everdeen",
    "Ellie Williams",
    "Cameron Diaz",
    "Jennifer Anniston",
    "Hayley Atwell",
    "bryce dallas howard",
    "Alexandria Ocasio-Cortez",
    "abby dahlkemper",
    "mary elizabeth winstead",
    "Selena Gomez",
    "Ariana_Grande",
    "sophie_turner",
    "sophie_turner",
    "selena_gomez",
    "emily_blunt",
    "cara Delevingne",
    "Emma_watson",
    "elizabeth_olsen",
    "scarlett_johansson",
    "Millie Bobby Brown",
    "Lucy Pinder",
    "Arya Stark",
    "jenna ortega",
    "sophie turner",
    "Sadie Sink",
    "daenerys",
    "sunnyleone",
    "emmastone",
    "emmawatson",
    "Margaery Tyrell",
    "Shakira",
    "Megan Fox",
    "Jenna Fischer",
    "Britney Spears",
    "jenna ortega",
    "Carrie Fisher",
    "Jessica Alba",
    "Marcelin Abadir",
    "Margot Robbie",
    "scarlet johansson",
    "Scarlett Johansson",
    "Emma Stone",
    "Charlize Theron",
    "Natalie Portman",
    "Jennifer Lawrence",
    "Megumin",
    "Kat Dennings",
    "Hermione Granger",
    "Kim Kardashian",
    "marilyn monroe",
    "Ariana Grande",
    "Gal Gadot",
    "Angelina Jolie",
    "Blake Lively",
    "Mila Kunis",
    "Emily Blunt",
    "Saoirse Ronan",
    "Emma Watson",
    "Kate Winslet",
    "Cate Blanchett",
    "Rachel McAdams",
    "Zoe Saldana",
    "Amy Adams",
    "Jessica Chastain",
    "Olivia Wilde",
    "Gwyneth Paltrow",
    "Keira Knightley",
    "Anne Hathaway",
    "Meryl Streep",
    "Nicole Kidman",
    "Julia Roberts",
    "Halle Berry",
    "Reese Witherspoon",
    "Penelope Cruz",
    "Salma Hayek",
    "Jennifer Aniston",
    "Sandra Bullock",
    "Kristen Stewart",
    "Alicia Vikander",
    "Brie Larson",
    "Dakota Johnson",
    "Elle Fanning",
    "Lupita Nyong'o",
    "Emma Roberts",
    "Chloë Grace Moretz",
    "Margaret Qualley",
    "Lily Collins",
    "Daisy Ridley",
    "Gemma Arterton",
    "Jenna Coleman",
    "Elizabeth Olsen",
    "Karen Gillan",
    "Emilia Clarke",
    "Sophie Turner",
    "Maisie Williams",
    "Lena Headey",
    "Natalie Dormer",
    "Rose Leslie",
    "Eva Green",
    "Michelle Pfeiffer",
    "Catherine Zeta-Jones",
    "Judi Dench",
    "Helen Mirren",
    "Rachel Weisz",
    "scarlett johansson",
    "Lupita Nyong'o",
    "Gal Gadot",
    "Zendaya",
    "Emma Watson",
    "Brie Larson",
    "Daisy Ridley",
    "Yara Shahidi",
    "Sophie Turner",
    "Amandla Stenberg",
    "Hailee Steinfeld",
    "Chloe Grace Moretz",
    "Marsai Martin",
    "Lana Condor",
    "Kiernan Shipka",
    "Auli'i Cravalho",
    "Lily Collins",
    "Madison Reyes",
    "Storm Reid",
    "Victoria Justice",
    "Shailene Woodley",
    "Dove Cameron",
    "Lili Reinhart",
    "Liana Liberato",
    "Saoirse Ronan",
    "Ellie Bamber",
    "Kristen Stewart",
    "Lupita Nyong'o",
    "Gal Gadot",
    "Zendaya",
    "Emma Watson",
    "Brie Larson",
    "Daisy Ridley",
    "Yara Shahidi",
    "Sophie Turner",
    "Amandla Stenberg",
    "Hailee Steinfeld",
    "Millie Davis",
    "Olivia Rodrigo",
    "Madelaine Petsch",
    "Emily Blunt",
    "Karen Gillan",
    "Kaitlyn Dever",
    "Gillian Jacobs",
    "Margaret Qualley",
    "Katherine Langford",
    "Shannon Purser",
    "Lupita Nyong'o",
    "Mindy Kaling",
    "Emma Stone",
    "Awkwafina",
    "Zoe Kravitz",
    "Ana de Armas",
    "Camila Mendes",
    "Lana Del Rey",
    "Janelle Monae",
    "Cynthia Erivo",
    "Lena Waithe",
    "Laverne Cox",
    "Marsai Martin",
    "Willow Smith",
    "Maddie Ziegler",
    "Joey King",
    "Mckenna Grace",
    "Natalia Dyer",
    "Sadie Stanley",
    "Jade Pettyjohn",
    "Isabela Merced",
    "Emily Alyn Lind",
    "Ava Michelle",
    "Lulu Wilson",
    "Jenna Davis",
    "Sophia Lillis",
    "Finn Wolfhard",
    "Noah Schnapp",
    "Gaten Matarazzo",
    "Caleb McLaughlin",
    "Wyatt Oleff",
    "Jack Dylan Grazer",
    "Jaeden Martell",
    "Asher Angel",
    "Jeremy Ray Taylor",
    "Owen Vaccaro",
    "Jacob Tremblay",
    "Fionn Whitehead",
    "Timothée Chalamet",
    "Asa Butterfield",
    "Ansel Elgort",
    "Lucas Hedges",
    "Tom Holland",
    "Nick Robinson",
    "Jacob Elordi",
    "Charlie Plummer",
    "Alex Wolff",
    "Barrett Carnahan",
    "Landon Liboiron",
    "RJ Cyler",
    "Griffin Gluck",
    "Nico Hiraga",
    "Nathaniel Potvin",
    "Cameron Boyce",
    "China Anne McClain",
    "Skai Jackson",
    "Peyton List",
    "Madison Pettis",
    "Amandla Stenberg",
    "Halle Bailey",
    "Zendaya",
    "Timothée Chalamet",
    "Noah Centineo",
    "Lili Reinhart",
    "Finn Wolfhard",
    "Sadie Sink",
    "Jacob Elordi",
    "Yara Shahidi",
    "Lana Condor",
    "Tom Holland",
    "Cole Sprouse",
    "Chloë Grace Moretz",
    "Billie Eilish",
    "Timothee Simons",
    "Kiernan Shipka",
    "Jacob Tremblay",
    "Marsai Martin",
    "Gaten Matarazzo",
    "Sophia Lillis",
    "Storm Reid",
    "Joey King",
    "Anya Taylor-Joy",
    "Kaitlyn Dever",
    "Olivia Rodrigo",
    "Eiza González",
    "Madelaine Petsch",
    "Jacob Batalon",
    "KJ Apa",
    "Miles Brown",
    "Lana Rhoades",
    "Marsai Martin",
    "Mckenna Grace",
    "Jade Pettyjohn",
    "Milly Shapiro",
    "Emily Alyn Lind",
    "Dakota Lotus",
    "Isabela Merced",
    "Jharrel Jerome",
    "Marsai Martin",
    "Caleb McLaughlin",
    "Natalie Alyn Lind",
    "Mackenzie Foy",
    "Mena Massoud",
    "Joel Courtney",
    "Auli'i Cravalho",
    "Amandla Stenberg",
    "Hunter Schafer",
    "Ava Michelle",
    "Liana Liberato",
    "Yara Shahidi",
    "Sabrina Carpenter",
    "Lulu Wilson",
    "Raffey Cassidy",
    "Iain Armitage",
    "Chosen Jacobs",
    "Noah Jupe",
    "Jacob Latimore",
    "Gaten Matarazzo",
    "Jaz Sinclair",
    "Saoirse Ronan",
    "Tye Sheridan",
    "Sophia Lillis",
    "Fionn Whitehead",
    "Asa Butterfield",
    "Millie Davis",
    "Jack Dylan Grazer",
    "Rowan Blanchard",
    "Kiernan Shipka",
    "Zendaya",
    "Hunter Schafer",
    "Algee Smith",
    "Alexa Demie",
    "Storm Reid",
    "Barbie Ferreira",
    "Nika King",
    "Maude Apatow",
    "Eric Dane",
    "Jacob Elordi",
    "Sydney Sweeney",
    "Elizabeth Olsen",
    "Sadie sink",
    "Rose McIver",
    "Taylor Swift",
    "jennifer_lawrence",
    "kimkardashian",
    "face of Kate Upton",
    "Bryce Dallas Howard",
    "wonder woman",
    "Amber Heard",
    "Kate Dennings",
    "Rihanna",
    "selena gomez",
    "scarlett_johansson as natasha romanoff",
    "Zooey Deschanel",
    "wonder-woman costume",
    "(emily_blunt)",
    "jennefer lawrence face",
    "Lucy Liu",
    "Jennifer lopez",
    "scarlett johanson",
    "in the halls of hogwarts, holding Hermione’s wand",
    "emma_watson",
    "Bryce Dallas Howard",
    "jennifer_lawrence",
    "khaleesi",
    "Aishwarya_rai",
    "Leslie Easterbrook",
    "Kelly Hu",
    "Cara Delevingne",
    "Arianna_Grande",
    "aishwarya rai",
    "tracee Ellis ross",
    "(Emma_watson)",
    "Hayley Atwell",
    "Avril Lavigne",
    "captain America and black widow",
    "Sansa Stark",
    "selena gomez:1.6",
  ];

  const fullPromptWithSpaces = fullPrompt.replace(/_/g, " ");

  const regex = new RegExp(badWords.join("|"), "i");
  return regex.test(fullPromptWithSpaces);
};
